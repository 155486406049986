import { yupResolver } from "@hookform/resolvers/yup";
import { TabPanel } from "devextreme-react";
import { Item } from "devextreme-react/cjs/accordion";
import { ValueChangedEvent } from "devextreme/ui/check_box";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import ProvedorAjuda from "../../../../components/ajuda/provedor-ajuda";
import BotaoCancelarMxp from "../../../../components/botoes/botao-cancelar-mxp";
import BotaoSalvarMxp from "../../../../components/botoes/botao-salvar-mxp";
import { FormCheckBox, FormDateBox } from "../../../../components/formularios";
import FormTextArea from "../../../../components/formularios/textarea";
import FormMxp from "../../../../components/layout/form";
import { ContainerFormMxp } from "../../../../components/layout/form/styles";
import { Coluna, Linha } from "../../../../components/layout/grid-system";
import ToolbarMxp from "../../../../components/layout/toolbar-mxp";
import Sessao from "../../../../components/organizacao/sessao";
import AuditavelDTO from "../../../../models/api/comum/auditavel-dto";
import { ResponseBase } from "../../../../models/api/comum/response-base";
import {
  IFormularioEditavelBase,
  ResultadoAcaoFormulario,
} from "../../../../models/shared/ui/formularios";
import { checarResponse, tratarErroApi } from "../../../../utils/api/api-utils";
import exibirNotificacaoToast, {
  GeradorMensagensNotificacao,
  TipoNotificacao,
} from "../../../../utils/common/notificacoes-utils";
import { MensagensPadraoYup } from "../../../comum/utils/yup/mensagens";
import ComboUsuarioMxp from "../../../seguranca/usuario/componentes/form/select-box-lazy";
import ComboEmpresaMxp from "../../../sistema/empresa/componentes/select-box-lazy";
import ComboValorOpcaoMxp from "../../../sistema/valor-opcao/form/select-box-lazy";
import { TipoValorOpcao } from "../../../sistema/valor-opcao/models/valor-opcao.api";
import { ComboContatoPorEmpresaMxp } from "../../contato/form/select-box-lazy";
import TarefaOuAnotacaoAbaAnexos from "../componentes/abas-formulario/anexos";
import TarefaOuAnotacaoAbaTarefasEAnotacoes from "../componentes/abas-formulario/tarefas-e-anotacoes";
import {
  TarefaOuAnotacaoRequest,
  TarefaOuAnotacaoResponse,
} from "../models/tarefa-ou-anotacao.api";
import TarefaOuAnotacaoServico from "../servicos/tarefa-ou-anotacao.servico";
import {
  EstadoTarefaOuAnotacao,
  TipoTarefaOuAnotacao,
} from "../utils/enums/tarefa-ou-anotacao.api.enums";

let dadosAuditoria: AuditavelDTO | undefined = undefined;

const service = new TarefaOuAnotacaoServico();

const schema = yup.object().shape({
  id: yup.number().required().moreThan(-1).integer(),
  descricao: yup.string().required(MensagensPadraoYup.campoObrigatorio),
  responsavelId: yup
    .number()
    .notRequired()
    .test(
      "responsavel_requerido",
      MensagensPadraoYup.campoObrigatorio,
      function (valor) {
        if (!valor && this.parent.tarefaPara) {
          return false;
        }
        return true;
      }
    ),
  dataNecessidade: yup
    .date()
    .notRequired()
    .test(
      "dataNecessidade_requerido",
      MensagensPadraoYup.campoObrigatorio,
      function (valor) {
        if (!valor && this.parent.tarefaPara) {
          return false;
        }
        return true;
      }
    ),
});

interface FormTarefaOuAnotacaoProps extends IFormularioEditavelBase {
  tarefaGeradoraId: number | null;
  ordemDeProducaoId: number | null;
}

export default function FormTarefaOuAnotacao(props: FormTarefaOuAnotacaoProps) {
  const novoRegistro: TarefaOuAnotacaoRequest = {
    id: 0,
    descricao: "",
    empresaId: null,
    assuntoId: null,
    contatoId: null,
    classeId: null,
    responsavelId: null,
    tarefaGeradoraId: props.tarefaGeradoraId,
    ordemDeProducaoId: props.ordemDeProducaoId,
    dataNecessidade: null,
    anotacaoFinalizacao: "",
    tipo: TipoTarefaOuAnotacao.Anotacao,
    estado: EstadoTarefaOuAnotacao.Pendente,
    tarefaPara: false,
    finalizar: false,
    arquivos: [],
  };

  const [carregando, setCarregando] = useState(false);

  const hookForms = useForm<TarefaOuAnotacaoRequest>({
    resolver: yupResolver(schema),
  });

  const { control, handleSubmit, getValues, setValue, reset, register, watch } =
    hookForms;

  //Hook usado para carregar os dados da tela
  useEffect(() => {
    if (
      Number.isNaN(props.idRegistroEmEdicao) ||
      props.idRegistroEmEdicao === 0
    ) {
      //Limpa tela para inserir os valores default no form definido no 'novoRegistro'
      limparTela();

      return;
    }

    preencherTela();

    return () => limparTela();
  }, [props.idRegistroEmEdicao]);

  async function preencherTela() {
    if (props.idRegistroEmEdicao > 0) {
      await carregarRegistroDoId();
    }
  }

  async function carregarRegistroDoId() {
    try {
      setCarregando(true);
      const [resposta, anexos] = await Promise.all([
        service.ObterPorIdComDadosAuditoria<TarefaOuAnotacaoResponse>(
          props.idRegistroEmEdicao
        ),
        service.obterAnexos(props.idRegistroEmEdicao),
      ]);

      checarResponse(resposta);
      dadosAuditoria = resposta.model;
      resposta.model.arquivos = anexos;

      resposta.model.tarefaPara =
        resposta.model.tipo === TipoTarefaOuAnotacao.Tarefa;
      resposta.model.finalizar =
        resposta.model.estado === EstadoTarefaOuAnotacao.Finalizada;
      reset(resposta.model);
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      setCarregando(false);
    }
  }

  async function handleSalvar() {
    setCarregando(true);
    const model = getValues();

    try {
      let resposta: ResponseBase;

      if (props.idRegistroEmEdicao > 0) {
        const respostaApi = await service.Atualizar(model);
        const respostaAnexo = await service.anexoEditarLista(
          model.id,
          model.arquivos ?? []
        );

        resposta = respostaApi;

        checarResponse(respostaAnexo);
      } else {
        const respostaInicial = await service.InserirComRetorno<
          TarefaOuAnotacaoRequest,
          TarefaOuAnotacaoResponse
        >(model as TarefaOuAnotacaoRequest);

        if (respostaInicial.sucesso) {
          const id = respostaInicial.model.id;
          const respostaAnexo = await service.anexoEditarLista(
            id,
            model.arquivos ?? []
          );
          checarResponse(respostaAnexo);
        }

        resposta = {
          sucesso: respostaInicial.sucesso,
          mensagem: respostaInicial.mensagem,
          erros: respostaInicial.erros,
        };
      }

      if (checarResponse(resposta)) {
        exibirNotificacaoToast({
          mensagem:
            GeradorMensagensNotificacao.SalvoComSucessoFeminino(
              "Tarefa/anotação"
            ),
          tipo: TipoNotificacao.Sucesso,
        });
        fechar(ResultadoAcaoFormulario.AcaoConcluida);
      }
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    } finally {
      setCarregando(false);
    }
  }

  function callBackUnprocessableEntity() {
    fechar(ResultadoAcaoFormulario.AcaoConcluida);
  }

  function limparTela() {
    dadosAuditoria = undefined;
    reset(novoRegistro);
  }

  function handleComboEmpresaOnChange() {
    setValue("contatoId", null);
  }

  function handleCancelar() {
    fechar(ResultadoAcaoFormulario.AcaoCancelada);
  }

  function fechar(resultado: ResultadoAcaoFormulario) {
    limparTela();
    props.handleCallback(resultado);
  }

  const limparCamposDeTarefa = useCallback(() => {
    setValue("responsavelId", null);
    setValue("dataNecessidade", null);
    setValue("anotacaoFinalizacao", null);
    setValue("finalizar", false);
  }, [setValue]);

  const handleTarefaPara = useCallback(
    async (event: ValueChangedEvent) => {
      if (!event.value) {
        limparCamposDeTarefa();
      }

      setValue(
        "tipo",
        event.value
          ? TipoTarefaOuAnotacao.Tarefa
          : TipoTarefaOuAnotacao.Anotacao
      );
    },
    [limparCamposDeTarefa, setValue]
  );

  const handleFinalizar = useCallback(
    async (event: ValueChangedEvent) => {
      const estado = event.value
        ? EstadoTarefaOuAnotacao.Finalizada
        : EstadoTarefaOuAnotacao.Pendente;

      setValue("estado", estado);
    },
    [setValue]
  );

  return (
    <>
      <ContainerFormMxp>
        <FormMxp carregando={carregando}>
          <ProvedorAjuda id="edit-form-tarefa-ou-anotacao">
            <input type="hidden" {...register("id")} defaultValue={0} />
            <input type="hidden" {...register("estado")} />
            <input type="hidden" {...register("tipo")} />
            <Linha>
              <Coluna md={3}>
                <Linha>
                  <Coluna md={12}>
                    <ComboEmpresaMxp
                      name="empresaId"
                      titulo="Empresa"
                      control={control}
                      onchange={handleComboEmpresaOnChange}
                    />
                  </Coluna>
                </Linha>
                <Linha>
                  <Coluna md={12}>
                    <ComboValorOpcaoMxp
                      tipoValorOpcao={TipoValorOpcao.AssuntoAnotacao}
                      tituloSeletor="Selecionar assunto"
                      name="assuntoId"
                      titulo="Assunto"
                      control={control}
                    />
                  </Coluna>
                </Linha>
              </Coluna>
              <Coluna md={3}>
                <Linha>
                  <Coluna md={12}>
                    <ComboContatoPorEmpresaMxp
                      name="contatoId"
                      titulo="Contato"
                      empresaId={watch("empresaId") ?? 0}
                      control={control}
                    />
                  </Coluna>
                </Linha>
                <Linha>
                  <Coluna md={12}>
                    <ComboValorOpcaoMxp
                      tipoValorOpcao={TipoValorOpcao.AcaoAnotacao}
                      tituloSeletor="Selecionar classe"
                      name="classeId"
                      titulo="Classe"
                      control={control}
                    />
                  </Coluna>
                </Linha>
              </Coluna>
              <Coluna md={6}>
                <FormTextArea
                  name="descricao"
                  titulo="Descrição"
                  valor={""}
                  control={control}
                  height={13}
                  requerido
                />
              </Coluna>
            </Linha>
            <Linha>
              <Coluna md={3}>
                <FormCheckBox
                  control={control}
                  name="tarefaPara"
                  titulo="Tarefa para"
                  defaultValue={false}
                  onValueChanged={handleTarefaPara}
                />
              </Coluna>
            </Linha>

            {/* Tarefa para */}
            <Sessao>
              <Linha>
                <Coluna md={4}>
                  <ComboUsuarioMxp
                    name="responsavelId"
                    titulo="Responsável"
                    somenteLeitura={!watch("tarefaPara")}
                    requerido={watch("tarefaPara")}
                    control={control}
                  />
                </Coluna>
                <Coluna md={4}>
                  <FormDateBox
                    name="dataNecessidade"
                    titulo="Data limite"
                    somenteLeitura={!watch("tarefaPara")}
                    requerido={watch("tarefaPara")}
                    control={control}
                    tipo="datetime"
                    aceitaValorCustomizado={true}
                    formatoExibicao="dd/MM/yyyy hh:mm:ss"
                    aceitaDigitacaoComMascara={true}
                  />
                </Coluna>
              </Linha>
              <Linha>
                <Coluna md={3}>
                  <FormCheckBox
                    control={control}
                    name="finalizar"
                    titulo="Finalizar"
                    defaultValue={false}
                    somenteLeitura={!watch("tarefaPara")}
                    onValueChanged={handleFinalizar}
                  />
                </Coluna>
              </Linha>
              <Linha>
                <Coluna md={12}>
                  <FormTextArea
                    name="anotacaoFinalizacao"
                    titulo="Anotação da finalização"
                    valor={""}
                    somenteLeitura={!watch("tarefaPara") || !watch("finalizar")}
                    control={control}
                    height={10}
                  />
                </Coluna>
              </Linha>
            </Sessao>

            <TabPanel
              key={props.idRegistroEmEdicao}
              deferRendering={false}
              showNavButtons
              swipeEnabled={false}
              itemTitleRender={(item) => item.text}
              height={"75%"}
            >
              <Item text="Anotações">
                {props.idRegistroEmEdicao > 0 ? (
                  <TarefaOuAnotacaoAbaTarefasEAnotacoes
                    tarefaGeradoraId={props.idRegistroEmEdicao}
                    ordemDeProducaoId={props.ordemDeProducaoId ?? undefined}
                  />
                ) : (
                  <p>
                    <b>
                      Para visualizar as tarefas e anotações é necessário criar
                      uma tarefa ou anotação primeiro.
                    </b>
                  </p>
                )}
              </Item>
              <Item text="Anexos">
                <TarefaOuAnotacaoAbaAnexos
                  hookForm={hookForms}
                  idRegistro={props.idRegistroEmEdicao}
                />
              </Item>
            </TabPanel>
          </ProvedorAjuda>
        </FormMxp>
        <ToolbarMxp dadosAuditoria={dadosAuditoria}>
          <BotaoSalvarMxp handleClick={handleSubmit(handleSalvar)} />
          <BotaoCancelarMxp handleClick={handleCancelar} />
        </ToolbarMxp>
      </ContainerFormMxp>
    </>
  );
}
