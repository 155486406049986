import { yupResolver } from "@hookform/resolvers/yup";
import ArrayStore from "devextreme/data/array_store";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import BotaoCancelarMxp from "../../../../../components/botoes/botao-cancelar-mxp";
import BotaoConfirmarMxp from "../../../../../components/botoes/botao-confirmar-mxp";
import {
  FormGrupo,
  FormNumberBox,
} from "../../../../../components/formularios";
import FormMxp from "../../../../../components/layout/form";
import { Coluna, Linha } from "../../../../../components/layout/grid-system";
import { ModalMxp } from "../../../../../components/layout/modal-mxp";
import ToolbarMxp from "../../../../../components/layout/toolbar-mxp";
import { EstoqueMovimentacaoService } from "../../../../../services/estoque-movimentacoes/estoque-movimentacoes-service";
import {
  checarResponse,
  tratarErroApi,
  verificarSePossuiErroApiPorCodigo,
} from "../../../../../utils/api/api-utils";
import exibirNotificacaoToast, {
  JanelasDeNotificacaoTitulos,
  TipoNotificacao,
} from "../../../../../utils/common/notificacoes-utils";
import { exibirConfirmacao } from "../../../../../utils/dialogos";
import {
  formatarNumeroQuantidade,
  obterFormatStringNumero,
} from "../../../../../utils/formatadores/formatador-de-numeros";
import { ModalSelecionarMovimentacoesParaEstornar } from "../../../../estoque/estoque-movimentacao/componentes/modal-selecionar-movimentacoes-para-estornar";
import { OrdemDeProducaoService } from "../../servicos/ordem-de-producao.service";
import ContextoOrdemDeProducaoGrid from "../provedor-contexto-ordem-de-producao/contexto-ordem-de-producao-grid";
import ComboOrdemDeProducaoMxp from "../select-box-lazy/select-box-padrao";

const ordemDeProducaoService = new OrdemDeProducaoService();
const estoqueMovimentacaoService = new EstoqueMovimentacaoService();

interface DesconcluirOrdemDeProducaoProps {
  ordemDeProducaoId: number | null;
  quantidade: number;
  unidadePrincipalPossuiQuantidadeInteira: boolean;
}

const schema = yup.object<DesconcluirOrdemDeProducaoProps>().shape({
  ordemDeProducaoId: yup.number().required().moreThan(0).integer(),
  quantidade: yup
    .number()
    .required()
    .when(
      ["unidadePrincipalPossuiQuantidadeInteira"],
      (unidadePrincipalPossuiQuantidadeInteira, schema) =>
        unidadePrincipalPossuiQuantidadeInteira[0]
          ? schema.required().moreThan(0).integer()
          : schema.required().moreThan(0)
    ),
});

export const ModalDesconcluirOrdemDeProducao = () => {
  const [dataSourceSelecionarEstoque, setDataSourceSelecionarEstoque] =
    useState<ArrayStore | undefined>(undefined);
  const [carregando, setCarregando] = useState(false);

  const {
    atualizaGridDeOrdemDeProducao,
    ordemDeProducaoDesfazerConclusao,
    definfirOrdemDeProducaoDesfazerConclusao,
  } = useContext(ContextoOrdemDeProducaoGrid);

  const hookForm = useForm<DesconcluirOrdemDeProducaoProps>({
    resolver: yupResolver(schema),
  });

  const { control, handleSubmit, reset, getValues } = hookForm;

  useEffect(() => {
    reset({
      ordemDeProducaoId: ordemDeProducaoDesfazerConclusao?.id ?? null,
      quantidade: ordemDeProducaoDesfazerConclusao?.quantidadeConcluida ?? 0,
      unidadePrincipalPossuiQuantidadeInteira:
        ordemDeProducaoDesfazerConclusao?.unidadePrincipalPossuiQuantidadeInteira ??
        false,
    });
  }, [reset, ordemDeProducaoDesfazerConclusao]);

  function fechar() {
    definfirOrdemDeProducaoDesfazerConclusao(undefined);
  }

  const fecharModal = () => {
    setDataSourceSelecionarEstoque(undefined);

    fechar();
  };

  async function confirmarEstorno() {
    const quantidadeAEstornar = getValues("quantidade");
    if (quantidadeAEstornar == 0) {
      exibirNotificacaoToast({
        mensagem: "A quantidade para desconcluir deve ser maior que zero",
        tipo: TipoNotificacao.Advertencia,
      });
    }

    const quantidadeBaixada =
      ordemDeProducaoDesfazerConclusao?.quantidadeConcluida ?? 0;
    if (quantidadeAEstornar > quantidadeBaixada) {
      const confirmacao = await exibirConfirmacao(
        JanelasDeNotificacaoTitulos.Atencao,
        `A quantidade informada (${formatarNumeroQuantidade(
          quantidadeAEstornar
        )}) é maior que a quantidade total já concluída na ordem de produção (${formatarNumeroQuantidade(
          quantidadeBaixada
        )}). Deseja desfazer com a quantidade total já concluída?`
      );

      if (!confirmacao) {
        return;
      }
    }
    try {
      setCarregando(true);
      const resposta = await ordemDeProducaoService.DesfazerConclusao(
        ordemDeProducaoDesfazerConclusao?.id ?? 0,
        quantidadeAEstornar ?? 0
      );

      checarResponse(resposta);
      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: "Conclusão desfeita com sucesso.",
          tipo: TipoNotificacao.Sucesso,
        });

        atualizaGridDeOrdemDeProducao();

        fechar();
      }
    } catch (error: any) {
      const precisaSelecionarEstoques = verificarSePossuiErroApiPorCodigo(
        error,
        "MOV-0002"
      );
      if (!precisaSelecionarEstoques) {
        tratarErroApi(error);
        return;
      }

      const movimentacoes =
        await estoqueMovimentacaoService.ObterMovimentacoesDeConclusao(
          ordemDeProducaoDesfazerConclusao?.id,
          quantidadeAEstornar
        );
      setDataSourceSelecionarEstoque(
        new ArrayStore({
          data: movimentacoes,
          key: "id",
        })
      );
    } finally {
      setCarregando(false);
    }
  }

  return (
    <>
      <ModalMxp
        titulo={"Desfazer conclusão da ordem de produção"}
        visivel={ordemDeProducaoDesfazerConclusao != undefined}
        handleFechar={fechar}
        larguraMaxima={"max(30vw, 400px)"}
      >
        <FormMxp carregando={carregando}>
          <FormGrupo>
            <Linha>
              <Coluna md={12}>
                <ComboOrdemDeProducaoMxp
                  name="ordemDeProducaoId"
                  titulo="Ordem de producão"
                  control={control}
                  somenteLeitura
                />
              </Coluna>
            </Linha>
            <Linha>
              <Coluna md={12}>
                <FormNumberBox
                  name="quantidade"
                  titulo="Quantidade a estornar"
                  formato={obterFormatStringNumero(5)}
                  control={control}
                  requerido
                  minimo={0}
                />
              </Coluna>
            </Linha>
          </FormGrupo>
        </FormMxp>
        <ToolbarMxp>
          <BotaoConfirmarMxp handleClick={handleSubmit(confirmarEstorno)} />
          <BotaoCancelarMxp handleClick={fechar} />
        </ToolbarMxp>
      </ModalMxp>
      <ModalSelecionarMovimentacoesParaEstornar
        dados={dataSourceSelecionarEstoque}
        fecharModal={fecharModal}
        onEstornado={atualizaGridDeOrdemDeProducao}
        mensagens={{
          erro: "Ocorreu um erro ao estornar as movimentações.",
          sucesso: "Movimentações estornadas com sucesso!",
        }}
      />
    </>
  );
};
