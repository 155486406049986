import TabContainer from "../../../../../../../components/layout/tab-container";
import RequerPermissao from "../../../../../../../components/seguranca/requer-permissao";
import { PermissoesTarefaOuAnotacao } from "../../../../../../../models/permissoes/crm/tarefa-ou-anoracao/permissoes-tarefa-ou-anotacao";
import GridEmbutidaTarefasEAnotacoes from "../../../../../../crm/tarefa-ou-anotacao/componentes/grid-embutida";
import { TipoVinculoTarefaOuAnotacaoParaCarregamentoDataSource } from "../../../../../../crm/tarefa-ou-anotacao/utils/enums/tarefa-ou-anotacao.api.enums";

interface OrdemDeProducaoAbaTarefasEAnotacoesProps {
  ordemDeProducaoId: number;
}

export default function OrdemDeProducaoAbaTarefasEAnotacoes(
  props: OrdemDeProducaoAbaTarefasEAnotacoesProps
) {
  return (
    <TabContainer>
      <RequerPermissao
        codigoPermissoes={[
          PermissoesTarefaOuAnotacao.ConsultarTodos,
          PermissoesTarefaOuAnotacao.ConsultarSomenteDoUsuario,
        ]}
        modo="alguma"
      >
        <GridEmbutidaTarefasEAnotacoes
          {...props}
          tipoVinculoTarefaOuAnotacaoParaCarregamentoDataSource={
            TipoVinculoTarefaOuAnotacaoParaCarregamentoDataSource.OrdemDeProducao
          }
        />
      </RequerPermissao>
    </TabContainer>
  );
}
