import { Button } from "devextreme-react";
import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";
import { useContext, useState } from "react";
import BotaoCancelarMxp from "../../../../../components/botoes/botao-cancelar-mxp";
import FormMxp from "../../../../../components/layout/form";
import { ModalMxp } from "../../../../../components/layout/modal-mxp";
import ToolbarMxp from "../../../../../components/layout/toolbar-mxp";
import { ContextoSeletor } from "../../../../../components/selecao/contexto-seletor";
import ProvedorSeletor from "../../../../../components/selecao/provedor-seletor";
import { tratarErroApi } from "../../../../../utils/api/api-utils";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../utils/common/notificacoes-utils";
import {
  DadosModalSelecaoInsumosFilhosDoInsumoAlternativo,
  InsumoAlternativoSelecionado,
} from "../../../insumo-da-ordem-de-producao/models/insumo-da-ordem-de-producao";
import { InsumoDaOrdemDeProducaoService } from "../../../insumo-da-ordem-de-producao/servicos/insumo-da-ordem-de-producao";
import { InsumosFilhosDoInsumoAlternativoGridModel } from "../../models/estrutura-de-produto.api";
import GridSelecaoInsumosFilhosDoInsumoAlternativo from "../grids/grid-selecao-estrutura-insumo-alternativo";

const APIInsumos = new InsumoDaOrdemDeProducaoService();

interface ModalSelecaoFilhosDoInsumoAlternativoProps {
  dados: DataSource | undefined;
  dadosModal: DadosModalSelecaoInsumosFilhosDoInsumoAlternativo;
  visivel: boolean;
  fecharModal: () => void;
}

export function ModalSelecaoFilhosDoInsumoAlternativo(
  props: ModalSelecaoFilhosDoInsumoAlternativoProps
) {
  return (
    <ProvedorSeletor>
      <ModalSelecaoFilhosDoInsumoAlternativoInterno {...props} />
    </ProvedorSeletor>
  );
}

function ModalSelecaoFilhosDoInsumoAlternativoInterno(
  props: ModalSelecaoFilhosDoInsumoAlternativoProps
) {
  const [carregando, setCarregando] = useState(false);
  const { refGrid } = useContext(ContextoSeletor);

  const fecharModal = () => {
    refGrid?.instance().cancelEditData();
    refGrid?.instance().refresh();
    props.fecharModal();
  };

  async function selecionarInsumosFilhosDoAlternativo() {
    if (!refGrid) {
      exibirNotificacaoToast({
        mensagem:
          "Ocorreu um erro ao selecionar insumos filhos do insumo alternativo.",
        tipo: TipoNotificacao.Erro,
      });
      return;
    }

    try {
      setCarregando(true);
      const insumosFilhosDoAlternativo = refGrid
        .instance()
        .getVisibleRows()
        .map((row) => {
          const dado = row.data as InsumosFilhosDoInsumoAlternativoGridModel;
          return new InsumoAlternativoSelecionado(
            dado.itemId,
            dado.quantidadeASelecionar,
            dado.proporcaoParaItemPai ?? 1
          );
        });

      const resposta = await APIInsumos.AtualizarInsumoAlternativo(
        props.dadosModal.insumoPaiId,
        insumosFilhosDoAlternativo
      );

      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem:
            "Insumos filhos do insumo alternativo atualizados com sucesso.",
          tipo: TipoNotificacao.Sucesso,
        });

        fecharModal();
      }
    } catch (error) {
      tratarErroApi(error);
    } finally {
      setCarregando(false);
    }
  }

  return (
    <ModalMxp
      titulo={"Selecionar insumo alternativo"}
      visivel={props.visivel}
      handleFechar={fecharModal}
      largura={"90vw"}
      altura={"90vh"}
    >
      <FormMxp carregando={carregando}>
        <GridSelecaoInsumosFilhosDoInsumoAlternativo
          dataSource={props.dados ?? new ArrayStore()}
          dadosModal={props.dadosModal}
          style={{ height: "100%" }}
        />
      </FormMxp>
      <ToolbarMxp>
        <Button
          type={"success"}
          text={"Selecionar"}
          icon={"check"}
          onClick={selecionarInsumosFilhosDoAlternativo}
        />
        <BotaoCancelarMxp handleClick={fecharModal} />
      </ToolbarMxp>
    </ModalMxp>
  );
}
