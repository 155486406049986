import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import ProvedorAjuda from "../../../../../components/ajuda/provedor-ajuda";
import BotaoCancelarMxp from "../../../../../components/botoes/botao-cancelar-mxp";
import BotaoSalvarMxp from "../../../../../components/botoes/botao-salvar-mxp";
import {
  FormGrupo,
  FormSelectBoxLazyMxp,
  FormTextBox,
} from "../../../../../components/formularios";
import { assertConfiguracaoExibicaoEBuscaType } from "../../../../../components/formularios/selectbox-lazy-mxp";
import FormMxp from "../../../../../components/layout/form";
import { ContainerFormMxp } from "../../../../../components/layout/form/styles";
import { Coluna, Linha } from "../../../../../components/layout/grid-system";
import ToolbarMxp from "../../../../../components/layout/toolbar-mxp";
import AuditavelDTO from "../../../../../models/api/comum/auditavel-dto";
import { PermissoesCalendarioDoGrupoDoCentroDeTrabalho } from "../../../../../models/permissoes/producao/calendario-do-grupo-do-centro-de-trabalho/permissoes-calendario-do-grupo-do-centro-de-trabalho";
import {
  IFormularioEditavelBase,
  ResultadoAcaoFormulario,
} from "../../../../../models/shared/ui/formularios";
import { NomesEndpoints } from "../../../../../services/comum/nomesEndpoints";
import {
  checarResponse,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import { previneDigitacaoDeCaracteres } from "../../../../../utils/common/common-utils";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../utils/common/notificacoes-utils";
import {
  letrasApenasMaiusculasDigitosSimbolosSemEspacos,
  letrasDigitosSimbolosSemEspacos,
} from "../../../../../utils/common/regex-padrao";
import {
  FormataDescricao,
  FormatadoresSelectBox,
} from "../../../../../utils/formatadores/formatador-de-selectbox";
import DataSourceFactory from "../../../../../utils/grid/data-source-factory";
import GridGrupoDeCentroDeTrabalhoCalendario from "../../../grupo-de-centro-de-trabalho-calendario/componentes/grid";
import { GrupoDeCentroDeTrabalhoCalendarioGridModel } from "../../../grupo-de-centro-de-trabalho-calendario/models/grupo-de-centro-de-trabalho-calendario";
import {
  CentroDeTrabalhoGrupoRequestDTO,
  CentroDeTrabalhoGrupoResponseDTO,
} from "../../models/grupo-de-centro-de-trabalho.api";
import GrupoDeCentroDeTrabalhoConstantes from "../../models/grupo-de-centro-de-trabalho.constantes";
import GrupoDeCentroDeTrabalhoServico from "../../servicos/grupo-de-centro-de-trabalho.service";

const service = new GrupoDeCentroDeTrabalhoServico();

let dadosAuditoria: AuditavelDTO | undefined = undefined;

const novoRegistro: CentroDeTrabalhoGrupoRequestDTO = {
  id: 0,
  codigo: "",
  descricao: null,
  idGrupoDeCentroDeTrabalhoCalendario: null,
};

const previneDigitacaoDeCaracteresHandler = (e: any) =>
  previneDigitacaoDeCaracteres(e, letrasDigitosSimbolosSemEspacos);

const dataSourceGrupoCentroDeTrabalhoCalendario =
  DataSourceFactory.CriarParaSelectBoxLazy<GrupoDeCentroDeTrabalhoCalendarioGridModel>(
    `${NomesEndpoints.GrupoDeCentroDeTrabalhoCalendario}/grid`,
    {
      camposRetorno: ["id", "codigo", "descricao"],
      camposOrdenacao: [
        {
          campo: "codigo",
          desc: false,
        },
      ],
    }
  );

const grupoCentroDeTrabalhoCalendarioExibicaoEBusca =
  assertConfiguracaoExibicaoEBuscaType<GrupoDeCentroDeTrabalhoCalendarioGridModel>(
    {
      nomeCampoChave: "id",
      nomeCampoExibicao: (c) => {
        if (c) {
          return c.descricao == null
            ? FormataDescricao(FormatadoresSelectBox.SomenteDescricao, c.codigo)
            : FormataDescricao(
                FormatadoresSelectBox.CodigoDescricaoParenteses,
                c.codigo,
                c.descricao
              );
        }

        return "";
      },
      expressaoDeBusca: ["codigo", "descricao"],
    }
  );

export default function FormGrupoDeCentroDeTrabalho(
  props: IFormularioEditavelBase
) {
  const [carregando, setCarregando] = useState(false);

  const schema = yup.object().shape({
    id: yup.number().required().moreThan(-1).integer(),
    codigo: yup
      .string()
      .required()
      .max(GrupoDeCentroDeTrabalhoConstantes.CodigoTamanhoMaximo)
      .matches(
        letrasApenasMaiusculasDigitosSimbolosSemEspacos,
        "Deve conter apenas letras maiúsculas, letras sem acento, números e símbolos"
      ),
    descricao: yup
      .string()
      .nullable()
      .max(GrupoDeCentroDeTrabalhoConstantes.DescricaoTamanhoMaximo),
    idCentroDeTrabalhoGrupo: yup.number().notRequired().nullable().positive(),
  });

  const hookForm = useForm<CentroDeTrabalhoGrupoRequestDTO>({
    resolver: yupResolver(schema),
  });

  const { register, control, handleSubmit, getValues, reset } = hookForm;

  //Hook usado para carregar os dados da tela
  useEffect(() => {
    if (Number.isNaN(props.idRegistroEmEdicao)) {
      return;
    }

    preencherTela();
  }, [props.idRegistroEmEdicao]);

  async function preencherTela() {
    if (props.idRegistroEmEdicao == 0) {
      limparTela();
    } else if (props.idRegistroEmEdicao > 0) {
      await carregarRegistroDoId();
    }
  }

  async function carregarRegistroDoId() {
    try {
      setCarregando(true);
      const resposta =
        await service.ObterPorIdComDadosAuditoria<CentroDeTrabalhoGrupoResponseDTO>(
          props.idRegistroEmEdicao
        );
      checarResponse(resposta);
      dadosAuditoria = resposta.model;
      reset(resposta.model);
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      setCarregando(false);
    }
  }

  function limparTela() {
    dadosAuditoria = undefined;
    reset(novoRegistro);
  }

  async function handleSalvar() {
    setCarregando(true);
    const model = getValues();
    try {
      const resposta =
        props.idRegistroEmEdicao > 0
          ? await service.Atualizar(model)
          : await service.Inserir(model);

      checarResponse(resposta);

      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: resposta.mensagem,
          tipo: TipoNotificacao.Sucesso,
        });

        fechar(ResultadoAcaoFormulario.AcaoConcluida);
      }
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    } finally {
      setCarregando(false);
    }
  }

  function fechar(resultado: ResultadoAcaoFormulario) {
    limparTela();
    props.handleCallback(resultado);
  }

  function callBackUnprocessableEntity() {
    fechar(ResultadoAcaoFormulario.AcaoConcluida);
  }

  function handleCancelar() {
    fechar(ResultadoAcaoFormulario.AcaoCancelada);
  }

  return (
    <ContainerFormMxp>
      <FormMxp carregando={carregando}>
        <ProvedorAjuda id="edit-form-grupo-centro-de-trabalho">
          <input type="hidden" {...register("id")} defaultValue={0} />
          <FormGrupo>
            <Linha>
              <Coluna md={6}>
                <FormTextBox
                  name="codigo"
                  titulo="Código"
                  control={control}
                  requerido
                  tamanhoMaximo={
                    GrupoDeCentroDeTrabalhoConstantes.CodigoTamanhoMaximo
                  }
                  transform="uppercase"
                  onKeyDown={previneDigitacaoDeCaracteresHandler}
                />
              </Coluna>
              <Coluna md={6}>
                <FormTextBox
                  name="descricao"
                  titulo="Descrição"
                  control={control}
                  tamanhoMaximo={
                    GrupoDeCentroDeTrabalhoConstantes.DescricaoTamanhoMaximo
                  }
                />
              </Coluna>
            </Linha>
            <Linha>
              <Coluna md={12}>
                <FormSelectBoxLazyMxp
                  name="idGrupoDeCentroDeTrabalhoCalendario"
                  titulo="Calendário do grupo de centros de trabalho"
                  control={control}
                  dataSource={dataSourceGrupoCentroDeTrabalhoCalendario}
                  configuracoesExibicaoEBusca={
                    grupoCentroDeTrabalhoCalendarioExibicaoEBusca
                  }
                  seletorConfig={{
                    modo: "selecaoUnica",
                    titulo: "Selecionar o calendário do grupo de CTs",
                    componenteGrid: <GridGrupoDeCentroDeTrabalhoCalendario />,
                  }}
                  labelSemDados="Sem dados"
                  permissoesNecessarias={[
                    PermissoesCalendarioDoGrupoDoCentroDeTrabalho.Consultar,
                  ]}
                />
              </Coluna>
            </Linha>
          </FormGrupo>
        </ProvedorAjuda>
      </FormMxp>
      <ToolbarMxp dadosAuditoria={dadosAuditoria}>
        <BotaoSalvarMxp handleClick={handleSubmit(handleSalvar)} />
        <BotaoCancelarMxp handleClick={handleCancelar} />
      </ToolbarMxp>
    </ContainerFormMxp>
  );
}
