import SelectItem from "../../../../models/shared/ui/select-item";
import { gerarSelectItemDoDicionario } from "../../../../utils/common/enum-utils";

export enum EstadoDoImobilizado {
  Ativo = 1,
  Baixado = 2,
}

export const estadoDoImobilizadoDecodificado: {
  [key in EstadoDoImobilizado]: string;
} = {
  [EstadoDoImobilizado.Ativo]: "Ativo",
  [EstadoDoImobilizado.Baixado]: "Baixado",
};

export enum TipoDeDepreciacao {
  Mensal = 1,
  Anual = 2,
}

export const tipoDeDepreciacaoDecodificado: {
  [key in TipoDeDepreciacao]: string;
} = {
  [TipoDeDepreciacao.Mensal]: "Mensal",
  [TipoDeDepreciacao.Anual]: "Anual",
};

export enum EstadoDaDepreciacao {
  NaoIniciada = 1,
  EmAndamento = 2,
  Concluida = 3,
}

export const estadoDaDepreciacaoDecodificado: {
  [key in EstadoDaDepreciacao]: string;
} = {
  [EstadoDaDepreciacao.NaoIniciada]: "Não iniciada",
  [EstadoDaDepreciacao.EmAndamento]: "Em andamento",
  [EstadoDaDepreciacao.Concluida]: "Concluída",
};

export enum EstadoDoCiapDoImobilizado {
  NaoIniciado = 1,
  EmAndamento = 2,
  Concluido = 3,
}

export const estadoDoCiapDoImobilizadoDecodificado: {
  [key in EstadoDoCiapDoImobilizado]: string;
} = {
  [EstadoDoCiapDoImobilizado.NaoIniciado]: "Não iniciado",
  [EstadoDoCiapDoImobilizado.EmAndamento]: "Em andamento",
  [EstadoDoCiapDoImobilizado.Concluido]: "Concluído",
};

export const SelectItemEnumEstadoDoImobilizado: SelectItem[] =
  gerarSelectItemDoDicionario(estadoDoImobilizadoDecodificado);

export const SelectItemEnumTipoDeDepreciacao: SelectItem[] =
  gerarSelectItemDoDicionario(tipoDeDepreciacaoDecodificado);

export const SelectItemEnumEstadoDaDepreciacao: SelectItem[] =
  gerarSelectItemDoDicionario(estadoDaDepreciacaoDecodificado);

export const SelectItemEnumEstadoDoCiapDoImobilizado: SelectItem[] =
  gerarSelectItemDoDicionario(estadoDoCiapDoImobilizadoDecodificado);

export enum ResultadoBuscaItemNotaRecebida {
  Encontrado = 0,
  NaoEncontrado = 1,
  DivergenciaSerie = 2,
  DivergenciaFornecedor = 3,
  DivergenciaSerieEFornecedor = 4,
}
