import DataGrid, { Column, DataGridRef } from "devextreme-react/cjs/data-grid";
import { useCallback, useMemo, useRef } from "react";
import { useRegistrarAtalhosGrid } from "../../../../../hooks/atalhos.hooks";
import { ProjecaoDeUsoDaOrdemDeProducaoGridModel } from "../../../../../models/api/projecao-de-uso/projecao-de-uso-da-ordem-de-producao-grid-model";
import { GridMxpProps } from "../../../../../models/shared/ui/grid";
import GetColunasDeAuditoria from "../../../../../parts/layout/grid-defaults/colunasDeAuditoria";
import criarNameof from "../../../../../utils/common/cria-name-of";
import GridBuilder from "../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../utils/grid/grid-controller";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import { OrdemDeProducaoService } from "../../servicos/ordem-de-producao.service";

const service = new OrdemDeProducaoService();
const nameOfGridHandler =
  criarNameof<ProjecaoDeUsoDaOrdemDeProducaoGridModel>();

interface GridEmbutidaProjecoesDeUsoDaOrdemDeProducaoProps
  extends GridMxpProps<ProjecaoDeUsoDaOrdemDeProducaoGridModel> {
  ordemDeProducaoId: number;
}

function calculateProjecaoDeUsoParaCellValue(
  projecaoDeUso: ProjecaoDeUsoDaOrdemDeProducaoGridModel
) {
  if (!projecaoDeUso) {
    return "";
  } else if (projecaoDeUso.operacaoDeOrdemDeProducaoDestinoId !== null) {
    return `Ordem de produção ${
      projecaoDeUso.numeroOrdemDeProducaoDestino ?? "sem nº"
    } #${projecaoDeUso.numeroOperacaoDaOrdemDeProducaoDestino}`;
  } else if (projecaoDeUso.itemPedidoDeVendaDestinoId) {
    return `Pedido de venda ${
      projecaoDeUso.numeroPedidoDeVendaDestino ?? "sem nº"
    } #${projecaoDeUso.numeroItemDoPedidoDeVendaDestino}`;
  } else {
    return `Solicitação de envio para terceirização ${
      projecaoDeUso.codigoSolicitacaoDestino ?? "sem nº"
    } - Fornecedor: ${
      projecaoDeUso.apelidoFornecedorOuTerceiroSolicitacaoDestino ?? "A definir"
    }`;
  }
}

export default function GridEmbutidaProjecoesDeUsoDaOrdemDeProducao(
  props: GridEmbutidaProjecoesDeUsoDaOrdemDeProducaoProps
) {
  const gridRef = useRef<DataGridRef>(null);

  const dataSource = service.ObterDataSourceParaGridProjecoesDeUso(
    props.ordemDeProducaoId,
    props.filtrosNoServidor
  );

  const gridController = useMemo(
    () =>
      new GridController<ProjecaoDeUsoDaOrdemDeProducaoGridModel>(() =>
        gridRef.current?.instance()
      ),
    []
  );

  const handleAtualizarGrid = useCallback(() => {
    gridController.atualizar();
  }, [gridController]);

  useRegistrarAtalhosGrid({
    controller: gridController,
    // Adicionar handlers de manipulação se/quando forem criados.
  });

  const configuracoesGrid = useMemo(() => {
    const isGridInterna = false;
    return GridBuilder.criar(
      "projecoes-de-uso-op",
      () => gridRef?.current?.instance(),
      isGridInterna,
      props.filtrosNoCliente
    )
      .definirStyles(props.style)
      .definirDataSource(dataSource)
      .definirRolagem()
      .configurarSelecionadorDeColunas()
      .definirFiltros()
      .definirGravacaoPreferenciasGrid()
      .definirPaginacao()
      .configurarExportacao("Projeções de uso da Ordem de Produção")
      .definirBotaoRefresh(handleAtualizarGrid)
      .definirSelecao("none")
      .definirOrdenacao()
      .build();
  }, [props.filtrosNoCliente, props.style, dataSource, handleAtualizarGrid]);

  return (
    <DataGrid ref={gridRef} {...configuracoesGrid}>
      <Column
        key={"projecaoDeUsoPara"}
        name={"projecaoDeUsoPara"}
        {...obterConfiguracaoColuna("stringXG")}
        caption="Projeção de uso para"
        allowFiltering={false}
        allowSorting={false}
        width={480}
        calculateCellValue={calculateProjecaoDeUsoParaCellValue}
      />
      <Column
        key={nameOfGridHandler("apelidoClientePedidoDeVenda")}
        dataField={nameOfGridHandler("apelidoClientePedidoDeVenda")}
        {...obterConfiguracaoColuna("stringGG")}
        caption="Cliente"
      />
      <Column
        key={nameOfGridHandler("dataDeEntregaItemPedidoDeVenda")}
        dataField={nameOfGridHandler("dataDeEntregaItemPedidoDeVenda")}
        {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
        caption="Data de entrega"
        width={150}
      />
      <Column
        key={nameOfGridHandler("quantidade")}
        dataField={nameOfGridHandler("quantidade")}
        {...obterConfiguracaoColuna("quantidade")}
        caption="Quantidade"
      />
      <Column
        key={nameOfGridHandler("codigoItem")}
        dataField={nameOfGridHandler("codigoItem")}
        {...obterConfiguracaoColuna("stringG")}
        caption="Item"
      />
      <Column
        key={nameOfGridHandler("descricaoItem")}
        dataField={nameOfGridHandler("descricaoItem")}
        {...obterConfiguracaoColuna("stringXG")}
        caption="Descrição do item"
      />
      {GetColunasDeAuditoria(false, false, [
        { ordenarPor: "criacaoData", ordem: "desc" },
      ])}
    </DataGrid>
  );
}
