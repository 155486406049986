import axios, {
  AxiosRequestConfig,
  AxiosResponse,
  HttpStatusCode,
} from "axios";
import { StringsComum } from "../../features/comum/strings";
import {
  TokenRefreshRequest,
  TokenResponse,
} from "../../features/seguranca/login/models/login.api";
import TokenService from "../../features/seguranca/login/servicos/token.servico";
import { AnexoResponse } from "../../models/api/comum/anexo-response";
import Sessao from "../../models/dto/sessao/sessao";
import { EstadosAtualizacaoToken } from "../../models/shared/ui/estado-ui";
import { NomesEndpoints } from "../../services/comum/nomesEndpoints";
import store from "../../store";
import {
  definirEstadoAtualizacaoToken,
  definirMensagemLogoff,
} from "../../store/ui/ui.slice";
import { tratarErroApi } from "../../utils/api/api-utils";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../utils/common/notificacoes-utils";
import {
  GravarSessaoReduxELocalStorage,
  RemoverSessaoReduxELocalStorage,
} from "../../utils/oauth/oauth-utils";
import GestorLocalStorage, {
  ChavesLocalstorage,
} from "../../utils/storage/gestor-storage";

const baseHeaders = {
  "Content-Type": "application/json",
  Accept: "*/*",
  "Accept-Language": "pt-BR,pt;q=0.9,en-US;q=0.8,en;q=0.7",
} as any;

const endpoint = process.env.REACT_APP_BACKEND_ENDPOINT as string;

const tokenService = new TokenService();

// Feito dessa forma pois não é possível utilizar hooks fora de um componente
const getEstatoAtualizacaoToken = () =>
  store.getState().estadoUI.atualizandoToken;
const getExpiracaoData = (dateString?: string) =>
  dateString ? new Date(dateString) : null;
const callLogout = (mensagem?: string) => {
  logoffBackEnd()
    .then(() => {
      const url = window.location.href;
      if (mensagem) {
        store.dispatch(definirMensagemLogoff(mensagem));
      }
      RemoverSessaoReduxELocalStorage();
      if (url.includes("/oauth?")) {
        setTimeout(() => {
          window.location.href = url;
        }, 100);
      }
    })
    .catch((erro) => {
      tratarErroApi(erro);
      RemoverSessaoReduxELocalStorage(true);
    });
};
const callDefinirEstadoAtualizacaoToken = (
  atualizado: EstadosAtualizacaoToken
) => {
  store.dispatch(definirEstadoAtualizacaoToken(atualizado));
};

async function logoffBackEnd() {
  const playload = store.getState().sessao.dadosSessao?.chaveSessao;
  if (playload) {
    await tokenService.efetuarLogoffPayload(playload);
  } else {
    await tokenService.efetuarLogoff();
  }
}

declare global {
  interface Window {
    requisicoesAbertas: number;
  }
}

export default function getApi(tokenResponse?: TokenResponse) {
  const dadosLocalStorage = GestorLocalStorage.LerItem<TokenResponse>(
    ChavesLocalstorage.DadosSessao
  );

  const token = tokenResponse?.token ?? dadosLocalStorage?.token;
  const expiracao =
    getExpiracaoData(tokenResponse?.refreshTokenExpiryTime) ??
    getExpiracaoData(dadosLocalStorage?.refreshTokenExpiryTime);

  const config: AxiosRequestConfig = {
    baseURL: endpoint,
    headers: getHeaders(tokenResponse?.token),
  };

  const api = axios.create(config);

  api.interceptors.request.use(async (request) => {
    if (token && expiracao && expiracao.getTime() <= Date.now()) {
      await atualizarToken("atualizando", {}, false);
    }

    if (window.requisicoesAbertas >= 0) {
      window.requisicoesAbertas++;
    } else {
      window.requisicoesAbertas = 1;
    }

    return request;
  });

  api.interceptors.response.use(
    (response) => {
      window.requisicoesAbertas--;
      return response;
    },
    async (error) => {
      window.requisicoesAbertas--;
      if (error?.response?.status === HttpStatusCode.Unauthorized && token) {
        await atualizarToken("atualizando", error, false);
      }
      //Ocorre quando a sessão é derrubada
      if (error?.response?.status === HttpStatusCode.Locked) {
        const msg: string =
          typeof error?.response?.data == "string"
            ? error?.response?.data
            : error?.response?.data?.mensagem;
        callLogout(msg);
      }
      return Promise.reject(error);
    }
  );

  return api;
}

export function getHeaders(tokenResponse?: string | undefined) {
  const headers = baseHeaders;

  const dadosLocalStorage = GestorLocalStorage.LerItem<TokenResponse>(
    ChavesLocalstorage.DadosSessao
  );

  const token = tokenResponse ?? dadosLocalStorage?.token;

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  return headers;
}

export function getUrl(pathRelativo: string) {
  const url = new URL(pathRelativo, endpoint);
  return url.href;
}

export async function atualizarToken(
  motivo: EstadosAtualizacaoToken,
  error: any,
  atualizarSessao: boolean
) {
  if (getEstatoAtualizacaoToken() != "ocioso") {
    return;
  }
  try {
    callDefinirEstadoAtualizacaoToken(motivo);
    const config: AxiosRequestConfig = {
      baseURL: endpoint,
      headers: getHeaders(),
    };

    const dadosLocalStorage = GestorLocalStorage.LerItem<TokenResponse>(
      ChavesLocalstorage.DadosSessao
    );

    const api = axios.create(config);
    const payload: TokenRefreshRequest = {
      token: dadosLocalStorage?.token as string,
      refreshToken: dadosLocalStorage?.refreshToken as string,
      editarSessao: atualizarSessao,
    };

    const response = await api.post<
      TokenRefreshRequest,
      AxiosResponse<TokenResponse>
    >(`${NomesEndpoints.Login}/refresh`, payload);

    // Necessário para obter os dados da sessão novamente
    await AtualizarDadosSessao(response.data);

    return response;
  } catch (erroExecucao) {
    const ehFalhaConexaoBackend = verificarFalhaConexaoBackend(erroExecucao);
    if (!store.getState().estadoUI.redirecionando) {
      exibirNotificacaoToast({
        mensagem: ehFalhaConexaoBackend
          ? StringsComum.falhaConexaoServidor
          : StringsComum.suaSessaoExpirou,
        tipo: ehFalhaConexaoBackend
          ? TipoNotificacao.Erro
          : TipoNotificacao.Advertencia,
      });

      if (!ehFalhaConexaoBackend) {
        callLogout();
      }

      throw error;
    }
  } finally {
    callDefinirEstadoAtualizacaoToken("ocioso");
  }
}

export async function processarRequestComPossivelArquivo(
  response: AxiosResponse
): Promise<AnexoResponse> {
  const infoAnexo = response.headers["content-disposition"] as string;

  if (
    !infoAnexo.includes("attachment; ") &&
    response.headers["Content-Type"] == "application/json"
  ) {
    const json = await (response.data as Blob).text();
    return {
      response: JSON.parse(json),
    };
  } else if (infoAnexo.includes("attachment;")) {
    // Regex para capturar 'filename' e 'filename*'
    const filenameRegex = /filename\*?=["']?([^;]*)["']?/g;
    let nome: string | undefined;
    let extensao: string | undefined;

    let matches: RegExpExecArray | null;
    while ((matches = filenameRegex.exec(infoAnexo)) !== null) {
      // Priorizar filename* se estiver presente
      if (matches[0].startsWith("filename*=")) {
        // Se o campo for 'filename*', a parte após "UTF-8''" contém o nome codificado
        const filenameEncoded = matches[1].split("''")[1]; // Pega a parte após "UTF-8''"
        nome = decodeURIComponent(filenameEncoded); // Decodifica para o nome correto
      } else if (matches[0].startsWith("filename=")) {
        // Se o campo for 'filename', use-o como fallback
        nome = matches[1];
      }
    }

    if (nome) {
      // Separar nome do arquivo e a extensão
      const parts = nome.split(".");
      if (parts.length > 1) {
        extensao = parts.pop(); // Pega a última parte como extensão
      }

      nome = parts.join("."); // Junta o nome sem a extensão
    }

    return {
      response: response.data,
      nomeArquivo: nome,
      extensao: extensao,
    };
  }

  return {
    response: response.data,
  };
}

async function AtualizarDadosSessao(token: TokenResponse) {
  const dadosSessao = await tokenService.getDadosSessao(token);

  const sessao: Sessao = {
    dadosSessao: dadosSessao,
  };

  GravarSessaoReduxELocalStorage(sessao, token);
}

function verificarFalhaConexaoBackend(erro: any) {
  const codigosErrosOffline = ["ECONNABORTED", "ERR_NETWORK"];
  return codigosErrosOffline.some((x) => x == erro?.code);
}
