import TabContainer from "../../../../../../../components/layout/tab-container";
import ProvedorMenus from "../../../../../../../components/menus/provedor-menus";
import RequerPermissao from "../../../../../../../components/seguranca/requer-permissao";
import { PermissoesReserva } from "../../../../../../../models/permissoes/estoque/reserva/permissoes-reserva";
import { gridEmbutidaStyle } from "../../../../../../comum/utils/styles/grid";
import GridEmbutidaProjecoesDeUsoDaOrdemDeProducao from "../../../grid-embutida/projecoes-de-uso-da-ordem-de-producao-grid";

interface OrdemDeProducaoAbaProjecoesDeUsoProps {
  idRegistro: number;
}

export default function OrdemDeProducaoAbaProjecoesDeUso({
  idRegistro,
}: OrdemDeProducaoAbaProjecoesDeUsoProps) {
  return (
    <TabContainer>
      <ProvedorMenus>
        <RequerPermissao
          codigoPermissoes={[
            PermissoesReserva.ConsultarReservasEProjecoesDeUso,
          ]}
        >
          <GridEmbutidaProjecoesDeUsoDaOrdemDeProducao
            ordemDeProducaoId={idRegistro}
            style={gridEmbutidaStyle}
          />
        </RequerPermissao>
      </ProvedorMenus>
    </TabContainer>
  );
}
