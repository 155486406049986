import { useMemo } from "react";
import { FieldValues, UseControllerProps } from "react-hook-form";
import { FormSelectBoxLazyMxp } from "../../../../../../components/formularios";
import { ItemSelectionChangedType } from "../../../../../../components/formularios/selectbox-lazy-mxp";
import { EnderecoEstoqueGridModel } from "../../../../../../models/api/endereco-estoque/endereco-estoque";
import { PermissoesEnderecoDeEstoque } from "../../../../../../models/permissoes/estoque/endereco-de-estoque/permissoes-endereco-de-estoque";
import { DataSourceOpcoesBuilder } from "../../../../../../utils/grid/data-source-factory";
import { EnderecoDeEstoqueService } from "../../../service/endereco-estoque.service";
import EnderecoEstoqueFiltrosHelpers from "../../../utils/data-source/EnderecoEstoqueFiltrosHelpers";
import GridEnderecoEstoque from "../../grids";

interface ComboEnderecoEstoqueMxpProps<T extends FieldValues>
  extends UseControllerProps<T> {
  titulo: string;
  tituloSeletor?: string;
  requerido?: boolean;
  filtrosNoCliente?: any[] | undefined;
  dataSourceOpcoes?: DataSourceOpcoesBuilder<EnderecoEstoqueGridModel>;
  somenteLeitura?: boolean;
  onSelectionChanged?:
    | ((e: ItemSelectionChangedType) => Promise<void>)
    | (() => void);
}

const service = new EnderecoDeEstoqueService();

export default function ComboEnderecoEstoqueMxp<T extends FieldValues>(
  props: ComboEnderecoEstoqueMxpProps<T>
) {
  const [dataSource, configuracoesExibicaoEBusca] = useMemo(
    () => service.GetDadosSelectBoxEnderecoDeEstoque(props.dataSourceOpcoes),
    [props.dataSourceOpcoes]
  );

  return (
    <FormSelectBoxLazyMxp
      name={props.name}
      titulo={props.titulo}
      control={props.control}
      requerido={props.requerido}
      configuracoesExibicaoEBusca={configuracoesExibicaoEBusca}
      somenteLeitura={props.somenteLeitura}
      onSelectionChanged={props.onSelectionChanged}
      dataSource={dataSource}
      seletorConfig={{
        modo: "selecaoUnica",
        titulo: props.tituloSeletor ?? "Selecionar item",
        componenteGrid: (
          <GridEnderecoEstoque
            filtrosNoServidor={
              props.dataSourceOpcoes?.camposFiltro ||
              EnderecoEstoqueFiltrosHelpers.FiltrarAtivos
            }
            filtrosNoCliente={props.filtrosNoCliente}
          />
        ),
      }}
      labelSemDados="Sem dados"
      permissoesNecessarias={[PermissoesEnderecoDeEstoque.Consultar]}
    />
  );
}
