import { format } from "date-fns";

export function formatarDataHoraComFormato(
  data: Date | string | null,
  formato: string
): string {
  if (!data) {
    return "";
  }
  const d = data instanceof Date ? data : new Date(data);
  return format(d, formato);
}

export function formartarDataParaSerializacao(data: Date | string) {
  return formatarDataHoraComFormato(data, "yyyy-MM-dd'T'HH:mm:ss");
}

export function formatarDataHoraAno4digitos(
  data: Date | string | null
): string {
  return formatarDataHoraComFormato(data, "dd/MM/yyyy HH:mm:ss");
}

export function formatarDataAno4digitos(data: Date | string | null): string {
  return formatarDataHoraComFormato(data, "dd/MM/yyyy");
}

export function formatarDataHoraAno2digitos(
  data: Date | string | null
): string {
  return formatarDataHoraComFormato(data, "dd/MM/yy HH:mm:ss");
}

export function formatarDataAno2digitos(data: Date | string | null): string {
  return formatarDataHoraComFormato(data, "dd/MM/yy");
}

export function formatarHora(data: Date | string | null): string {
  return formatarDataHoraComFormato(data, "HH:mm:ss");
}
