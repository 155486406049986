import getApi, {
  processarRequestComPossivelArquivo,
} from "../../../../configs/api";
import {
  ResponseBase,
  ResponseModel,
} from "../../../../models/api/comum/response-base";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import DataSourceFactory from "../../../../utils/grid/data-source-factory";
import { FinalidadeModeloRelatorio } from "../../../relatorio/relatorio-personalizado/models/relatorio-personalizado.enums";
import {
  FavoritarModeloRelatorioRequest,
  ModeloDeRelatorioDaFinalidadeResponse,
  ModeloDeRelatorioGridModel,
  ModeloDeRelatorioRequest,
  ModeloDeRelatorioResponse,
  RestaurarFavoritoRequest,
} from "../models/modelo-de-relatorio.api";

export default class ModeloDeRelatorioServico {
  private _nomeEndpoint: NomesEndpoints;

  constructor() {
    this._nomeEndpoint = NomesEndpoints.ModeloDeRelatorio;
  }

  public ObterDataSourceParaGrid(finalidade: FinalidadeModeloRelatorio) {
    return DataSourceFactory.CriarParaGrid<ModeloDeRelatorioGridModel>(
      `${this._nomeEndpoint}/grid`,
      [{ campo: "finalidade", operador: "=", valor: finalidade }]
    );
  }

  public async RealizarDownload(id: number) {
    const api = getApi();
    const response = await api.get(`${this._nomeEndpoint}/Download?id=${id}`, {
      responseType: "blob",
    });
    return await processarRequestComPossivelArquivo(response);
  }

  public async ObterPorIdComDadosAuditoria(id: number) {
    const api = getApi();
    const response = await api.get<ResponseModel<ModeloDeRelatorioResponse>>(
      `${this._nomeEndpoint}/ObterPorIdComDadosAuditoria?id=${id}`
    );

    return response.data;
  }

  public async Inserir(model: ModeloDeRelatorioRequest) {
    const api = getApi();
    const response = await api.post<ResponseBase>(this._nomeEndpoint, model, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  }

  public async Atualizar(model: ModeloDeRelatorioRequest) {
    const api = getApi();
    const response = await api.put<ResponseBase>(this._nomeEndpoint, model, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  }

  public async Excluir(id: number) {
    const api = getApi();
    const response = await api.delete<ResponseBase>(
      `${this._nomeEndpoint}?id=${id}`
    );

    return response.data;
  }

  public async Favoritar(model: FavoritarModeloRelatorioRequest) {
    const api = getApi();
    const response = await api.post<ResponseBase>(
      `${this._nomeEndpoint}/Favoritar`,
      model
    );
    return response.data;
  }

  public async RestaurarFavoritoPadrao(model: RestaurarFavoritoRequest) {
    const api = getApi();
    const response = await api.post<ResponseBase>(
      `${this._nomeEndpoint}/RestaurarFavoritoPadrao`,
      model
    );
    return response.data;
  }

  public async ObterModelosDaFinalidade(finalidade: FinalidadeModeloRelatorio) {
    const api = getApi();
    const response = await api.get<
      ResponseModel<ModeloDeRelatorioDaFinalidadeResponse[]>
    >(`${this._nomeEndpoint}/ModelosDaFinalidade?finalidade=${finalidade}`);

    return response.data;
  }
}
