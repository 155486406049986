import PaginaMxp from "../../../../../components/layout/pagina-mxp";
import ProvedorMenus from "../../../../../components/menus/provedor-menus";
import RequerPermissao from "../../../../../components/seguranca/requer-permissao";
import { PermissoesCodigoTributacaoServico } from "../../../../../models/permissoes/fiscal/codigo-tributacao-servico/permissoes-codigo-tributacao-servico";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import GridCodigoTributacaoServico from "../../componentes/grid";

export default function CodigoTributacaoServicoPage() {
  return (
    <ProvedorMenus>
      <PaginaMxp
        id="codigo-tributacao-servico-page"
        titulo={NomesTelas.codigosTributacaoServicos}
        area={["Fiscal", "Cadastros"]}
      >
        <RequerPermissao
          codigoPermissoes={[PermissoesCodigoTributacaoServico.Consultar]}
        >
          <GridCodigoTributacaoServico />
        </RequerPermissao>
      </PaginaMxp>
    </ProvedorMenus>
  );
}
