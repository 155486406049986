import { AxiosRequestConfig } from "axios";
import getApi from "../../configs/api";
import {
  ResponseBaseArquivo,
  ResponseModel,
} from "../../models/api/comum/response-base";
import { SituacaoMDFe } from "../../models/api/mdfe/mdfe-enums";
import {
  MDFeCancelamentoResponseDTO,
  MDFeCompletoDTO,
  MDFeEncerramentoResponseDTO,
  MDFeEnvioResponseDTO,
} from "../../models/api/mdfe/mdfe-request-response";
import { NomesEndpoints } from "../comum/nomesEndpoints";

const nomeEndpoint = NomesEndpoints.MDFe;

async function downloadXmlMdfe(id: number, situacao: SituacaoMDFe) {
  const api = getApi();

  const situacaoEndpoints: any = {
    [SituacaoMDFe.Autorizado]: `${nomeEndpoint}/XMLAutorizacao`,
    [SituacaoMDFe.Cancelado]: `${nomeEndpoint}/XMLCancelamento`,
    [SituacaoMDFe.Encerrado]: `${nomeEndpoint}/XMLEncerramento`,
  };

  const endpoint: string = situacaoEndpoints[situacao];
  const resquestConfig: AxiosRequestConfig = {
    responseType: "blob",
  };

  if (endpoint) {
    const response = await api.get<ResponseBaseArquivo>(
      `${endpoint}?id=${id}`,
      resquestConfig
    );
    return response;
  } else {
    throw new Error(
      "Situação da MDFe precisa ser Autorizado, Cancelado ou Encerrado."
    );
  }
}

async function consultarDamdfeMdfe(id: number) {
  const api = getApi();
  const resquestConfig: AxiosRequestConfig = {
    responseType: "blob",
  };
  const response = await api.get<ResponseBaseArquivo>(
    `${nomeEndpoint}/DAMDFE?id=${id}`,
    resquestConfig
  );

  return response;
}

async function desfazerValidacao(id: number) {
  const api = getApi();
  const response = await api.patch<ResponseModel<MDFeCompletoDTO>>(
    `${nomeEndpoint}/DesfazerValidacao?id=${id}`
  );

  return response.data;
}

async function encerrarMDFe(idMdfeDocumento: number, dataEncerramento: string) {
  const api = getApi();
  const response = await api.post<ResponseModel<MDFeEncerramentoResponseDTO>>(
    `${nomeEndpoint}/Encerrar?id=${idMdfeDocumento}&dataEncerramento=${dataEncerramento}`
  );

  return response.data;
}

async function inserir(model: MDFeCompletoDTO) {
  const api = getApi();
  const response = await api.post<ResponseModel<MDFeCompletoDTO>>(
    "MDFe",
    model
  );

  return response.data;
}

async function atualizar(model: MDFeCompletoDTO) {
  const api = getApi();
  const response = await api.put<ResponseModel<MDFeCompletoDTO>>("MDFe", model);

  return response.data;
}

async function autorizar(id: number) {
  const api = getApi();
  const response = await api.post<ResponseModel<MDFeEnvioResponseDTO>>(
    `${nomeEndpoint}/Enviar?id=${id}`
  );

  return response.data;
}

async function validar(model: MDFeCompletoDTO) {
  const api = getApi();
  const response = await api.put<ResponseModel<MDFeCompletoDTO>>(
    `${nomeEndpoint}/Validar`,
    model
  );

  return response.data;
}

async function cancelarMDFe(idMDFeDocumento: number, justificativa: string) {
  const api = getApi();
  const response = await api.post<ResponseModel<MDFeCancelamentoResponseDTO>>(
    `${nomeEndpoint}/Cancelar?id=${idMDFeDocumento}&motivoCancelamento=${justificativa}`
  );

  return response.data;
}

async function consultarSituacao(id: number) {
  const api = getApi();
  const response = await api.post<ResponseModel<MDFeCompletoDTO>>(
    `${nomeEndpoint}/ConsultarSituacao?id=${id}`
  );

  return response.data;
}

async function duplicar(id: number) {
  const api = getApi();
  const response = await api.post<ResponseModel<MDFeCompletoDTO>>(
    `MDFe/Duplicar?id=${id}`
  );

  return response.data;
}

export default {
  desfazerValidacao,
  encerrarMDFe,
  autorizar,
  validar,
  inserir,
  atualizar,
  cancelarMDFe,
  downloadXmlMdfe,
  consultarDamdfeMdfe,
  consultarSituacao,
  duplicar,
};
