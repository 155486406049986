import { yupResolver } from "@hookform/resolvers/yup";
import DataSource from "devextreme/data/data_source";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import ProvedorAjuda from "../../../../../components/ajuda/provedor-ajuda";
import BotaoCancelarMxp from "../../../../../components/botoes/botao-cancelar-mxp";
import BotaoSalvarMxp from "../../../../../components/botoes/botao-salvar-mxp";
import {
  FormGrupo,
  FormNumberBox,
  FormSelectBox,
  FormSelectBoxLazy,
  FormTextBox,
  FormTextBoxSimples,
} from "../../../../../components/formularios";
import FormMxp from "../../../../../components/layout/form";
import { ContainerFormMxp } from "../../../../../components/layout/form/styles";
import { Coluna, Linha } from "../../../../../components/layout/grid-system";
import ToolbarMxp from "../../../../../components/layout/toolbar-mxp";
import { useAppSelector } from "../../../../../hooks/store.hooks";
import AuditavelDTO from "../../../../../models/api/comum/auditavel-dto";
import { SelectBoxLazyFiltros } from "../../../../../models/api/comum/selectboxlazy-options";
import {
  SelectItemEnumTipoCarroceria,
  SelectItemEnumTipoProprietario,
  SelectItemEnumTipoRodado,
  SelectItemEnumTipoUnidadeTransporte,
} from "../../../../../models/const/dicionario-combos/veiculo";
import {
  IFormularioEditavelBase,
  ResultadoAcaoFormulario,
} from "../../../../../models/shared/ui/formularios";
import SelectItem from "../../../../../models/shared/ui/select-item";
import { NomesEndpoints } from "../../../../../services/comum/nomesEndpoints";
import APIBase from "../../../../../services/comum/serviceBase";
import APIEmpresa from "../../../../../services/empresa/empresa.service";
import APIUF from "../../../../../services/uf/uf.service";
import {
  checarResponse,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../utils/common/notificacoes-utils";
import { obterFormatStringNumero } from "../../../../../utils/formatadores/formatador-de-numeros";
import {
  FormataDescricao,
  FormatadoresSelectBox,
} from "../../../../../utils/formatadores/formatador-de-selectbox";
import {
  obterMensagemQuantidadeMinimaCaracteres,
  StringsComum,
} from "../../../../comum/strings";
import { VeiculoRequestDTO, VeiculoResponseDTO } from "../../models/veiculo";
import VeiculoConstantes from "../../models/veiculo.constantes";
import {
  TipoCarroceria,
  TipoProprietario,
  TipoRodado,
  TipoUnidadeTransporte,
} from "../../models/veiculo.enums";
import VeiculoServico from "../../servicos/veiculo.servico";
import { StringsVeiculo } from "../../strings";

const novoRegistro: VeiculoRequestDTO = {
  id: 0,
  descricao: "",
  tipoRodado: TipoRodado.Outros,
  placa: "",
  renavam: null,
  idUF: 0,
  tara: null,
  capacidadeKG: null,
  capacidadeM3: null,
  tipoCarroceria: TipoCarroceria.Aberta,
  unidadeTransporte: TipoUnidadeTransporte.Outros,
  tipoProprietario: null,
  idProprietario: null,
};

const nomeEndpointEmpresa = NomesEndpoints.Empresa;

const service = new VeiculoServico();

let dadosAuditoria: AuditavelDTO | undefined = undefined;

const exibeProprietario = (c: any) => {
  if (c) {
    return c.CnpjCpfCodigo
      ? FormataDescricao(
          FormatadoresSelectBox.CodigoDescricaoParenteses,
          c.Apelido,
          c.CnpjCpfCodigo
        )
      : c.Apelido;
  }

  return "";
};

const buscaProprietario = [
  "Apelido",
  "RntrcProprietario",
  "CnpjCpfCodigo",
  "EstadoCadastro",
];

export default function FormVeiculo(props: IFormularioEditavelBase) {
  const [carregando, setCarregando] = useState(false);
  const [ufs, setUf] = useState<SelectItem[]>([]);
  const [proprietarios, setProprietario] = useState<DataSource>();
  const [rntrcProprietario, setRntrcProprietario] = useState<string>("");
  const [estadoCadastroProprietario, setEstadoCadastroProprietario] =
    useState<string>("");
  const idEmpresa = useAppSelector(
    (state) => state.sessao.dadosSessao?.empresa.id
  );

  const schema = yup.object().shape({
    id: yup
      .number()
      .required()
      .moreThan(VeiculoConstantes.ValorMinimoMaiorQue)
      .integer(),
    descricao: yup
      .string()
      .required()
      .max(VeiculoConstantes.DescricaoTamanhoMaximo),
    tipoRodado: yup
      .mixed<TipoRodado>()
      .transform((v) => (v ? v : null))
      .oneOf(
        Object.values(TipoRodado).map((x) => x as number),
        StringsComum.valorInvalido
      )
      .required(),
    placa: yup
      .string()
      .required()
      .min(VeiculoConstantes.PlacaTamanho)
      .max(VeiculoConstantes.PlacaTamanho),
    renavam: yup
      .string()
      .nullable()
      .matches(VeiculoConstantes.RenavamRegex, {
        excludeEmptyString: true,
        message: obterMensagemQuantidadeMinimaCaracteres(9),
      })
      .max(VeiculoConstantes.RenavamTamanhoMaximo),
    idUF: yup
      .number()
      .required()
      .positive()
      .integer()
      .transform((v) => (v ? v : null))
      .oneOf(
        ufs.map((x) => x.valor as number),
        StringsComum.valorInvalido
      ),
    tara: yup
      .number()
      .required()
      .integer()
      .min(VeiculoConstantes.TaraTamanhos.Minimo)
      .max(VeiculoConstantes.TaraTamanhos.Maximo),
    capacidadeKG: yup.number().when("unidadeTransporte", {
      is: TipoUnidadeTransporte.RodoviarioTracao,
      then: (schema) =>
        schema
          .required()
          .transform((v) => (v ? v : null))
          .integer()
          .min(VeiculoConstantes.CapacidadeKgTamanhos.Minimo),
      otherwise: (schema) => schema.notRequired().nullable(),
    }),
    capacidadeM3: yup
      .number()
      .nullable()
      .integer()
      .min(VeiculoConstantes.CapacidadeM3Tamanhos.Minimo),
    tipoCarroceria: yup
      .mixed<TipoCarroceria>()
      .transform((v) => (v >= 0 ? v : null))
      .oneOf(
        Object.values(TipoCarroceria).map((x) => x as number),
        StringsComum.valorInvalido
      )
      .required(),
    unidadeTransporte: yup
      .mixed<TipoUnidadeTransporte>()
      .transform((v) => (v ? v : null))
      .oneOf(
        Object.values(TipoUnidadeTransporte).map((x) => x as number),
        StringsComum.valorInvalido
      )
      .required(),
    tipoProprietario: yup
      .mixed<TipoProprietario>()
      .transform((v) => (v >= 0 ? v : null))
      .oneOf(
        Object.values(TipoProprietario).map((x) => x as number),
        StringsComum.valorInvalido
      )
      .nullable()
      .test(
        "tipoProprietario_idProprietario",
        StringsVeiculo.tipoProprietarioRequeridoMedianteIdProprietario,
        function (value) {
          return !(this.parent.idProprietario != null && value == null);
        }
      ),
    idProprietario: yup
      .number()
      .notRequired()
      .nullable()
      .positive()
      .test(
        "idProprietario_tipoProprietario",
        StringsVeiculo.idProprietarioRequeridoMedianteTipoProprietario,
        function (value) {
          return !(value == null && this.parent.tipoProprietario != null);
        }
      )
      .test(
        "idProprietario_estadoCadastro",
        StringsVeiculo.cadastroProprietarioPrecisaEstarCompleto,
        function (value) {
          return !(
            value != null &&
            estadoCadastroProprietario !==
              VeiculoConstantes.EstadoCadastroProprietarioCompleto
          );
        }
      ),
  });

  const { register, control, handleSubmit, getValues, reset, watch } =
    useForm<VeiculoRequestDTO>({
      resolver: yupResolver(schema),
    });

  //Hook usado para carregar os dados da tela
  useEffect(() => {
    if (Number.isNaN(props.idRegistroEmEdicao)) {
      return;
    }

    preencherTela();
  }, [props.idRegistroEmEdicao]);

  async function preencherTela() {
    carregarUfs();
    carregarProprietarios();

    if (props.idRegistroEmEdicao == 0) {
      limparTela();
    } else if (props.idRegistroEmEdicao > 0) {
      await carregarRegistroDoId();
    }
  }

  async function carregarRegistroDoId() {
    try {
      setCarregando(true);
      const resposta =
        await service.ObterPorIdComDadosAuditoria<VeiculoResponseDTO>(
          props.idRegistroEmEdicao
        );
      checarResponse(resposta);
      dadosAuditoria = resposta.model;
      if (resposta.model.idProprietario) {
        carregarRntrc(resposta.model.idProprietario);
      }
      reset(resposta.model);
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      setCarregando(false);
    }
  }

  function limparTela() {
    dadosAuditoria = undefined;
    reset(novoRegistro);
  }

  async function handleSalvar() {
    setCarregando(true);
    const model = getValues();
    try {
      const resposta =
        props.idRegistroEmEdicao > 0
          ? await service.Atualizar(model)
          : await service.Inserir(model);

      checarResponse(resposta);

      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: resposta.mensagem,
          tipo: TipoNotificacao.Sucesso,
        });
        fechar(ResultadoAcaoFormulario.AcaoConcluida);
      }
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      setCarregando(false);
    }
  }

  function handleCancelar() {
    fechar(ResultadoAcaoFormulario.AcaoCancelada);
  }

  function fechar(resultado: ResultadoAcaoFormulario) {
    limparTela();
    props.handleCallback(resultado);
  }

  async function carregarUfs() {
    try {
      const resposta = await APIUF.obterListagemSimples();
      checarResponse(resposta);
      setUf(
        resposta.model.map((x) => ({
          valor: x.valor,
          descricao: x.descricao,
        }))
      );
    } catch (erro) {
      tratarErroApi(erro);
    }
  }

  function carregarProprietarios(
    filtrosAdicionais: SelectBoxLazyFiltros[] = []
  ) {
    const filtros: SelectBoxLazyFiltros[] = [
      { nomeCampo: "Transportadora", operador: "=", valor: true },
    ];

    for (const filtro of filtrosAdicionais) {
      filtros.push(filtro);
    }

    setProprietario(
      APIBase.getDataSourceSelectBoxLazy(
        {
          camposRetorno: [
            "Id",
            "Apelido",
            "RntrcProprietario",
            "CnpjCpfCodigo",
            "EstadoCadastro",
          ],
          filtros: filtros,
          camposOrdenacao: [
            {
              nomeCampo: "Apelido",
              desc: false,
            },
          ],
        },
        nomeEndpointEmpresa
      )
    );
  }

  async function carregarRntrc(idProprietario: number) {
    try {
      const resposta = await APIEmpresa.obterRntrc(idProprietario);
      checarResponse(resposta);
      setRntrcProprietario(resposta.model != null ? resposta.model : "");
    } catch (erro) {
      tratarErroApi(erro);
    }
  }

  const onProprietarioChanged = (e: any) => {
    setRntrcProprietario(
      e?.selectedItem?.RntrcProprietario != null
        ? e.selectedItem.RntrcProprietario
        : ""
    );

    setEstadoCadastroProprietario(
      e?.selectedItem?.EstadoCadastro != null
        ? e.selectedItem.EstadoCadastro
        : ""
    );
  };

  function atualizarProprietarios(e: number) {
    const unidade = e;

    if (unidade !== TipoUnidadeTransporte.RodoviarioTracao) {
      carregarProprietarios();
      return;
    }
    carregarProprietarios([
      { nomeCampo: "Id", operador: "<>", valor: idEmpresa?.toString() ?? "0" },
    ]);
  }

  return (
    <>
      <ContainerFormMxp>
        <FormMxp carregando={carregando}>
          <ProvedorAjuda id="edit-form-codigo-tributacao-servico">
            <input type="hidden" {...register("id")} defaultValue={0} />
            <FormGrupo>
              <Linha>
                <Coluna md={8}>
                  <FormTextBox
                    name="descricao"
                    titulo={StringsComum.descricao}
                    control={control}
                    requerido
                    tamanhoMaximo={VeiculoConstantes.DescricaoTamanhoMaximo}
                  />
                </Coluna>
                <Coluna md={4}>
                  <FormTextBox
                    name="placa"
                    titulo={StringsVeiculo.placa}
                    control={control}
                    requerido
                    tamanhoMaximo={VeiculoConstantes.PlacaTamanho}
                    mask={VeiculoConstantes.PlacaMascara}
                    transform="uppercase"
                  />
                </Coluna>
              </Linha>
              <Linha>
                <Coluna md={6}>
                  <FormSelectBox
                    name="idUF"
                    titulo={StringsVeiculo.ufLicenciamento}
                    control={control}
                    requerido
                    dataSource={ufs}
                    habilitaBusca
                    tipoBusca="contains"
                  />
                </Coluna>
                <Coluna md={6}>
                  <FormTextBox
                    name="renavam"
                    titulo={StringsVeiculo.renavam}
                    control={control}
                    tamanhoMaximo={VeiculoConstantes.RenavamTamanhoMaximo}
                  />
                </Coluna>
              </Linha>
              <Linha>
                <Coluna md={4}>
                  <FormNumberBox
                    name="tara"
                    titulo={StringsVeiculo.tara}
                    toolTip={StringsVeiculo.tara}
                    control={control}
                    requerido
                    minimo={VeiculoConstantes.TaraTamanhos.Minimo}
                    maximo={VeiculoConstantes.TaraTamanhos.Maximo}
                    formato={obterFormatStringNumero(0)}
                  />
                </Coluna>
                <Coluna md={4}>
                  <FormNumberBox
                    name="capacidadeKG"
                    titulo={StringsVeiculo.capacidadeKG}
                    control={control}
                    requerido={
                      watch("unidadeTransporte") ==
                      TipoUnidadeTransporte.RodoviarioTracao
                    }
                    minimo={VeiculoConstantes.CapacidadeKgTamanhos.Minimo}
                    maximo={VeiculoConstantes.CapacidadeKgTamanhos.Maximo}
                    formato={obterFormatStringNumero(0)}
                    quantidadeIncrementarDecrementar={
                      VeiculoConstantes.TamanhoIncremento
                    }
                  />
                </Coluna>
                <Coluna md={4}>
                  <FormNumberBox
                    name="capacidadeM3"
                    titulo={StringsVeiculo.capacidadeM3}
                    control={control}
                    minimo={VeiculoConstantes.CapacidadeM3Tamanhos.Minimo}
                    maximo={VeiculoConstantes.CapacidadeM3Tamanhos.Maximo}
                    formato={obterFormatStringNumero(0)}
                    quantidadeIncrementarDecrementar={
                      VeiculoConstantes.TamanhoIncremento
                    }
                  />
                </Coluna>
              </Linha>
              <Linha>
                <Coluna md={6}>
                  <FormSelectBox
                    name="tipoRodado"
                    titulo={StringsVeiculo.tipoRodado}
                    control={control}
                    requerido
                    dataSource={SelectItemEnumTipoRodado}
                    habilitaBusca
                    tipoBusca="contains"
                  />
                </Coluna>
                <Coluna md={6}>
                  <FormSelectBox
                    name="tipoCarroceria"
                    titulo={StringsVeiculo.tipoCarroceria}
                    control={control}
                    requerido
                    dataSource={SelectItemEnumTipoCarroceria}
                    habilitaBusca
                    tipoBusca="contains"
                  />
                </Coluna>
              </Linha>
              <Linha>
                <Coluna md={6}>
                  <FormSelectBox
                    name="unidadeTransporte"
                    titulo={StringsVeiculo.unidadeTransporte}
                    control={control}
                    requerido
                    dataSource={SelectItemEnumTipoUnidadeTransporte}
                    habilitaBusca
                    tipoBusca="contains"
                    onValueChange={atualizarProprietarios}
                  />
                </Coluna>
                <Coluna md={6}>
                  <FormSelectBox
                    name="tipoProprietario"
                    titulo={StringsVeiculo.tipoProprietario}
                    control={control}
                    dataSource={SelectItemEnumTipoProprietario}
                    habilitaBusca
                    requerido={watch("idProprietario") != null}
                    tipoBusca="contains"
                  />
                </Coluna>
              </Linha>
              <Linha>
                <Coluna md={7}>
                  <FormSelectBoxLazy
                    name="idProprietario"
                    titulo={StringsVeiculo.proprietario}
                    nomeCampoChave="Id"
                    nomeCampoExibicao={exibeProprietario}
                    expressaoDeBusca={buscaProprietario}
                    control={control}
                    requerido={watch("tipoProprietario") != null}
                    dataSource={proprietarios}
                    labelSemDados="Sem dados"
                    onSelectionChanged={onProprietarioChanged}
                  />
                </Coluna>
                <Coluna md={5}>
                  <FormTextBoxSimples
                    valor={rntrcProprietario}
                    titulo={StringsVeiculo.rntcProprietario}
                    somenteLeitura={true}
                  />
                </Coluna>
              </Linha>
            </FormGrupo>
          </ProvedorAjuda>
        </FormMxp>
        <ToolbarMxp dadosAuditoria={dadosAuditoria}>
          <BotaoSalvarMxp handleClick={handleSubmit(handleSalvar)} />
          <BotaoCancelarMxp handleClick={handleCancelar} />
        </ToolbarMxp>
      </ContainerFormMxp>
    </>
  );
}
