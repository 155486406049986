import { yupResolver } from "@hookform/resolvers/yup";
import { Button, CheckBox } from "devextreme-react";
import {
  memo,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import * as yup from "yup";
import ProvedorAjuda from "../../../../../components/ajuda/provedor-ajuda";
import BotaoCancelarMxp from "../../../../../components/botoes/botao-cancelar-mxp";
import BotaoImprimirMxp from "../../../../../components/botoes/botao-imprimir-mxp";
import BotaoSalvarMxp from "../../../../../components/botoes/botao-salvar-mxp";
import {
  FormCheckBox,
  FormDateBox,
  FormNumberBox,
  FormSelectBox,
  FormSelectBoxLazyMxp,
} from "../../../../../components/formularios";
import { ItemSelectionChangedType } from "../../../../../components/formularios/selectbox-lazy-mxp";
import FormMxp from "../../../../../components/layout/form";
import { ContainerFormMxp } from "../../../../../components/layout/form/styles";
import { Coluna, Linha } from "../../../../../components/layout/grid-system";
import { ModalMxp } from "../../../../../components/layout/modal-mxp";
import ToolbarMxp from "../../../../../components/layout/toolbar-mxp";
import ContextoMenuEdicaoOrdemDeProducao from "../../../../../components/ordem-de-producao/contexto-menu-op";
import {
  HintsMenuEdicaoOrdemDeProducao,
  NomesMenuEdicaoOrdemDeProducao,
} from "../../../../../components/ordem-de-producao/menu-edicao-op";
import AuditavelDTO from "../../../../../models/api/comum/auditavel-dto";
import { ResponseBase } from "../../../../../models/api/comum/response-base";
import { PermissoesOrdemDeProducao } from "../../../../../models/permissoes/producao/ordem-de-producao/OrdemDeProducaoPermissoes";
import {
  IFormularioEditavelComImpressao,
  IFormularioEditavelComRegistro,
  ResultadoAcaoFormulario,
} from "../../../../../models/shared/ui/formularios";
import SelectItem from "../../../../../models/shared/ui/select-item";
import ShowHide, {
  IShowHideRef,
  ShowHideItem,
} from "../../../../../parts/layout/showhide/showhide";
import {
  checarResponse,
  checarResponseExibeToastSucesso,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import {
  quantidadeValorMaximo,
  quantidadeValorMinimo,
} from "../../../../../utils/common/constantes";
import NomesModais from "../../../../../utils/common/nomes-modais";
import exibirNotificacaoToast, {
  GeradorMensagensNotificacao,
  TipoNotificacao,
} from "../../../../../utils/common/notificacoes-utils";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../utils/common/permissoes-utils";
import { ItemContextMenuMxp } from "../../../../../utils/context-menu/context-menu-utils";
import { GestorEventoClickSimples } from "../../../../../utils/context-menu/gestor-evento-click";
import FiltrosGridItem from "../../../../../utils/filtros-grid/itens/item/filtros-grid-item";
import {
  formatarNumeroQuantidade,
  obterFormatStringNumero,
} from "../../../../../utils/formatadores/formatador-de-numeros";
import { DataSourceOpcoesBuilder } from "../../../../../utils/grid/data-source-factory";
import { FormCarregarRegistro } from "../../../../comum/util/form/form-carregar-registro";
import ComboItemMxp from "../../../../itens/item/componentes/select-box-lazy";
import { ItemGridModel } from "../../../../itens/item/models/item.api";
import { EstocagemTipo } from "../../../../itens/item/models/item.enums";
import GridEstadoConfiguravelDaOrdemDeProducao from "../../../estado-configuravel-da-ordem-de-producao/componentes/grid";
import EstadoConfiguravelDaOrdemDeProducaoService from "../../../estado-configuravel-da-ordem-de-producao/services/estado-configuravel-da-ordem-de-producao.service";
import FiltrosGridEstadoConfiguravelDaOrdemDeProducao from "../../../estado-configuravel-da-ordem-de-producao/utils/filtros-grid/filtros";
import {
  OrdemDeProducaoRequest,
  OrdemDeProducaoResponse,
  quantidadeDePerdaPrevistaMaxima,
  quantidadeDePerdaPrevistaMinima,
} from "../../models/ordem-de-producao.api";
import { OrdemDeProducaoService } from "../../servicos/ordem-de-producao.service";
import {
  EstocagemDoProduto,
  EstocagemDoProdutoHelper,
  OrdemDeProducaoEstado,
  OrdemDeProducaoEstadoHelper,
} from "../../utils/enums/ordem-de-producao-enums";
import { abrirTelaDeApontamentoDaDuracaoDaSessaoNoMxp1 } from "../../utils/handlers/apontar-duracao-da-sessao";
import { confirmarRoteiro } from "../../utils/handlers/confirmar-roteiro";
import {
  verificacoesParaRetiradaDeApara,
  verificacoesParaRetiradaDeMaterial,
} from "../../utils/handlers/retirada-de-material-ou-apara";
import OrdemDeProducaoAbaAnexos from "../abas-formulario/abas/anexos";
import OrdemDeProducaoAbaConclusoes from "../abas-formulario/abas/conclusoes-ordem-de-producao";
import OrdemDeProducaoAbaInsumos from "../abas-formulario/abas/insumos";
import OrdemDeProducaoAbaOutrosDados from "../abas-formulario/abas/outros-dados";
import OrdemDeProducaoAbaProjecoesDeUso from "../abas-formulario/abas/projecoes-de-uso";
import OrdemDeProducaoAbaReservas from "../abas-formulario/abas/reservas";
import OrdemDeProducaoAbaRetiradaDeApara from "../abas-formulario/abas/retirada-de-apara";
import OrdemDeProducaoAbaRetiradaDeMaterial from "../abas-formulario/abas/retirada-de-material";
import OrdemDeProducaoAbaTarefasEAnotacoes from "../abas-formulario/abas/tarefas-e-anotacoes";
import FormConcluirOrdemDeProducao from "./concluir-ordem-de-producao";
import FormRetirarApara from "./retiradas/retirar-apara";
import FormRetirarMaterial from "./retiradas/retirar-material";
import { EstadoConfiguravelContainer, EstadoContainer } from "./style";

const novoRegistro: OrdemDeProducaoRequest = {
  id: 0,
  numero: NaN,
  itemId: null,
  quantidade: NaN,
  fixarQuantidade: true,
  quantidadeDePerdaPrevista: 0,
  necessidadeData: new Date(),
  fixarNecessidadeData: true,
  estado: OrdemDeProducaoEstado.AProduzir,
  estadoConfiguravelId: null,
  observacoes: "",
  arquivos: [],
};

const filtrosPadroesParaItem: DataSourceOpcoesBuilder<ItemGridModel> = {
  camposRetorno: [
    "id",
    "descricao",
    "codigo",
    "estocagemPor",
    "perdaPrevista",
    "usaQuantidadeInteira",
  ],
  camposFiltro:
    FiltrosGridItem.itemGridSemProcedenciaAgregadaOuManutencaoOuAlternativo,
};

let dadosAuditoria: AuditavelDTO | undefined = undefined;

const ordemDeProducaoService = new OrdemDeProducaoService();
const estadoConfiguravelDaOrdemDeProducaoService =
  new EstadoConfiguravelDaOrdemDeProducaoService();

const [estadosConfiguraveis, estadosConfiguraveisExibicaoEBusca] =
  estadoConfiguravelDaOrdemDeProducaoService.GetDadosSelectBox();

const estadoOrdemDeProducaoAsSelectItem: SelectItem[] =
  OrdemDeProducaoEstadoHelper.AsSelectItems();

function gerarSchemaYup(itemUsaQuantidadeInteira: boolean) {
  return yup.object().shape({
    id: yup.number().required().moreThan(-1).integer(),
    itemId: yup.number().required().moreThan(-1).integer(),
    necessidadeData: yup.date().required(),
    quantidade: yup
      .number()
      .required()
      .moreThan(
        quantidadeValorMinimo.toNumber(),
        `Quantidade deve ser maior que ${formatarNumeroQuantidade(
          quantidadeValorMinimo.toNumber()
        )}.`
      )
      .lessThan(
        quantidadeValorMaximo.toNumber(),
        `Quantidade deve ser menor que ${formatarNumeroQuantidade(
          quantidadeValorMaximo.toNumber()
        )}.`
      )
      .test(
        "is-integer",
        "Valor deve ser um número inteiro.",
        function (value) {
          return itemUsaQuantidadeInteira ? Number.isInteger(value) : true;
        }
      ),
    quantidadeDePerdaPrevista: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .notRequired()
      .min(quantidadeDePerdaPrevistaMinima.toNumber())
      .lessThan(quantidadeDePerdaPrevistaMaxima.toNumber())
      .test(
        "is-integer",
        "Valor deve ser um número inteiro.",
        function (value) {
          if (Number.isNaN(value) || value == undefined) {
            return true;
          }

          return itemUsaQuantidadeInteira ? Number.isInteger(value) : true;
        }
      ),
    fixarQuantidade: yup.bool().required(),
    fixarNecessidadeData: yup.bool().required(),
    estadoConfiguravelId: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .notRequired()
      .moreThan(-1)
      .integer(),
  });
}

const mensagemCriarOrdemDeProducao = "é necessário salvar a ordem de produção.";

interface FormOrdemDeProducaoInternoProps
  extends IFormularioEditavelComImpressao,
    IFormularioEditavelComRegistro<OrdemDeProducaoResponse> {}

export default function FormOrdemDeProducao(
  props: IFormularioEditavelComImpressao
) {
  const carregarOp = useCallback(async () => {
    const [resposta, anexos] = await Promise.all([
      ordemDeProducaoService.ObterPorIdComDadosAuditoria<OrdemDeProducaoResponse>(
        props.idRegistroEmEdicao
      ),
      ordemDeProducaoService.obterAnexos(props.idRegistroEmEdicao),
    ]);

    checarResponse(resposta);
    resposta.model.arquivos = anexos;

    return resposta.model;
  }, [props.idRegistroEmEdicao]);

  return (
    <FormCarregarRegistro<OrdemDeProducaoResponse>
      idRegistroEmEdicao={props.idRegistroEmEdicao}
      getRegistroEmEdicao={carregarOp}
    >
      <FormOrdemDeProducaoInterno
        handleCallback={props.handleCallback}
        idRegistroEmEdicao={props.idRegistroEmEdicao}
        handleImpressaoCallback={props.handleImpressaoCallback}
      />
    </FormCarregarRegistro>
  );
}

function FormOrdemDeProducaoInterno({
  idRegistroEmEdicao,
  handleCallback,
  atualizarRegistro,
  registroEmEdicao,
  handleImpressaoCallback,
}: FormOrdemDeProducaoInternoProps) {
  const [carregando, setCarregando] = useState(false);
  const [idConcluirOp, setIdConcluirOp] = useState(NaN);
  const [
    ordemDeProducaoIdParaRetiradaDeMaterial,
    setOrdemDeProducaoIdParaRetiradaDeMaterial,
  ] = useState(NaN);

  const [
    ordemDeProducaoIdParaRetiradaDeApara,
    setOrdemDeProducaoIdParaRetiradaDeApara,
  ] = useState(NaN);
  const [itemUsaQuantidadeInteira, setitemUsaQuantidadeInteira] =
    useState(false);
  const [usaAba, setUsaAba] = useState(false);

  const tabPanelRef = useRef<IShowHideRef>(null);

  const schema = useMemo(
    () => gerarSchemaYup(itemUsaQuantidadeInteira),
    [itemUsaQuantidadeInteira]
  );

  const isRegistroEmEdicao = idRegistroEmEdicao > 0;

  const hookForms = useForm<OrdemDeProducaoRequest>({
    resolver: yupResolver(schema),
  });

  const { handleSubmit, getValues, reset, register, setValue, watch } =
    hookForms;

  const limparTela = useCallback(() => {
    dadosAuditoria = undefined;
    reset(novoRegistro);
  }, [reset]);

  const { addItensMenu } = useContext(ContextoMenuEdicaoOrdemDeProducao);

  const handleAtualizarRoteiro = useCallback(
    async function () {
      if (await confirmarRoteiro()) {
        try {
          setCarregando(true);

          const resposta =
            await ordemDeProducaoService.AtualizarRoteiroDaOrdemDeProducao(
              idRegistroEmEdicao
            );

          if (resposta) {
            checarResponseExibeToastSucesso(
              resposta,
              "Roteiro atualizado com sucesso."
            );
            if (atualizarRegistro) {
              atualizarRegistro();
            }
          }
        } catch (erro) {
          tratarErroApi(erro);
        } finally {
          setCarregando(false);
        }
      }
    },
    [atualizarRegistro, idRegistroEmEdicao]
  );

  const handleApontarDuracaoDaSessao = useCallback(
    async function () {
      abrirTelaDeApontamentoDaDuracaoDaSessaoNoMxp1(idRegistroEmEdicao);
    },
    [idRegistroEmEdicao]
  );

  const handleRetirarMaterialClick = useCallback(async () => {
    if (await verificacoesParaRetiradaDeMaterial(registroEmEdicao?.estado)) {
      setOrdemDeProducaoIdParaRetiradaDeMaterial(idRegistroEmEdicao);
    }
  }, [idRegistroEmEdicao, registroEmEdicao?.estado]);

  const handleRetirarAparaClick = useCallback(async () => {
    if (
      await verificacoesParaRetiradaDeApara(
        registroEmEdicao?.estado,
        idRegistroEmEdicao
      )
    ) {
      setOrdemDeProducaoIdParaRetiradaDeApara(idRegistroEmEdicao);
    }
  }, [idRegistroEmEdicao, registroEmEdicao?.estado]);

  const menusEditFormOp: ItemContextMenuMxp[] = useMemo(
    () => [
      {
        icon: "ic-material-symbols-outlined ic-vertical",
        hint: "Ações",
        text: NomesMenuEdicaoOrdemDeProducao.acoes.name,
        name: NomesMenuEdicaoOrdemDeProducao.acoes.name,
        items: [
          {
            icon: "ic-material-symbols-outlined ic-timer",
            hint: NomesMenuEdicaoOrdemDeProducao.acoes.apontarDuracaoDaSessao,
            text: NomesMenuEdicaoOrdemDeProducao.acoes.apontarDuracaoDaSessao,
            name: NomesMenuEdicaoOrdemDeProducao.acoes.apontarDuracaoDaSessao,
            gestorEventoClick: new GestorEventoClickSimples(
              handleApontarDuracaoDaSessao
            ),
          },
          {
            icon: "ic-material-symbols-outlined ic-recycling",
            hint: HintsMenuEdicaoOrdemDeProducao.acoes
              .retirarAparaDaOrdemDeProducao,
            text: NomesMenuEdicaoOrdemDeProducao.acoes
              .retirarAparaDaOrdemDeProducao,
            name: NomesMenuEdicaoOrdemDeProducao.acoes
              .retirarAparaDaOrdemDeProducao,
            gestorEventoClick: new GestorEventoClickSimples(
              handleRetirarAparaClick
            ),
          },
          {
            icon: "ic-material-symbols-outlined ic-inventory",
            hint: HintsMenuEdicaoOrdemDeProducao.acoes
              .retirarMaterialDaOrdemDeProducao,
            text: NomesMenuEdicaoOrdemDeProducao.acoes
              .retirarMaterialDaOrdemDeProducao,
            name: NomesMenuEdicaoOrdemDeProducao.acoes
              .retirarMaterialDaOrdemDeProducao,
            gestorEventoClick: new GestorEventoClickSimples(
              handleRetirarMaterialClick
            ),
          },
          {
            icon: "ic-material-symbols-outlined ic-sync",
            hint: NomesMenuEdicaoOrdemDeProducao.acoes.atualizarRoteiro,
            text: NomesMenuEdicaoOrdemDeProducao.acoes.atualizarRoteiro,
            name: NomesMenuEdicaoOrdemDeProducao.acoes.atualizarRoteiro,
            gestorEventoClick: new GestorEventoClickSimples(
              handleAtualizarRoteiro
            ),
          },
        ],
      },
    ],
    [
      handleApontarDuracaoDaSessao,
      handleAtualizarRoteiro,
      handleRetirarAparaClick,
      handleRetirarMaterialClick,
    ]
  );

  //Hook usado para carregar os dados da tela
  useEffect(() => {
    if (Number.isNaN(idRegistroEmEdicao) || idRegistroEmEdicao <= 0) {
      limparTela();
    } else {
      reset(registroEmEdicao);
      dadosAuditoria = registroEmEdicao;
    }

    addItensMenu(menusEditFormOp);
  }, [
    idRegistroEmEdicao,
    registroEmEdicao,
    addItensMenu,
    menusEditFormOp,
    limparTela,
    reset,
  ]);

  async function handleSalvar() {
    setCarregando(true);
    const model = getValues();

    try {
      let resposta: ResponseBase;

      if (isRegistroEmEdicao) {
        const [respostaApi, respostaAnexo] = await Promise.all([
          ordemDeProducaoService.Atualizar(model),
          ordemDeProducaoService.anexoEditarLista(
            model.id,
            model.arquivos ?? []
          ),
        ]);

        resposta = respostaApi;

        checarResponse(respostaAnexo);
      } else {
        const respostaInicial = await ordemDeProducaoService.InserirComRetorno<
          OrdemDeProducaoRequest,
          OrdemDeProducaoResponse
        >(model as OrdemDeProducaoRequest);

        if (respostaInicial.sucesso) {
          const id = respostaInicial.model.id;
          const respostaAnexo = await ordemDeProducaoService.anexoEditarLista(
            id,
            model.arquivos ?? []
          );
          checarResponse(respostaAnexo);
        }

        resposta = {
          sucesso: respostaInicial.sucesso,
          mensagem: respostaInicial.mensagem,
          erros: respostaInicial.erros,
        };
      }

      checarResponse(resposta);

      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem:
            GeradorMensagensNotificacao.SalvoComSucessoFeminino(
              "Ordem de produção"
            ),
          tipo: TipoNotificacao.Sucesso,
        });
      }
      fechar(ResultadoAcaoFormulario.AcaoConcluida);
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    } finally {
      setCarregando(false);
    }
  }

  function callBackUnprocessableEntity() {
    fechar(ResultadoAcaoFormulario.AcaoConcluida);
  }

  function handleCancelar() {
    fechar(ResultadoAcaoFormulario.AcaoCancelada);
  }

  async function handleSubmitError(errors: object) {
    tabPanelRef.current?.handleSubmitErrors(errors);
  }

  async function fechar(resultado: ResultadoAcaoFormulario) {
    handleCallback(resultado);
  }

  const onItemChanged = useCallback(
    async (e: ItemSelectionChangedType<ItemGridModel>) => {
      //Se for formulário de edição, nao precisa buscar a quantidade de perda prevista
      //Pois ele já foi preenchido e não é possível alterar um item na edição de uma OP.
      if (isRegistroEmEdicao) {
        return;
      }

      setValue(
        "estocagemPor",
        EstocagemDoProdutoHelper.ConverterEstocagemDeItemParaProduto(
          e.selectedItem?.estocagemPor
        )
      );

      if (e.selectedItem?.estocagemPor == EstocagemTipo.Serie) {
        setValue("fixarQuantidade", true);
      }

      setitemUsaQuantidadeInteira(
        e.selectedItem?.usaQuantidadeInteira ?? false
      );
      setValue(
        "quantidadeDePerdaPrevista",
        e.selectedItem?.perdaPrevista ?? NaN
      );
    },
    [isRegistroEmEdicao, setValue]
  );

  const fecharConcluirOp = useCallback(
    async (resultado?: ResultadoAcaoFormulario) => {
      setIdConcluirOp(NaN);
      if (
        resultado == ResultadoAcaoFormulario.AcaoConcluida &&
        atualizarRegistro
      ) {
        atualizarRegistro();
      }
    },
    [atualizarRegistro]
  );

  const fecharModalRetiradaDeMaterial = useCallback(async () => {
    setOrdemDeProducaoIdParaRetiradaDeMaterial(NaN);
  }, []);

  const fecharModalRetiradaDeApara = useCallback(async () => {
    setOrdemDeProducaoIdParaRetiradaDeApara(NaN);
  }, []);

  const mostrarConcluirOp = useCallback(() => {
    setIdConcluirOp(idRegistroEmEdicao);
  }, [idRegistroEmEdicao]);

  const onClickImprimir = useCallback(() => {
    handleImpressaoCallback(idRegistroEmEdicao);
  }, [idRegistroEmEdicao]);

  const confirmarOp = useCallback(
    async (fecharModal: boolean) => {
      if (
        verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesOrdemDeProducao.InserirEditar,
        ])
      ) {
        try {
          const model = getValues();
          setCarregando(true);

          const resposta =
            await ordemDeProducaoService.ConfirmarOrdemDeProducao(model.id);

          checarResponse(resposta);

          if (resposta.sucesso) {
            //Atualiza o form com o novo estado
            if (atualizarRegistro) {
              atualizarRegistro();
            }

            if (fecharModal) {
              fechar(ResultadoAcaoFormulario.AcaoConcluida);
            }

            exibirNotificacaoToast({
              mensagem: "Ordem de produção confirmada com sucesso.",
              tipo: TipoNotificacao.Sucesso,
            });
          }
        } catch (erro) {
          tratarErroApi(erro);
        } finally {
          setCarregando(false);
        }
      }
    },
    [registroEmEdicao, fechar, getValues]
  );

  return (
    <>
      <ContainerFormMxp>
        <FormProvider {...hookForms}>
          <FormMxp carregando={carregando}>
            <ProvedorAjuda id={"edit-form-ordem-de-producao"}>
              <input type="hidden" {...register("id")} defaultValue={0} />
              <AbasOp
                usaAba={usaAba}
                tabPanelRef={tabPanelRef}
                idRegistroEmEdicao={idRegistroEmEdicao}
                ordemDeProducao={registroEmEdicao}
                onItemChanged={onItemChanged}
              />
            </ProvedorAjuda>
          </FormMxp>
        </FormProvider>
        <ToolbarMxp dadosAuditoria={dadosAuditoria}>
          <BotaoSalvarMxp
            handleClick={handleSubmit(handleSalvar, handleSubmitError)}
          />

          <Button
            key="btn-confirmar-op"
            type="success"
            text="Confirmar"
            icon="check"
            visible={
              isRegistroEmEdicao &&
              (watch("estado") == OrdemDeProducaoEstado.Sugerida ||
                watch("estado") == OrdemDeProducaoEstado.Suspensa)
            }
            onClick={() => confirmarOp(false)}
          />
          <Button
            key="btn-confirmar-fechar-op"
            type="success"
            text="Confirmar e fechar"
            icon="check"
            visible={
              isRegistroEmEdicao &&
              (watch("estado") == OrdemDeProducaoEstado.Sugerida ||
                watch("estado") == OrdemDeProducaoEstado.Suspensa)
            }
            onClick={() => confirmarOp(true)}
          />

          <BotaoCancelarMxp handleClick={handleCancelar} />

          <BotaoImprimirMxp handleClick={onClickImprimir} />

          {watch("estado") != OrdemDeProducaoEstado.Sugerida &&
            watch("estado") != OrdemDeProducaoEstado.Suspensa && (
              <Button
                key="btn-concluir-op"
                type="normal"
                text="Concluir"
                visible={
                  isRegistroEmEdicao &&
                  watch("estado") == OrdemDeProducaoEstado.AProduzir
                }
                onClick={mostrarConcluirOp}
              />
            )}

          <CheckBox
            value={usaAba}
            text="Usar aba"
            onValueChanged={(e) => setUsaAba(e.value)}
          />
        </ToolbarMxp>
      </ContainerFormMxp>
      <ModalMxp
        titulo={`Concluir ${NomesModais.ordemDeProducao}`}
        visivel={!Number.isNaN(idConcluirOp)}
        handleFechar={fecharConcluirOp}
        largura={"max(50vw, 800px)"}
        altura={"auto"}
      >
        <FormConcluirOrdemDeProducao
          idRegistroEmEdicao={idConcluirOp}
          handleCallback={fecharConcluirOp}
        />
      </ModalMxp>
      <ModalMxp
        titulo={`Nova ${NomesModais.retiradaDeMaterial}`}
        visivel={!Number.isNaN(ordemDeProducaoIdParaRetiradaDeMaterial)}
        handleFechar={fecharModalRetiradaDeMaterial}
        largura={"max(65vw, 900px)"}
        altura={"auto"}
        alturaMaxima={"95vh"}
      >
        <FormRetirarMaterial
          ordemDeProducaoId={ordemDeProducaoIdParaRetiradaDeMaterial}
          idRegistroEmEdicao={0}
          handleCallback={fecharModalRetiradaDeMaterial}
        />
      </ModalMxp>

      <ModalMxp
        titulo={`Nova ${NomesModais.retiradaDeApara}`}
        visivel={!Number.isNaN(ordemDeProducaoIdParaRetiradaDeApara)}
        handleFechar={fecharModalRetiradaDeApara}
        largura={"max(65vw, 900px)"}
        altura={"auto"}
        alturaMaxima={"95vh"}
      >
        <FormRetirarApara
          ordemDeProducaoId={ordemDeProducaoIdParaRetiradaDeApara}
          idRegistroEmEdicao={0}
          handleCallback={fecharModalRetiradaDeApara}
        />
      </ModalMxp>
    </>
  );
}

interface AbasOpProps {
  usaAba: boolean;
  tabPanelRef: RefObject<IShowHideRef>;
  idRegistroEmEdicao: number;
  ordemDeProducao?: OrdemDeProducaoResponse;
  onItemChanged(e: ItemSelectionChangedType<ItemGridModel>): Promise<void>;
}

// Utilizamos o memo aqui pois para carregar as "abas" da OP, temos o custo
// de fazer as chamadas para o servidor e montar os DataGrids. Para evitar
// isso, fazemos com que o componente seja memoizado pelas props.
// Não precisamos de comparação customizada aqui pois o ordemDeProducao
// vem de um useState, então se ele mudar, tem que mudar por inteiro.
const AbasOp = memo(
  ({
    usaAba,
    tabPanelRef,
    idRegistroEmEdicao,
    ordemDeProducao,
    onItemChanged,
  }: AbasOpProps) => {
    const isRegistroEmEdicao = idRegistroEmEdicao > 0;

    return (
      <ShowHide
        ref={tabPanelRef}
        id="accordion-container"
        usarAba={usaAba}
        height={"75vh"}
        idRegistroEmEdicao={0}
      >
        <ShowHideItem text="Ordem de produção" aberto>
          <OrdemDeProducaoAbaDadosGerais
            isRegistroEmEdicao={isRegistroEmEdicao}
            onItemChanged={onItemChanged}
          />
        </ShowHideItem>
        <ShowHideItem text="Insumos" aberto style={{ maxHeight: 1000 }}>
          {isRegistroEmEdicao ? (
            <OrdemDeProducaoAbaInsumos
              idRegistro={idRegistroEmEdicao}
              ordemDeProducaoEstado={ordemDeProducao?.estado}
            />
          ) : (
            <p>
              <b>Para visualizar os insumos, {mensagemCriarOrdemDeProducao}</b>
            </p>
          )}
        </ShowHideItem>
        <ShowHideItem text="Reservas" carregarApenasQuandoAberto>
          {isRegistroEmEdicao ? (
            <OrdemDeProducaoAbaReservas
              ordemDeProducaoId={ordemDeProducao?.id ?? 0}
              ordemDeProducaoCodigo={ordemDeProducao?.numero}
              ordemDeProducaoEhSugerida={
                ordemDeProducao?.estado == OrdemDeProducaoEstado.Sugerida
              }
              ordemDeProducaoIdItem={ordemDeProducao?.itemId ?? 0}
            />
          ) : (
            <p>
              <b>Para visualizar as reservas, {mensagemCriarOrdemDeProducao}</b>
            </p>
          )}
        </ShowHideItem>
        <ShowHideItem text="Projeções de uso" carregarApenasQuandoAberto>
          {isRegistroEmEdicao ? (
            <OrdemDeProducaoAbaProjecoesDeUso idRegistro={idRegistroEmEdicao} />
          ) : (
            <p>
              <b>
                Para visualizar as projeções de uso,{" "}
                {mensagemCriarOrdemDeProducao}
              </b>
            </p>
          )}
        </ShowHideItem>
        <ShowHideItem text="Aparas" carregarApenasQuandoAberto>
          {isRegistroEmEdicao ? (
            <OrdemDeProducaoAbaRetiradaDeApara
              ordemDeProducaoId={idRegistroEmEdicao}
              ordemDeProducaoEstado={ordemDeProducao?.estado}
              ordemDeProducaoNumero={ordemDeProducao?.numero}
            />
          ) : (
            <p>
              <b>
                Para visualizar as aparas,é necessário salvar a ordem de
                produção.
              </b>
            </p>
          )}
        </ShowHideItem>
        <ShowHideItem text="Retirada de materiais" carregarApenasQuandoAberto>
          {isRegistroEmEdicao ? (
            <OrdemDeProducaoAbaRetiradaDeMaterial
              ordemDeProducaoId={idRegistroEmEdicao}
              ordemDeProducaoEstado={ordemDeProducao?.estado}
            />
          ) : (
            <p>
              <b>
                Para visualizar as retiradas de materiais,{" "}
                {mensagemCriarOrdemDeProducao}
              </b>
            </p>
          )}
        </ShowHideItem>
        <ShowHideItem text="Conclusões" carregarApenasQuandoAberto>
          {isRegistroEmEdicao ? (
            <OrdemDeProducaoAbaConclusoes
              ordemDeProducaoId={idRegistroEmEdicao}
            />
          ) : (
            <p>
              <b>
                Para visualizar as conclusões da ordem de produção,{" "}
                {mensagemCriarOrdemDeProducao}
              </b>
            </p>
          )}
        </ShowHideItem>
        <ShowHideItem text="Outros dados">
          <OrdemDeProducaoAbaOutrosDados />
        </ShowHideItem>
        <ShowHideItem text="Anexos">
          <OrdemDeProducaoAbaAnexos idRegistro={idRegistroEmEdicao} />
        </ShowHideItem>
        <ShowHideItem text="Tarefas e anotações" carregarApenasQuandoAberto>
          {isRegistroEmEdicao ? (
            <OrdemDeProducaoAbaTarefasEAnotacoes
              ordemDeProducaoId={ordemDeProducao?.id ?? 0}
            />
          ) : (
            <p>
              <b>
                Para visualizar as tarefas e anotações,{" "}
                {mensagemCriarOrdemDeProducao}
              </b>
            </p>
          )}
        </ShowHideItem>
      </ShowHide>
    );
  }
);

interface OrdemDeProducaoAbaDadosGeraisProps {
  isRegistroEmEdicao: boolean;
  onItemChanged: (e: ItemSelectionChangedType<ItemGridModel>) => Promise<void>;
}

function OrdemDeProducaoAbaDadosGerais({
  isRegistroEmEdicao,
  onItemChanged,
}: OrdemDeProducaoAbaDadosGeraisProps) {
  const { control, watch } = useFormContext<OrdemDeProducaoRequest>();

  const isFechada = watch("estado") == OrdemDeProducaoEstado.Fechada;
  return (
    <div style={{ padding: "0px" }}>
      <Linha>
        <Coluna md={2}>
          <FormNumberBox
            name={"numero"}
            titulo="Número"
            control={control}
            somenteLeitura
          />
        </Coluna>
        <Coluna md={6}>
          <ComboItemMxp
            name={"itemId"}
            titulo="Item"
            control={control}
            somenteLeitura={isRegistroEmEdicao}
            dataSourceOpcoes={filtrosPadroesParaItem}
            filtrosNoCliente={FiltrosGridItem.itemAtivo}
            onSelectionChanged={onItemChanged}
          />
        </Coluna>
        <Coluna md={2} offset={2}>
          <EstadoContainer>
            <FormSelectBox
              name={"estado"}
              titulo="Estado"
              control={control}
              somenteLeitura
              dataSource={estadoOrdemDeProducaoAsSelectItem}
              habilitaBusca
              tipoBusca="contains"
            />
          </EstadoContainer>
        </Coluna>
      </Linha>
      <Linha>
        <Coluna md={2}>
          <FormNumberBox
            name={"quantidade"}
            titulo="Quantidade"
            formato={obterFormatStringNumero(5)}
            control={control}
            somenteLeitura={isFechada}
            minimo={quantidadeValorMinimo.toNumber()}
            maximo={quantidadeValorMaximo.toNumber()}
            requerido
          />
        </Coluna>
        <Coluna md={1} centralizar>
          <FormCheckBox
            name={"fixarQuantidade"}
            titulo="Fixar"
            desabilitado={
              watch("estocagemPor") == EstocagemDoProduto.Serie || isFechada
            }
            control={control}
          />
        </Coluna>
        <Coluna md={2}>
          <FormDateBox
            name={"necessidadeData"}
            titulo="Necessidade"
            control={control}
            exibirBotaoLimpar
            aceitaValorCustomizado={true}
            aceitaDigitacaoComMascara={true}
            somenteLeitura={isFechada}
            requerido
          />
        </Coluna>
        <Coluna md={1} centralizar>
          <FormCheckBox
            name={"fixarNecessidadeData"}
            titulo="Fixar"
            somenteLeitura={isFechada}
            control={control}
          />
        </Coluna>
        <Coluna md={2} offset={4}>
          <EstadoConfiguravelContainer>
            <FormSelectBoxLazyMxp
              name={"estadoConfiguravelId"}
              titulo="Estado configurável"
              control={control}
              configuracoesExibicaoEBusca={estadosConfiguraveisExibicaoEBusca}
              dataSource={estadosConfiguraveis}
              seletorConfig={{
                modo: "selecaoUnica",
                titulo: "Selecionar estado configurável de ordem de produção",
                componenteGrid: (
                  <GridEstadoConfiguravelDaOrdemDeProducao
                    filtrosNoCliente={
                      FiltrosGridEstadoConfiguravelDaOrdemDeProducao.apenasAtivos
                    }
                  />
                ),
              }}
              labelSemDados="Sem dados"
            />
          </EstadoConfiguravelContainer>
        </Coluna>
      </Linha>
    </div>
  );
}
