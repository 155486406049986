import { InformacaoDaEntidadeOrigemDestinoDaProjecaoDeUso } from "../../models/projecao-de-uso.api";

export enum TipoDeDestino {
  OperacaoDaOrdemDeProducao = 0,
  ItemDoPedidoDeVenda = 1,
  SolicitacaoDeCompra = 2,
  ItemDoPedidoDeCompra = 3,
  InsumoDaOrdemDeProducao = 4,
}

export enum TipoDeOrigem {
  Estoque = 0,
  SolicitacaoDeCompra = 1,
  ItemDoPedidoDeCompra = 2,
  OrdemDeProducao = 3,
  OrdemDeManutencao = 4,
}

export class TipoDeOrigemEDestinoHelper {
  public static getDescricaoOuDataDaEntidadeCorrespondente(
    propriedadeARetornar: "descricao" | "data",
    informacoesEntidade: InformacaoDaEntidadeOrigemDestinoDaProjecaoDeUso | null
  ): any {
    if (!informacoesEntidade) return;

    return informacoesEntidade[propriedadeARetornar] ?? null;
  }
}
