import { DataGridRef } from "devextreme-react/cjs/data-grid";

export interface IFormulario {
  requestSubmit: () => void;
  valido: () => boolean;
  isDirty: () => boolean;
}

export interface IGridSelecao {
  obterSelecao: () => any[];
  limparSelecao: () => void;
  definirModo(modo: "selecaoUnica" | "selecaoMultipla"): void;
  definirCallBackDoubleClick: (callBackDoubleClick: () => void) => void;
  getGridRef: () => DataGridRef<any, any>;
  atualizarGrid?: () => void;
}

export interface IEditForm {
  handleCancelar: () => void;
  setRegistroEmEdicao?: (id: number) => void;
}

export enum ResultadoAcaoFormulario {
  AcaoCancelada = 1,
  AcaoConcluida = 2,
  AcaoComErro = 3,
}

export interface IFormularioBase {
  handleCallback: (resultado: ResultadoAcaoFormulario) => void;
}

export interface IFormularioEditavelBase extends IFormularioBase {
  idRegistroEmEdicao: number;
}

export interface IFormularioEditavelComImpressao
  extends IFormularioEditavelBase {
  handleImpressaoCallback: (id: number) => void;
}

export interface IFormularioEditavelComRegistro<T>
  extends IFormularioEditavelBase {
  registroEmEdicao?: T;
  atualizarRegistro?: () => void;
}
