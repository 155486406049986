import { useEffect } from "react";
import { FieldPath, UseFormReturn } from "react-hook-form";
import * as yup from "yup";
import { FormSelectBox } from "../../../../../components/formularios";
import { Coluna, Linha } from "../../../../../components/layout/grid-system";
import TabContainer from "../../../../../components/layout/tab-container";
import SelectItem from "../../../../../models/shared/ui/select-item";
import { useFormSubContext } from "../../../../../utils/form/subform";
import { SubFormProvider } from "../../../../../utils/form/subformContext";
import { EstocagemTipo } from "../../../../itens/item/models/item.enums";
import {
  DadosRastreabilidadeLoteRequest,
  LoteRequestBase,
  NovoRegistroLoteRequest,
} from "../../models/lote.api";
import LoteAbaDadosGerais from "../abas-formulario/dados-gerais";
import LoteAbaObservacoes from "../abas-formulario/observacoes";
import { yupLote } from "../formulario";
import ComboLotesPorItemMxp from "../select-box-lazy";
import { RastreabilidadePorLoteContainer } from "./styles";

export const novaRastreabilidadeLote: DadosRastreabilidadeLoteRequest = {
  criarNovo: false,
  loteId: null,
  cadastroLote: NovoRegistroLoteRequest,
};

export const yupRastreabilidadeLote = (
  estocagemTipo: () => EstocagemTipo | null | undefined
) =>
  yup
    .object<DadosRastreabilidadeLoteRequest>()
    .when("$estocagemPorLote", (_, schema) => {
      return estocagemTipo() === EstocagemTipo.LoteFabricante
        ? schema.shape({
            criarNovo: yup.boolean().required(),
            loteId: yup.number().when(["criarNovo"], (values, schema) => {
              const [criarNovo] = values as [boolean];

              if (!criarNovo) {
                return schema.required().integer().positive();
              }

              return schema.notRequired();
            }),
            cadastroLote: yup.object().when(["criarNovo"], (values, schema) => {
              const [criarNovo] = values as [boolean];

              if (criarNovo) {
                return yupLote;
              }
              return schema.notRequired();
            }),
          })
        : schema.notRequired();
    });

export interface RastreabilidadePorLoteRequest {
  itemId: number | null;
  rastreabilidadeLote: DadosRastreabilidadeLoteRequest;
}

export interface RastreabilidadePorNumeroDeSerieRequest {
  rastreabilidadeNumeroDeSerieSelecionados?: number[];
  rastreabilidadeNumeroDeSerie?: DadosRastreabilidadePorNumeroDeSerieRequest[];
}

export interface DadosRastreabilidadePorNumeroDeSerieRequest {
  id: number;
  numeroDeSerie: string;
}

interface RastreabilidadePorLoteProps<T extends RastreabilidadePorLoteRequest> {
  hookForms: UseFormReturn<T>;
}

const datasourceAcao: SelectItem[] = [
  {
    valor: true,
    descricao: "Criar novo lote",
  },
  {
    valor: false,
    descricao: "Selecionar lote existente",
  },
];

const obterNomeDaPropriedadeLote = (
  propriedade: FieldPath<LoteRequestBase>
) => {
  return ("rastreabilidadeLote.cadastroLote." +
    (propriedade as string)) as FieldPath<RastreabilidadePorLoteRequest>;
};

export default function RastreabilidadePorLote(
  props: RastreabilidadePorLoteProps<RastreabilidadePorLoteRequest>
) {
  const { control, watch, setValue, getValues } = props.hookForms;

  useEffect(() => {
    setValue("rastreabilidadeLote.cadastroLote.itemId", getValues("itemId"));
  }, [watch("itemId")]);

  const subform = useFormSubContext<RastreabilidadePorLoteRequest>(
    "rastreabilidadeLote.cadastroLote",
    props.hookForms as any
  );

  return (
    <RastreabilidadePorLoteContainer>
      <TabContainer>
        <Linha>
          <Coluna md={3}>
            <FormSelectBox
              name="rastreabilidadeLote.criarNovo"
              titulo="Ação"
              control={control}
              dataSource={datasourceAcao}
            />
          </Coluna>
          {!watch("rastreabilidadeLote.criarNovo") && (
            <Coluna md={3}>
              <ComboLotesPorItemMxp
                name="rastreabilidadeLote.loteId"
                titulo="Lote"
                control={control}
                idItem={watch("itemId")}
              />
            </Coluna>
          )}
        </Linha>
        {watch("rastreabilidadeLote.criarNovo") && (
          <SubFormProvider<LoteRequestBase, DadosRastreabilidadeLoteRequest>
            hookForms={subform as any}
            isSubform={true}
            obterNomeDaPropriedade={obterNomeDaPropriedadeLote as any}
          >
            <LoteAbaDadosGerais isRegistroEmEdicao={false} />
            <LoteAbaObservacoes />
          </SubFormProvider>
        )}
      </TabContainer>
    </RastreabilidadePorLoteContainer>
  );
}
