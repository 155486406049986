import TabContainer from "../../../../../../components/layout/tab-container";
import { TipoVinculoTarefaOuAnotacaoParaCarregamentoDataSource } from "../../../utils/enums/tarefa-ou-anotacao.api.enums";
import GridEmbutidaTarefasEAnotacoes from "../../grid-embutida";

interface TarefaOuAnotacaoAbaTarefasEAnotacoesProps {
  tarefaGeradoraId: number;
  ordemDeProducaoId?: number;
}

export default function TarefaOuAnotacaoAbaTarefasEAnotacoes(
  props: TarefaOuAnotacaoAbaTarefasEAnotacoesProps
) {
  return (
    <TabContainer>
      <GridEmbutidaTarefasEAnotacoes
        tarefaGeradoraId={props.tarefaGeradoraId}
        ordemDeProducaoId={props.ordemDeProducaoId}
        tipoVinculoTarefaOuAnotacaoParaCarregamentoDataSource={
          TipoVinculoTarefaOuAnotacaoParaCarregamentoDataSource.TarefaOuAnotacaoGeradora
        }
      />
    </TabContainer>
  );
}
