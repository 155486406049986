import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import ProvedorAjuda from "../../../../../../../components/ajuda/provedor-ajuda";
import BotaoCancelarMxp from "../../../../../../../components/botoes/botao-cancelar-mxp";
import BotaoLadoDireitoInput from "../../../../../../../components/botoes/botao-lado-direito-input";
import BotaoSalvarMxp from "../../../../../../../components/botoes/botao-salvar-mxp";
import { FormNumberBox } from "../../../../../../../components/formularios";
import FormMxp from "../../../../../../../components/layout/form";
import { ContainerFormMxp } from "../../../../../../../components/layout/form/styles";
import {
  Coluna,
  Linha,
} from "../../../../../../../components/layout/grid-system";
import ToolbarMxp from "../../../../../../../components/layout/toolbar-mxp";
import AuditavelDTO from "../../../../../../../models/api/comum/auditavel-dto";
import {
  IFormularioEditavelBase,
  ResultadoAcaoFormulario,
} from "../../../../../../../models/shared/ui/formularios";
import {
  checarResponse,
  tratarErroApi,
} from "../../../../../../../utils/api/api-utils";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../../../utils/common/notificacoes-utils";
import { exibirConfirmacao } from "../../../../../../../utils/dialogos";
import { obterFormatStringNumero } from "../../../../../../../utils/formatadores/formatador-de-numeros";
import { LinhaApuracaoDoInformativoDaComposicaoDeCustosDaEcfEditarRequest } from "../../../../models/linha-apuracao-informativo-composicao-custos-ecf";
import LinhaApuracaoDoInformativoDaComposicaoDeCustosDaEcfService from "../../../../servicos/linha-apuracao-informativo-composicao-custos-ecf";

const service =
  new LinhaApuracaoDoInformativoDaComposicaoDeCustosDaEcfService();

const novoRegistro: LinhaApuracaoDoInformativoDaComposicaoDeCustosDaEcfEditarRequest =
  {
    id: 0,
    valor: 0,
  };

let dadosAuditoria: AuditavelDTO | undefined = undefined;

export default function ModalEdicaoValorLinhaApuracaoInformativoComposicaoCustos(
  props: IFormularioEditavelBase
) {
  const [carregando, setCarregando] = useState(false);
  const [valorEstaFixo, setValorEstaFixo] = useState(false);

  const schema = yup.object().shape({
    id: yup.number().required().moreThan(-1).integer(),
    valor: yup.number().required(),
  });

  const { control, reset, register, getValues } =
    useForm<LinhaApuracaoDoInformativoDaComposicaoDeCustosDaEcfEditarRequest>({
      mode: "onChange",
      reValidateMode: "onChange",
      resolver: yupResolver(schema),
    });

  //Hook usado para carregar os dados da tela
  useEffect(() => {
    if (Number.isNaN(props.idRegistroEmEdicao)) {
      return;
    }

    preencherTela();
  }, [props.idRegistroEmEdicao]);

  async function preencherTela() {
    if (props.idRegistroEmEdicao == 0) {
      limparTela();
    } else if (props.idRegistroEmEdicao > 0) {
      await carregarRegistroDoId();
    }
  }

  async function carregarRegistroDoId() {
    try {
      setCarregando(true);
      const resposta = await service.ObterPorIdComDadosAuditoria(
        props.idRegistroEmEdicao
      );
      checarResponse(resposta);
      dadosAuditoria = resposta.model;
      reset(resposta.model);
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      setCarregando(false);
    }
  }

  async function handleSalvar() {
    setCarregando(true);
    const model = getValues();
    try {
      const resposta = await service.Editar(model);

      checarResponse(resposta);

      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: resposta.mensagem,
          tipo: TipoNotificacao.Sucesso,
        });
        fechar(ResultadoAcaoFormulario.AcaoConcluida);
      }
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      setCarregando(false);
    }
  }

  async function handleValorLivre() {
    try {
      const resposta = await exibirConfirmacao(
        "Confirmação",
        "Deseja tornar livre o valor?"
      );

      if (resposta) {
        setCarregando(true);
        const retorno = await service.TornarValorLivre(
          props.idRegistroEmEdicao
        );
        checarResponse(retorno);

        if (retorno.sucesso) {
          setValorEstaFixo(false);
        }
      }
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      setCarregando(false);
    }
  }

  function limparTela() {
    dadosAuditoria = undefined;
    reset(novoRegistro);
  }

  function fechar(resultado: ResultadoAcaoFormulario) {
    limparTela();
    props.handleCallback(resultado);
  }

  function handleCancelar() {
    fechar(ResultadoAcaoFormulario.AcaoCancelada);
  }

  return (
    <>
      <ContainerFormMxp>
        <FormMxp carregando={carregando}>
          <ProvedorAjuda id="edit-form-apuracao-lacs-csll">
            <input type="hidden" {...register("id")} defaultValue={0} />
            <Linha>
              <Coluna md={6}>
                <BotaoLadoDireitoInput
                  textoTooltip="Tornar livre"
                  icone="ic-keep"
                  onClick={handleValorLivre}
                  visivel={valorEstaFixo}
                >
                  <FormNumberBox
                    name="valor"
                    titulo="Valor"
                    control={control}
                    requerido
                    formato={obterFormatStringNumero(2)}
                  />
                </BotaoLadoDireitoInput>
              </Coluna>
            </Linha>
          </ProvedorAjuda>
        </FormMxp>
        <ToolbarMxp dadosAuditoria={dadosAuditoria}>
          <BotaoSalvarMxp handleClick={handleSalvar} />
          <BotaoCancelarMxp handleClick={handleCancelar} />
        </ToolbarMxp>
      </ContainerFormMxp>
    </>
  );
}
