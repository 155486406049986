import AuditavelDTO from "../../../../models/api/comum/auditavel-dto";
import AuditavelGridModel from "../../../../models/api/comum/auditavel-grid";
import { ErroApi } from "../../../../models/api/comum/erros";
import { EstoqueGridModelSelecionar } from "../../../../models/api/estoque/estoque";
import SelectItem from "../../../../models/shared/ui/select-item";
import { EstoqueMovimentacaoSelecionarGridModel } from "../../../estoque/estoque-movimentacao/models/estoque-movimentacao";
import {
  EstadoDaTerceirizacao,
  InsumoEstado,
  InsumoTipoBaixa,
  OrigemInsumo,
  TipoDoEstoqueInsumo,
} from "../utils/enum/insumo-da-ordem-de-producao-enums";

export interface InsumoDaOrdemDeProducaoGridModel extends AuditavelGridModel {
  id: number;
  estado: InsumoEstado;
  estadoDecodificado: string;
  codigo: string;
  descricao: string | null;
  ordemDeProducao: string;
  ordemDeProducaoEstado: string;
  ordemDeProducaoId: number;
  operacao: string;
  descricaoComplementar: string | null;
  quantidadeSemPercentualDePerda: number;
  perdaEmPercentual: number;
  quantidadeTotal: number;
  unidade: string;
  quantidadeParaUmaUnidade: number;
  quantidadeBaixada: number;
  quantidadeABaixar: number;
  loteInterno: string;
  loteDoFabricante: string;
  enderecoDeEstoque: string;
  estoqueDisponivel: string;
  estoqueDisponivelExplosao: string;
  quantidadeReservada: number;
  tipoDeBaixa: InsumoTipoBaixa;
  dono: string;
  tipoDoEstoque: string;
  isProcedenciaAlternativo: boolean;
  quantidadeASelecionarInsumoAlternativo: number | null;
  insumoPaiAlternativo: string | null;
  itemId: number;
}

export const ObterDescricaoInsumo = (
  insumo?: InsumoDaOrdemDeProducaoGridModel
) => {
  if (!insumo) {
    return "(insumo não encontrado)";
  }

  return `${insumo.codigo} (${insumo.descricao}) - ${insumo.operacao}`;
};

export interface InsumoDaOrdemDeProducaoRequest {
  id: number;
  ordemDeProducaoId: number;
  operacaoId: number;
  itemId: number;
  quantidadeLiquida: number;
  observacoes: string | null;
  ativo: boolean;
  origem: OrigemInsumo;
  ordem: number;
  perdaEmPercentual: number;
  estoqueId: number | null;
  numeroDeSerieId: number | null;
  tipoDoEstoque: TipoDoEstoqueInsumo | null;
  estadoDaTerceirizacao: EstadoDaTerceirizacao;
  baixarEstoque: boolean;
}

export const InsumosTipoEstoque: SelectItem[] = [
  { valor: TipoDoEstoqueInsumo.ConsumirEstoque, descricao: "Consumir estoque" },
  {
    valor: TipoDoEstoqueInsumo.ManterInsumo,
    descricao: "Manter insumo consumido em estoque separado",
  },
];

export const InsumosEstadoDaTerceirizacao: SelectItem[] = [
  {
    valor: EstadoDaTerceirizacao.FornecidoPeloCliente,
    descricao: "Fornecido pelo cliente",
  },
  {
    valor: EstadoDaTerceirizacao.SemTerceirizacao,
    descricao: "Sem terceirização",
  },
];

export interface InsumoDaOrdemDeProducaoBaixarResponse {
  quantidadeBaixada: number;
  estoquesParaBaixar: EstoqueGridModelSelecionar[];
}
export interface InsumoDaOrdemDeProducaoResponseDTO extends AuditavelDTO {
  id: number;
  ordemDeProducaoId: number;
  operacaoId: number;
  itemId: number;
  quantidadeLiquida: number;
  observacoes: string | null;
  ativo: boolean;
  origem: OrigemInsumo;
  ordem: number;
  perdaEmPercentual: number;
  estoqueId: number | null;
  numeroDeSerieId: number | null;
  tipoDoEstoque: TipoDoEstoqueInsumo | null;
  estadoDaTerceirizacao: EstadoDaTerceirizacao;
  baixarEstoque: boolean;
}

export interface EstornarBaixasDoInsumoResponse {
  sucesso: boolean;
  mensagem: string;
  erros: ErroApi[];
  precisaSelecionarMovs: boolean;
  movimentacoes: EstoqueMovimentacaoSelecionarGridModel[];
}

export interface InsumosEstornarEmMassaResponse {
  id: number;
  item: string;
  listaDeErros: ErroApi[];
  valido: boolean;
}

export interface DadosModalSelecaoInsumosFilhosDoInsumoAlternativo {
  insumoPaiId: number;
  quantidadeTotalInsumoPai: number;
}

export interface DadosModalEstornoDeInsumos {
  descricaoInsumo: string;
  quantidadeAEstornar: number;
}

export class InsumoAlternativoSelecionado {
  itemId: number;
  quantidade: number;
  proporcaoDoItemAlternativo: number;

  constructor(
    itemId: number,
    quantidade: number,
    proporcaoDoItemAlternativo: number
  ) {
    this.itemId = itemId;
    this.quantidade = quantidade;
    this.proporcaoDoItemAlternativo = proporcaoDoItemAlternativo;
  }
}
