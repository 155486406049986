import DataGrid, {
  Column,
  DataGridRef,
  Summary,
  TotalItem,
} from "devextreme-react/cjs/data-grid";
import ArrayStore from "devextreme/data/array_store";
import { useMemo, useRef } from "react";
import ProvedorAjuda from "../../../../../../../components/ajuda/provedor-ajuda";
import { Modal } from "../../../../../../../components/layout/modal";
import GetColunasDeAuditoria from "../../../../../../../parts/layout/grid-defaults/colunasDeAuditoria";
import criarNameof from "../../../../../../../utils/common/cria-name-of";
import NomesTelas from "../../../../../../../utils/common/nomes-telas";
import GridBuilder from "../../../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../../../utils/grid/grid-controller";
import obterConfiguracaoColuna from "../../../../../../../utils/grid/padroes-colunas";
import { AdicaoOuExclusaoOuCompensacao } from "../../../../../adicao-exclusao-compensacao/models/adicao-exclusao-compensacao-lacs-lalur.enums";
import { AdicaoExclusaoCompensacaoApuracaoLacsCsllGridModel } from "../../../../models/adicao-exclusao-compensacao-apuracao-lacs-csll";
import AdicaoExclusaoCompensacaoApuracaoLacsCsllService from "../../../../servicos/adicao-exclusao-compensacao-apuracao-lacs-csll.service";

const service = new AdicaoExclusaoCompensacaoApuracaoLacsCsllService();

const nameOfGridHandler =
  criarNameof<AdicaoExclusaoCompensacaoApuracaoLacsCsllGridModel>();

interface ModalDetalhamentoAdicaoExclusaoCompensacaoApuracaoLacsCsllProps {
  modalVisivel: boolean;
  apuracaoDoLacsECsllId: number;
  adicaoOuExclusaoOuCompensacao?: AdicaoOuExclusaoOuCompensacao;
  handleCallbackModal: () => void;
  titulo: string;
}

export default function ModalDetalhamentoAdicaoExclusaoCompensacaoApuracaoLacsCsll(
  props: ModalDetalhamentoAdicaoExclusaoCompensacaoApuracaoLacsCsllProps
) {
  const gridRef = useRef<DataGridRef>(null);

  const dataSource = useMemo(
    () =>
      !props.modalVisivel
        ? new ArrayStore()
        : service.ObterDataSourceParaGrid<AdicaoExclusaoCompensacaoApuracaoLacsCsllGridModel>(
            [
              {
                campo: "apuracaoDoLacsEDoCsllId",
                operador: "=",
                valor: props.apuracaoDoLacsECsllId,
              },
              {
                campo: "adicaoOuExclusaoOuCompensacao",
                operador: "=",
                valor: props.adicaoOuExclusaoOuCompensacao!,
              },
            ]
          ),
    [
      props.modalVisivel,
      props.apuracaoDoLacsECsllId,
      props.adicaoOuExclusaoOuCompensacao,
    ]
  );

  const gridController =
    new GridController<AdicaoExclusaoCompensacaoApuracaoLacsCsllGridModel>(() =>
      gridRef.current?.instance()
    );

  const configuracoesGrid = useMemo(() => {
    return GridBuilder.criar(
      "adicao-exclusao-compensacao-apuracao-lacs-csll",
      () => gridRef.current?.instance()
    )
      .definirStyles({ height: "100%" })
      .definirDataSource(dataSource)
      .definirFiltros()
      .definirRolagem()
      .configurarSelecionadorDeColunas()
      .configurarExportacao(NomesTelas.adicaoExclusaoCompensacaoLacsCsll)
      .definirGravacaoPreferenciasGrid()
      .definirPaginacao()
      .definirBotaoRefresh(handleAtualizarGrid)
      .definirOrdenacao()
      .build();
  }, [
    props.modalVisivel,
    props.apuracaoDoLacsECsllId,
    props.adicaoOuExclusaoOuCompensacao,
  ]);

  function handleAtualizarGrid() {
    gridController.atualizar();
  }

  return (
    <Modal
      titulo={props.titulo}
      visivel={props.modalVisivel}
      onFechar={props.handleCallbackModal}
      altura={"90vh"}
    >
      <ProvedorAjuda
        id={"tooltips-grid-adicao-exclusao-compensacao-apuracao-lacs-csll"}
      >
        <DataGrid ref={gridRef} {...configuracoesGrid}>
          <Column
            dataField={nameOfGridHandler("apuracaoDoLacsEDoCsllId")}
            visible={false}
            showInColumnChooser={false}
          />
          <Column
            dataField={nameOfGridHandler("adicaoOuExclusaoOuCompensacao")}
            visible={false}
            showInColumnChooser={false}
          />
          <Column
            dataField={nameOfGridHandler(
              "adicaoExclusaoCompensacaoApuracaoFormatado"
            )}
            {...obterConfiguracaoColuna("stringGG")}
            caption="Adição, exclusão ou compensação"
          />
          <Column
            dataField={nameOfGridHandler("valorTotalDosLancamentosContabeis")}
            {...obterConfiguracaoColuna("monetario")}
            width={280}
            caption="Valor total dos lançamentos contábeis"
          />
          <Column
            dataField={nameOfGridHandler("valorDaParteB")}
            {...obterConfiguracaoColuna("monetario")}
            width={200}
            caption="Valor da parte B"
          />
          <Column
            dataField={nameOfGridHandler("valorDaParteA")}
            {...obterConfiguracaoColuna("monetario")}
            width={200}
            caption="Valor da parte A"
          />
          <Summary>
            <TotalItem
              column={nameOfGridHandler("valorDaParteA")}
              summaryType="sum"
              valueFormat={{ currency: "BRL", minimumFractionDigits: 2 }}
              displayFormat="{0}"
            />
            <TotalItem
              column={nameOfGridHandler("valorDaParteB")}
              summaryType="sum"
              valueFormat={{ currency: "BRL", minimumFractionDigits: 2 }}
              displayFormat="{0}"
            />
            <TotalItem
              column={nameOfGridHandler("valorTotalDosLancamentosContabeis")}
              summaryType="sum"
              valueFormat={{ currency: "BRL", minimumFractionDigits: 2 }}
              displayFormat="{0}"
            />
          </Summary>
          {GetColunasDeAuditoria()}
        </DataGrid>
      </ProvedorAjuda>
    </Modal>
  );
}
