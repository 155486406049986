import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import ProvedorAjuda from "../../../../../components/ajuda/provedor-ajuda";
import BotaoCancelarMxp from "../../../../../components/botoes/botao-cancelar-mxp";
import BotaoSalvarMxp from "../../../../../components/botoes/botao-salvar-mxp";
import {
  FormCheckBox,
  FormGrupo,
  FormNumberBox,
  FormTextBox,
} from "../../../../../components/formularios";
import FormTextArea from "../../../../../components/formularios/textarea";
import FormMxp from "../../../../../components/layout/form";
import { ContainerFormMxp } from "../../../../../components/layout/form/styles";
import { Coluna, Linha } from "../../../../../components/layout/grid-system";
import ToolbarMxp from "../../../../../components/layout/toolbar-mxp";
import AuditavelDTO from "../../../../../models/api/comum/auditavel-dto";
import { ResponseBase } from "../../../../../models/api/comum/response-base";
import {
  IFormularioEditavelBase,
  ResultadoAcaoFormulario,
} from "../../../../../models/shared/ui/formularios";
import {
  checarResponse,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import {
  ordemValorMaximo,
  ordemValorMinimo,
} from "../../../../../utils/common/constantes";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../utils/common/notificacoes-utils";
import { exibirConfirmacao } from "../../../../../utils/dialogos";
import {
  EstadoConfiguravelDaOrdemDeProducaoRequest,
  EstadoConfiguravelDaOrdemDeProducaoResponse,
} from "../../models/estado-configuravel-da-ordem-de-producao";
import EstadoConfiguravelOrdemDeProducaoConstantes from "../../models/estado-configuravel-da-ordem-de-producao.constantes";
import EstadoConfiguravelDaOrdemDeProducaoService from "../../services/estado-configuravel-da-ordem-de-producao.service";

let dadosAuditoria: AuditavelDTO | undefined = undefined;

const service = new EstadoConfiguravelDaOrdemDeProducaoService();

const novoRegistro: EstadoConfiguravelDaOrdemDeProducaoRequest = {
  id: 0,
  ativo: true,
  descricao: "",
  ordem: null,
  observacoes: null,
};

export default function FormEstadoConfiguravelDaOrdemDeProducao(
  props: IFormularioEditavelBase
) {
  const [carregando, setCarregando] = useState(false);

  const schema = yup.object().shape({
    id: yup.number().required().moreThan(-1).integer(),
    ativo: yup.boolean().required(),
    descricao: yup
      .string()
      .required()
      .max(
        EstadoConfiguravelOrdemDeProducaoConstantes.TamanhoMaximoDescricaoEstadoConfiguravel
      ),
    ordem: yup.number().notRequired().min(ordemValorMinimo),
    observacoes: yup.string().notRequired(),
  });

  const hookForms = useForm<EstadoConfiguravelDaOrdemDeProducaoRequest>({
    resolver: yupResolver(schema),
  });

  const { register, handleSubmit, getValues, reset, formState, control } =
    hookForms;

  //Hook usado para carregar os dados da tela
  useEffect(() => {
    if (Number.isNaN(props.idRegistroEmEdicao)) {
      return;
    }

    preencherTela();
  }, [props.idRegistroEmEdicao]);

  async function preencherTela() {
    if (props.idRegistroEmEdicao == 0) {
      limparTela();
    } else if (props.idRegistroEmEdicao > 0) {
      await carregarRegistroDoId();
    }
  }

  async function carregarRegistroDoId() {
    try {
      setCarregando(true);

      const resposta =
        await service.ObterPorIdComDadosAuditoria<EstadoConfiguravelDaOrdemDeProducaoResponse>(
          props.idRegistroEmEdicao
        );

      checarResponse(resposta);
      dadosAuditoria = resposta.model;
      reset(resposta.model);
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    } finally {
      setCarregando(false);
    }
  }

  function limparTela() {
    dadosAuditoria = undefined;
    reset(novoRegistro);
  }

  function fechar(resultado: ResultadoAcaoFormulario) {
    limparTela();
    props.handleCallback(resultado);
  }

  function callBackUnprocessableEntity() {
    fechar(ResultadoAcaoFormulario.AcaoComErro);
  }

  async function handleSalvar() {
    try {
      setCarregando(true);

      let resposta: ResponseBase;
      const model = getValues() as EstadoConfiguravelDaOrdemDeProducaoRequest;

      if (props.idRegistroEmEdicao > 0) {
        const respostaApi = await service.Atualizar(model);
        resposta = respostaApi;

        checarResponse(resposta);
      } else {
        const respostaInicial = await service.InserirComRetorno<
          EstadoConfiguravelDaOrdemDeProducaoRequest,
          EstadoConfiguravelDaOrdemDeProducaoResponse
        >(model as EstadoConfiguravelDaOrdemDeProducaoRequest);

        resposta = {
          sucesso: respostaInicial.sucesso,
          mensagem: respostaInicial.mensagem,
          erros: respostaInicial.erros,
        };
      }

      checarResponse(resposta);

      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: resposta.mensagem,
          tipo: TipoNotificacao.Sucesso,
        });
        fechar(ResultadoAcaoFormulario.AcaoConcluida);
      }
      fechar(ResultadoAcaoFormulario.AcaoCancelada);
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    } finally {
      setCarregando(false);
    }
  }

  async function handleCancelar() {
    if (formState.isDirty) {
      const confirmacao = await exibirConfirmacao(
        "Aviso",
        "Há dados não salvos. Deseja cancelar?"
      );

      if (!confirmacao) {
        return;
      }
    }

    fechar(ResultadoAcaoFormulario.AcaoCancelada);
  }

  return (
    <ContainerFormMxp data-testid="edit-form-estado-configuravel-da-ordem-de-producao">
      <FormMxp carregando={carregando}>
        <ProvedorAjuda id="edit-form-estado-configuravel-da-ordem-de-producao">
          <input type="hidden" {...register("id")} defaultValue={0} />
          <FormGrupo>
            <Linha>
              <Coluna md={8}>
                <FormTextBox
                  name="descricao"
                  titulo="Descrição"
                  control={control}
                  tamanhoMaximo={
                    EstadoConfiguravelOrdemDeProducaoConstantes.TamanhoMaximoDescricaoEstadoConfiguravel
                  }
                  requerido
                />
              </Coluna>
              <Coluna md={2}>
                <FormNumberBox
                  name="ordem"
                  titulo="Ordem"
                  control={control}
                  minimo={ordemValorMinimo}
                  maximo={ordemValorMaximo}
                />
              </Coluna>
              <Coluna md={2} centralizar>
                <FormCheckBox name="ativo" titulo="Ativo" control={control} />
              </Coluna>
            </Linha>
            <Linha>
              <Coluna md={12}>
                <FormTextArea
                  name="observacoes"
                  titulo="Observações"
                  valor={""}
                  control={control}
                  height={25}
                />
              </Coluna>
            </Linha>
          </FormGrupo>
        </ProvedorAjuda>
      </FormMxp>
      <ToolbarMxp dadosAuditoria={dadosAuditoria}>
        <BotaoSalvarMxp handleClick={handleSubmit(handleSalvar)} />
        <BotaoCancelarMxp handleClick={handleCancelar} />
      </ToolbarMxp>
    </ContainerFormMxp>
  );
}
