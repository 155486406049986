import { DataGrid } from "devextreme-react";
import {
  Column,
  DataGridRef,
  DataGridTypes,
} from "devextreme-react/cjs/data-grid";
import { useCallback, useMemo, useRef } from "react";
import ProvedorAjuda from "../../../../../components/ajuda/provedor-ajuda";
import GridColunaAcoes from "../../../../../components/grid-mxp/grid-mxp-coluna-acoes";
import { useRegistrarAtalhosGrid } from "../../../../../hooks/atalhos.hooks";
import { useSeletorGridMxp } from "../../../../../hooks/seletor.hooks";
import { GridMxpProps } from "../../../../../models/shared/ui/grid";
import GetColunasDeAuditoria from "../../../../../parts/layout/grid-defaults/colunasDeAuditoria";
import criarNameof from "../../../../../utils/common/cria-name-of";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import { formatarDataAno4digitos } from "../../../../../utils/formatadores/formatador-de-datas";
import GridBuilder from "../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../utils/grid/grid-controller";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import { ProjecaoDeUsoGridModel } from "../../models/projecao-de-uso.api";
import { ProjecaoDeUsoService } from "../../service/projecao-de-uso.service";
import { TipoDeOrigemEDestinoHelper } from "../../utils/enum/tipo-destino-ou-origem";
const service = new ProjecaoDeUsoService();

const nameOfGridHandler = criarNameof<ProjecaoDeUsoGridModel>();

const formatarOrigem = (
  data: DataGridTypes.ColumnCellTemplateData
): string | null => {
  const dadosLinha: ProjecaoDeUsoGridModel | undefined = data.row.data;
  if (!dadosLinha) return null;

  return TipoDeOrigemEDestinoHelper.getDescricaoOuDataDaEntidadeCorrespondente(
    "descricao",
    dadosLinha.informacoesOrigemEstoque ??
      dadosLinha.informacoesOrigemItemDoPedidoDeCompra ??
      dadosLinha.informacoesOrigemOrdemDeProducao ??
      dadosLinha.informacoesOrigemSolicitacaoDeCompra
  );
};

const formatarDataOrigem = (
  data: DataGridTypes.ColumnCellTemplateData
): string | null => {
  const dadosLinha: ProjecaoDeUsoGridModel | undefined = data.row.data;
  if (!dadosLinha) return null;

  const dataDeOrigem =
    TipoDeOrigemEDestinoHelper.getDescricaoOuDataDaEntidadeCorrespondente(
      "data",
      dadosLinha.informacoesOrigemEstoque ??
        dadosLinha.informacoesOrigemItemDoPedidoDeCompra ??
        dadosLinha.informacoesOrigemOrdemDeProducao ??
        dadosLinha.informacoesOrigemSolicitacaoDeCompra
    );

  return dataDeOrigem ? formatarDataAno4digitos(dataDeOrigem) : null;
};

const formatarDestino = (
  data: DataGridTypes.ColumnCellTemplateData
): string | null => {
  const dadosLinha: ProjecaoDeUsoGridModel | undefined = data.row.data;

  if (!dadosLinha) return null;

  return TipoDeOrigemEDestinoHelper.getDescricaoOuDataDaEntidadeCorrespondente(
    "descricao",
    dadosLinha.informacoesDestinoOperacaoDaOrdemDeProducao ??
      dadosLinha.informacoesDestinoItemDoPedidoDeVenda ??
      dadosLinha.informacoesDestinoSolicitacaoDeCompra ??
      dadosLinha.informacoesDestinoItemDoPedidoDeCompra ??
      dadosLinha.informacoesDestinoInsumoDaOrdemDeProducao
  );
};

const formatarDataDestino = (
  data: DataGridTypes.ColumnCellTemplateData
): string | null => {
  const dadosLinha: ProjecaoDeUsoGridModel | undefined = data.row.data;

  if (!dadosLinha) return null;

  const dataDeDestino =
    TipoDeOrigemEDestinoHelper.getDescricaoOuDataDaEntidadeCorrespondente(
      "data",
      dadosLinha.informacoesDestinoOperacaoDaOrdemDeProducao ??
        dadosLinha.informacoesDestinoItemDoPedidoDeVenda ??
        dadosLinha.informacoesDestinoSolicitacaoDeCompra ??
        dadosLinha.informacoesDestinoItemDoPedidoDeCompra ??
        dadosLinha.informacoesDestinoInsumoDaOrdemDeProducao
    );

  return dataDeDestino ? formatarDataAno4digitos(dataDeDestino) : null;
};

export default function GridProjecaoDeUso(
  props: GridMxpProps<ProjecaoDeUsoGridModel>
) {
  const gridRef = useRef<DataGridRef>(null);

  const dataSource = service.ObterDataSourceParaGrid<ProjecaoDeUsoGridModel>(
    props.filtrosNoServidor
  );

  useSeletorGridMxp(() => gridRef.current, props.filtrosNoCliente);

  const gridController = useMemo(
    () =>
      new GridController<ProjecaoDeUsoGridModel>(() =>
        gridRef.current?.instance()
      ),
    []
  );

  const handleAtualizarGrid = useCallback(() => {
    gridController.atualizar();
  }, [gridController]);

  useRegistrarAtalhosGrid<ProjecaoDeUsoGridModel>({
    controller: gridController,
  });

  const configuracoesGrid = useMemo(() => {
    return GridBuilder.criar(
      "projecao-de-uso",
      () => gridRef.current?.instance(),
      false,
      props.filtrosNoCliente
    )
      .definirDataSource(dataSource)
      .definirRolagem()
      .configurarSelecionadorDeColunas()
      .definirFiltros()
      .definirGravacaoPreferenciasGrid()
      .definirPaginacao()
      .configurarExportacao(NomesTelas.projecaoDeUso)
      .definirBotaoRefresh(handleAtualizarGrid)
      .definirSelecao()
      .definirOrdenacao()
      .build();
  }, [props.filtrosNoCliente, dataSource, handleAtualizarGrid]);

  return (
    <>
      <ProvedorAjuda id={"tooltips-grid-projecao-de-uso"}>
        <DataGrid ref={gridRef} {...configuracoesGrid}>
          <Column {...obterConfiguracaoColuna("colunaDeEspaco")} />
          {GridColunaAcoes<ProjecaoDeUsoGridModel>({})}
          <Column
            key={nameOfGridHandler("itemCodigo")}
            dataField={nameOfGridHandler("itemCodigo")}
            {...obterConfiguracaoColuna("stringM")}
            caption="Item"
          />
          <Column
            key={nameOfGridHandler("itemDescricao")}
            dataField={nameOfGridHandler("itemDescricao")}
            {...obterConfiguracaoColuna("stringGG")}
            caption="Descrição do item"
          />
          <Column
            key={nameOfGridHandler("itemUnidadeDeMedida")}
            dataField={nameOfGridHandler("itemUnidadeDeMedida")}
            {...obterConfiguracaoColuna("stringM")}
            caption="Unid"
            width={75}
          />
          <Column
            key={nameOfGridHandler("dataEntrada")}
            dataField={nameOfGridHandler("dataEntrada")}
            {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
            cellRender={formatarDataDestino}
            caption="Entrada"
            width={100}
          />
          <Column
            key={nameOfGridHandler("tipoDaOrigemDecodificada")}
            dataField={nameOfGridHandler("tipoDaOrigemDecodificada")}
            {...obterConfiguracaoColuna("stringM")}
            caption="Tipo da origem"
          />
          <Column
            key={"origem"}
            dataField="origem"
            {...obterConfiguracaoColuna("stringGG")}
            caption="Origem"
            cellRender={formatarOrigem}
          />
          <Column
            key={nameOfGridHandler("dataSaida")}
            dataField={nameOfGridHandler("dataSaida")}
            {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
            caption="Saída"
            width={100}
            cellRender={formatarDataOrigem}
          />
          <Column
            key={nameOfGridHandler("destino")}
            dataField={nameOfGridHandler("destino")}
            {...obterConfiguracaoColuna("stringGG")}
            caption="Destino"
            cellRender={formatarDestino}
          />
          <Column
            key={nameOfGridHandler("quantidade")}
            dataField={nameOfGridHandler("quantidade")}
            {...obterConfiguracaoColuna(
              "quantidadeComNoMaximoCincoCasasDecimais"
            )}
            caption="Qt"
          />
          <Column
            key={nameOfGridHandler("itemDoPedidoDeVendaFinal")}
            dataField={nameOfGridHandler("itemDoPedidoDeVendaFinal")}
            {...obterConfiguracaoColuna("stringGG")}
            caption="Item do pedido de venda final"
          />
          <Column
            key={nameOfGridHandler("quantidadeReservada")}
            dataField={nameOfGridHandler("quantidadeReservada")}
            {...obterConfiguracaoColuna(
              "quantidadeComNoMaximoCincoCasasDecimais"
            )}
            caption="Qt reservada"
          />
          <Column
            key={nameOfGridHandler("quantidadeNaoReservada")}
            dataField={nameOfGridHandler("quantidadeNaoReservada")}
            {...obterConfiguracaoColuna(
              "quantidadeComNoMaximoCincoCasasDecimais"
            )}
            caption="Qt não reservada"
          />
          {GetColunasDeAuditoria(false, false, [
            { ordenarPor: "criacaoData", ordem: "desc" },
          ])}
        </DataGrid>
      </ProvedorAjuda>
    </>
  );
}
