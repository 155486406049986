import { DataGrid } from "devextreme-react";
import { Column, DataGridRef } from "devextreme-react/cjs/data-grid";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ProvedorAjuda from "../../../../../components/ajuda/provedor-ajuda";
import GridColunaAcoes from "../../../../../components/grid-mxp/grid-mxp-coluna-acoes";
import { ModalMxp } from "../../../../../components/layout/modal-mxp";
import { useRegistrarAtalhosGrid } from "../../../../../hooks/atalhos.hooks";
import { useMenuDeContextosGrid } from "../../../../../hooks/menus.hooks";
import { useParametroId } from "../../../../../hooks/route.hooks";
import { useSeletorGridMxp } from "../../../../../hooks/seletor.hooks";
import { PermissoesCentroDeTrabalho } from "../../../../../models/permissoes/producao/centro-de-trabalho/permissoes-centro-de-trabalho";
import { PermissoesCentroDeTrabalhoGrupo } from "../../../../../models/permissoes/producao/grupo-centro-de-trabalho/permissoes-grupo-centro-de-trabalho";
import { ResultadoAcaoFormulario } from "../../../../../models/shared/ui/formularios";
import { GridMxpProps } from "../../../../../models/shared/ui/grid";
import GetColunasDeAuditoria from "../../../../../parts/layout/grid-defaults/colunasDeAuditoria";
import {
  checarResponseExibeMensagemExclusaoDeSucesso,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import criarNameof from "../../../../../utils/common/cria-name-of";
import NomesModais from "../../../../../utils/common/nomes-modais";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import NormalizaTituloModal from "../../../../../utils/common/normaliza-titulo";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../utils/common/permissoes-utils";
import { ItemContextMenuMxp } from "../../../../../utils/context-menu/context-menu-utils";
import { GestorEventoClickUnicaLinha } from "../../../../../utils/context-menu/gestor-evento-click";
import { exibirConfirmacao } from "../../../../../utils/dialogos";
import GridBuilder from "../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../utils/grid/grid-controller";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import UrlUtils from "../../../../../utils/url/url-utils";
import GridCentroDeTrabalho from "../../../centro-de-trabalho/componentes/grid";
import { CentroDeTrabalhoGridModel } from "../../../centro-de-trabalho/models/centro-de-trabalho.api";
import { CentroDeTrabalhoGrupoGridModel } from "../../models/grupo-de-centro-de-trabalho.api";
import GrupoDeCentroDeTrabalhoServico from "../../servicos/grupo-de-centro-de-trabalho.service";
import FormGrupoDeCentroDeTrabalho from "../formulario";

const service = new GrupoDeCentroDeTrabalhoServico();

const nameOfGridHandler = criarNameof<CentroDeTrabalhoGrupoGridModel>();
const nameOfGridCentroDeTrabalho = criarNameof<CentroDeTrabalhoGridModel>();
let filtroCodigoGrupoCentroDeTrabalho: any[] | undefined = undefined;

export default function GridGrupoDeCentroDeTrabalho(
  props: GridMxpProps<CentroDeTrabalhoGrupoGridModel>
) {
  const gridRef = useRef<DataGridRef>(null);
  const parametroId = useParametroId();
  const [idRegistroEdicao, setIdRegistroEdicao] = useState(NaN);
  const [
    modalConsultaCentroDeTrabalhoVisivel,
    setModalConsultaCentroDeTrabalhoVisivel,
  ] = useState(false);

  useSeletorGridMxp(() => gridRef.current, props.filtrosNoCliente);

  const dataSource =
    service.ObterDataSourceParaGrid<CentroDeTrabalhoGrupoGridModel>(
      props.filtrosNoServidor
    );

  const handleNovoRegistro = useCallback(() => {
    if (
      !verificaComNotificacaoSeUsuarioPossuiPermissoes([
        PermissoesCentroDeTrabalhoGrupo.InserirEditar,
      ])
    ) {
      return;
    }

    setIdRegistroEdicao(0);
  }, []);

  const handleEditarRegistro = useCallback(
    (registro: CentroDeTrabalhoGrupoGridModel) => {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesCentroDeTrabalhoGrupo.InserirEditar,
        ])
      ) {
        return;
      }

      setIdRegistroEdicao(registro.id);
    },
    []
  );

  const handleExcluirRegistro = useCallback(
    async (registro: CentroDeTrabalhoGrupoGridModel) => {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesCentroDeTrabalhoGrupo.Excluir,
        ])
      ) {
        return;
      }

      const excluir = await exibirConfirmacao(
        "Confirmar exclusão",
        obterMensagemExclusao(registro)
      );

      if (excluir) {
        try {
          const resposta = await service.Excluir(registro.id);

          if (resposta) {
            checarResponseExibeMensagemExclusaoDeSucesso(resposta);
            handleAtualizarGrid();
          }
        } catch (erro) {
          tratarErroApi(erro);
        }
      }
    },
    []
  );

  useEffect(() => {
    if (parametroId) {
      setIdRegistroEdicao(parametroId);
    }
  }, []);

  function obterMensagemExclusao(registro: CentroDeTrabalhoGrupoGridModel) {
    return `Tem certeza de que deseja excluir o grupo de centro de trabalho ${registro.codigo}?`;
  }

  function handleAtualizarGrid() {
    gridController.atualizar();
  }

  const gridController = new GridController<CentroDeTrabalhoGrupoGridModel>(
    () => gridRef.current?.instance()
  );

  useRegistrarAtalhosGrid<CentroDeTrabalhoGrupoGridModel>({
    controller: gridController,
    handleNovo: handleNovoRegistro,
    handleEditar: handleEditarRegistro,
    handleExcluir: handleExcluirRegistro,
  });

  const handleCallbackFormulario = useCallback(
    (resultado: ResultadoAcaoFormulario) => {
      setIdRegistroEdicao(NaN);

      if (resultado == ResultadoAcaoFormulario.AcaoConcluida) {
        handleAtualizarGrid();
      }

      UrlUtils.RemoverParametroId(parametroId);
    },
    [parametroId]
  );

  const handleFecharModal = useCallback(() => {
    setIdRegistroEdicao(NaN);
    UrlUtils.RemoverParametroId(parametroId);
  }, [parametroId]);

  const handleModalConsultarCentroDeTrabalhoCallBack = useCallback(() => {
    setModalConsultaCentroDeTrabalhoVisivel(false);
  }, []);

  const handleConsultarCentrosDeTrabalho = useCallback(
    (centroDeTrabalhoGrupo: CentroDeTrabalhoGrupoGridModel) => {
      if (
        verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesCentroDeTrabalho.Consultar,
        ])
      ) {
        filtroCodigoGrupoCentroDeTrabalho = [
          nameOfGridCentroDeTrabalho("centroDeTrabalhoGrupo"),
          "=",
          centroDeTrabalhoGrupo?.descricao
            ? `${centroDeTrabalhoGrupo?.codigo} - ${centroDeTrabalhoGrupo?.descricao}`
            : centroDeTrabalhoGrupo?.codigo,
        ];

        setModalConsultaCentroDeTrabalhoVisivel(true);
      }
    },
    []
  );

  const itensMenuDeContexto: ItemContextMenuMxp[] = [
    {
      text: "Consultar",
      icon: "ic-material-symbols-outlined ic-manage-search",
      hint: "Menu com opções de consulta",
      exibirNaLinhaDaGrid: "menuDeContexto",
      exibirNoMenuPrincipal: true,
      items: [
        {
          text: NomesTelas.centrosDeTrabalho,
          icon: "ic-material-symbols-outlined ic-quick-reference-all",
          gestorEventoClick: new GestorEventoClickUnicaLinha(
            handleConsultarCentrosDeTrabalho,
            () => gridController
          ),
        },
      ],
    },
  ];

  useMenuDeContextosGrid(itensMenuDeContexto);

  const configuracoesGrid = useMemo(() => {
    return GridBuilder.criar(
      "grupo-centros-trabalho",
      () => gridRef.current?.instance(),
      false,
      props.filtrosNoCliente
    )
      .definirStyles(props.style)
      .definirDataSource(dataSource)
      .definirFiltros()
      .definirRolagem()
      .configurarSelecionadorDeColunas()
      .definirGravacaoPreferenciasGrid()
      .definirPaginacao()
      .configurarExportacao(NomesTelas.gruposCentrosTrabalho)
      .definirBotaoNovo(handleNovoRegistro)
      .definirBotaoRefresh(handleAtualizarGrid)
      .definirSelecao()
      .definirOrdenacao()
      .definirMenuDeContexto(itensMenuDeContexto)
      .definirDuploCliqueLinha(handleEditarRegistro)
      .build();
  }, [props.filtrosNoCliente]);

  return (
    <>
      <ProvedorAjuda id={"tooltips-grid-grupo-centro-trabalho"}>
        <DataGrid ref={gridRef} {...configuracoesGrid}>
          <Column {...obterConfiguracaoColuna("colunaDeEspaco")} />
          {GridColunaAcoes<CentroDeTrabalhoGrupoGridModel>({
            handleEditar: handleEditarRegistro,
            handleExcluir: handleExcluirRegistro,
          })}
          <Column
            key={nameOfGridHandler("codigo")}
            dataField={nameOfGridHandler("codigo")}
            {...obterConfiguracaoColuna("stringP")}
            sortIndex={0}
            sortOrder="asc"
            caption="Código"
          />
          <Column
            key={nameOfGridHandler("descricao")}
            dataField={nameOfGridHandler("descricao")}
            {...obterConfiguracaoColuna("stringG")}
            caption="Descrição"
          />
          <Column
            key={nameOfGridHandler("grupoDeCentroDeTrabalhoCalendario")}
            dataField={nameOfGridHandler("grupoDeCentroDeTrabalhoCalendario")}
            {...obterConfiguracaoColuna("stringGG")}
            caption="Calendário do grupo de centros de trabalho"
          />
          {GetColunasDeAuditoria()}
        </DataGrid>
      </ProvedorAjuda>

      {/*Modal Formulário*/}
      <ModalMxp
        titulo={NormalizaTituloModal.Normalizar(
          idRegistroEdicao,
          NomesModais.grupoCentroDeTrabalho
        )}
        visivel={!Number.isNaN(idRegistroEdicao)}
        handleFechar={handleFecharModal}
        largura={"max(50vw, 800px)"}
        altura={"auto"}
      >
        <FormGrupoDeCentroDeTrabalho
          idRegistroEmEdicao={idRegistroEdicao}
          handleCallback={handleCallbackFormulario}
        />
      </ModalMxp>

      {/*Modal Consultar Centro de Trabalho*/}
      <ModalMxp
        titulo={"Consultar centro de trabalho"}
        visivel={modalConsultaCentroDeTrabalhoVisivel}
        handleFechar={handleModalConsultarCentroDeTrabalhoCallBack}
        largura={"max(50vw, 800px)"}
        altura={"90vh"}
      >
        <GridCentroDeTrabalho
          style={{
            width: "100%",
            height: "100%",
          }}
          filtrosNoCliente={filtroCodigoGrupoCentroDeTrabalho}
        />
      </ModalMxp>
    </>
  );
}
