import { Button } from "devextreme-react";

interface BotaoImprimirMxpProps {
  handleClick: () => void;
}

export default function BotaoImprimirMxp(props: BotaoImprimirMxpProps) {
  return (
    <Button
      type="normal"
      text={"Imprimir"}
      icon={"print"}
      onClick={props.handleClick}
    />
  );
}
