import { DataGrid } from "devextreme-react";
import { Column, DataGridRef } from "devextreme-react/cjs/data-grid";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ProvedorAjuda from "../../../../../components/ajuda/provedor-ajuda";
import GridColunaAcoes from "../../../../../components/grid-mxp/grid-mxp-coluna-acoes";
import { ModalMxp } from "../../../../../components/layout/modal-mxp";
import { useRegistrarAtalhosGrid } from "../../../../../hooks/atalhos.hooks";
import { useParametroId } from "../../../../../hooks/route.hooks";
import { useSeletorGridMxp } from "../../../../../hooks/seletor.hooks";
import { PermissoesVinculoEnderecoEstoqueCentroDeTrabalho } from "../../../../../models/permissoes/producao/vinculo-endereco-estoque-centro-de-trabalho/permissoes-vinculo-endereco-estoque-centro-de-trabalho";
import { ResultadoAcaoFormulario } from "../../../../../models/shared/ui/formularios";
import { GridMxpProps } from "../../../../../models/shared/ui/grid";
import GetColunasDeAuditoria from "../../../../../parts/layout/grid-defaults/colunasDeAuditoria";
import { checarResponseExibeMensagemExclusaoDeSucesso } from "../../../../../utils/api/api-utils";
import criarNameof from "../../../../../utils/common/cria-name-of";
import NomesModais from "../../../../../utils/common/nomes-modais";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import NormalizaTituloModal from "../../../../../utils/common/normaliza-titulo";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../utils/common/permissoes-utils";
import { exibirConfirmacao } from "../../../../../utils/dialogos";
import GridBuilder from "../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../utils/grid/grid-controller";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import UrlUtils from "../../../../../utils/url/url-utils";
import { VinculoEnderecoEstoqueCentroDeTrabalhoGridModel } from "../../models/vinculo-endereco-estoque-centro-de-trabalho.api";
import VinculoEnderecoEstoqueCentroDeTrabalhoServico from "../../servicos/vinculo-endereco-estoque-centro-de-trabalho.service";
import FormVinculoEnderecoDeEstoqueCentroDeTrabalho from "../formulario";

const service = new VinculoEnderecoEstoqueCentroDeTrabalhoServico();
const nameofGridHandler =
  criarNameof<VinculoEnderecoEstoqueCentroDeTrabalhoGridModel>();

export default function GridVinculoEnderecoEstoqueCentroDeTrabalho(
  props: GridMxpProps<VinculoEnderecoEstoqueCentroDeTrabalhoGridModel>
) {
  const gridRef = useRef<DataGridRef>(null);
  const parametroId = useParametroId();
  const [idRegistroEdicao, setIdRegistroEdicao] = useState(NaN);

  useSeletorGridMxp(() => gridRef.current, props.filtrosNoCliente);

  const dataSource =
    service.ObterDataSourceParaGrid<VinculoEnderecoEstoqueCentroDeTrabalhoGridModel>(
      props.filtrosNoServidor
    );

  const handleNovoRegistro = useCallback(() => {
    if (
      !verificaComNotificacaoSeUsuarioPossuiPermissoes([
        PermissoesVinculoEnderecoEstoqueCentroDeTrabalho.InserirEditar,
      ])
    ) {
      return;
    }

    setIdRegistroEdicao(0);
  }, []);

  const handleEditarRegistro = useCallback(
    (registro: VinculoEnderecoEstoqueCentroDeTrabalhoGridModel) => {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesVinculoEnderecoEstoqueCentroDeTrabalho.InserirEditar,
        ])
      ) {
        return;
      }

      setIdRegistroEdicao(registro.id);
    },
    []
  );

  const handleExcluirRegistro = useCallback(
    async (registro: VinculoEnderecoEstoqueCentroDeTrabalhoGridModel) => {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesVinculoEnderecoEstoqueCentroDeTrabalho.Excluir,
        ])
      ) {
        return;
      }

      const excluir = await exibirConfirmacao(
        "Confirmar exclusão",
        obterMensagemExclusao(registro)
      );

      if (excluir) {
        const resposta = await service.Excluir(registro.id);

        if (resposta) {
          checarResponseExibeMensagemExclusaoDeSucesso(resposta);
          handleAtualizarGrid();
        }
      }
    },
    []
  );

  function obterMensagemExclusao(
    registro: VinculoEnderecoEstoqueCentroDeTrabalhoGridModel
  ) {
    return `Tem certeza de que deseja excluir o vínculo entre ${registro.centroDeTrabalho} e ${registro.enderecoEstoque}?`;
  }

  const gridController =
    new GridController<VinculoEnderecoEstoqueCentroDeTrabalhoGridModel>(() =>
      gridRef.current?.instance()
    );

  const configuracoesGrid = useMemo(() => {
    return GridBuilder.criar(
      "vinculo-endereco-estoque-centro-de-trabalho",
      () => gridRef.current?.instance(),
      false,
      props.filtrosNoCliente
    )
      .definirStyles(props.style)
      .definirDataSource(dataSource)
      .definirFiltros()
      .definirRolagem()
      .configurarSelecionadorDeColunas()
      .definirGravacaoPreferenciasGrid()
      .definirPaginacao()
      .configurarExportacao(
        NomesTelas.vinculosDoCentroDeTrabalhoComEnderecosDeEstoque
      )
      .definirBotaoNovo(handleNovoRegistro)
      .definirBotaoRefresh(handleAtualizarGrid)
      .definirSelecao()
      .definirOrdenacao()
      .definirDuploCliqueLinha(handleEditarRegistro)
      .build();
  }, [props.filtrosNoCliente]);

  useEffect(() => {
    if (parametroId) {
      setIdRegistroEdicao(parametroId);
    }
  }, []);

  function handleAtualizarGrid() {
    gridController.atualizar();
  }

  useRegistrarAtalhosGrid<VinculoEnderecoEstoqueCentroDeTrabalhoGridModel>({
    controller: gridController,
    handleNovo: handleNovoRegistro,
    handleEditar: handleEditarRegistro,
    handleExcluir: handleExcluirRegistro,
  });

  const handleCallbackFormulario = useCallback(
    (resultado: ResultadoAcaoFormulario) => {
      setIdRegistroEdicao(NaN);

      if (resultado == ResultadoAcaoFormulario.AcaoConcluida) {
        handleAtualizarGrid();
      }

      UrlUtils.RemoverParametroId(parametroId);
    },
    [parametroId]
  );

  const handleFecharModal = useCallback(() => {
    setIdRegistroEdicao(NaN);
    UrlUtils.RemoverParametroId(parametroId);
  }, [parametroId]);

  return (
    <>
      <ProvedorAjuda
        id={"tooltips-grid-vinculo-endereco-estoque-centro-trabalho"}
      >
        <DataGrid ref={gridRef} {...configuracoesGrid}>
          <Column {...obterConfiguracaoColuna("colunaDeEspaco")} />
          {GridColunaAcoes<VinculoEnderecoEstoqueCentroDeTrabalhoGridModel>({
            handleEditar: handleEditarRegistro,
            handleExcluir: handleExcluirRegistro,
          })}
          <Column
            key={nameofGridHandler("centroDeTrabalho")}
            dataField={nameofGridHandler("centroDeTrabalho")}
            caption="Centro de trabalho"
            {...obterConfiguracaoColuna("stringGG")}
          />
          <Column
            key={nameofGridHandler("enderecoEstoque")}
            dataField={nameofGridHandler("enderecoEstoque")}
            caption="Endereço de estoque"
            {...obterConfiguracaoColuna("stringGG")}
          />
          <Column
            key={nameofGridHandler("minhaEmpresa")}
            dataField={nameofGridHandler("minhaEmpresa")}
            caption="Minha empresa"
            {...obterConfiguracaoColuna("stringGG")}
          />
          {GetColunasDeAuditoria()},
        </DataGrid>
      </ProvedorAjuda>

      <ModalMxp
        titulo={NormalizaTituloModal.Normalizar(
          idRegistroEdicao,
          NomesModais.vinculoDoCentroDeTrabalhoComEnderecoDeEstoque
        )}
        visivel={!Number.isNaN(idRegistroEdicao)}
        handleFechar={handleFecharModal}
        largura={"max(50vw, 800px)"}
        altura={"auto"}
      >
        <FormVinculoEnderecoDeEstoqueCentroDeTrabalho
          idRegistroEmEdicao={idRegistroEdicao}
          handleCallback={handleCallbackFormulario}
        />
      </ModalMxp>
    </>
  );
}
