import { useCallback, useEffect, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import * as yup from "yup";
import { FormSelectBox } from "../../../../../../components/formularios";
import { Coluna, Linha } from "../../../../../../components/layout/grid-system";
import SelectItem from "../../../../../../models/shared/ui/select-item";
import criarNameof from "../../../../../../utils/common/cria-name-of";
import { MensagensPadraoYup } from "../../../../../comum/utils/yup/mensagens";
import {
  FinalidadeTipo,
  FinalidadeTipoHelper,
} from "../../../../../contabilidade/conta-contabil/models/conta-contabil.enum";
import { ContaContabilService } from "../../../../../contabilidade/conta-contabil/servicos/conta-contabil.service";
import ComboEmpresaMxp from "../../../../../sistema/empresa/componentes/select-box-lazy";
import ComboEnderecoEstoqueMxp from "../../../../endereco-de-estoque/componentes/parts/combo-endereco-estoque";
import {
  EstoqueMovimentacaoTipo,
  EstoqueMovimentacaoTipoHelper,
} from "../../../models/estoque-movimentacao.enums";

export interface SelecionarEstoqueDestinoRequest {
  idEnderecoDeEstoque?: number | null;
  idContaContabilDestino?: number | null;
  destino: EstoqueMovimentacaoTipo;
  finalidadeContaContabil: FinalidadeTipo;
  empresaDonaId?: number;
  empresaPosseId?: number;
}

interface SelecionarEstoqueDestinoProps<
  T extends SelecionarEstoqueDestinoRequest
> {
  hookForms: UseFormReturn<T>;
  somenteLeitura?: boolean;
}

const apiContaContabil = new ContaContabilService();

const datasouceDestinos = EstoqueMovimentacaoTipoHelper.AsSelectItems().filter(
  (x) =>
    x.valor === EstoqueMovimentacaoTipo.Estoque ||
    x.valor === EstoqueMovimentacaoTipo.ContaContabil
);

const dataSourceContaContabilGrid =
  apiContaContabil.GetDataSourceSelectBoxContasDeEstoque();

let dataSourceContaContabilGridCarregado: any[] = [];

const nameOfSelecionarEstoqueDestinoRequest =
  criarNameof<SelecionarEstoqueDestinoRequest>();

export const yupCampoEmpresaDonaId = () =>
  yup
    .number()
    .when(nameOfSelecionarEstoqueDestinoRequest("finalidadeContaContabil"), {
      is: (finalidadeContaContabil: FinalidadeTipo) =>
        finalidadeContaContabil ===
          FinalidadeTipo.EstoqueDeTerceirosEmNossoPoder ||
        finalidadeContaContabil ===
          FinalidadeTipo.EstoqueDeTerceirosEmPoderDeTerceiros,
      then: (schema) =>
        schema.required(MensagensPadraoYup.campoObrigatorio).moreThan(0),
      otherwise: (schema) => schema.notRequired(),
    });

export const yupCampoEmpresaPosseId = () =>
  yup
    .number()
    .when(nameOfSelecionarEstoqueDestinoRequest("finalidadeContaContabil"), {
      is: (finalidadeContaContabil: FinalidadeTipo) =>
        finalidadeContaContabil === FinalidadeTipo.EstoqueEmPoderDeTerceiros ||
        finalidadeContaContabil ===
          FinalidadeTipo.EstoqueDeTerceirosEmPoderDeTerceiros,
      then: (schema) =>
        schema.required(MensagensPadraoYup.campoObrigatorio).moreThan(0),
      otherwise: (schema) => schema.notRequired(),
    });

export default function SelecionarEstoqueDestino(
  props: SelecionarEstoqueDestinoProps<SelecionarEstoqueDestinoRequest>
) {
  const [
    dataSourceContaContabilGridFiltrado,
    setDataSourceContaContabilGridFiltrado,
  ] = useState<SelectItem[]>([]);

  const { control, getValues, setValue, watch, register } = props.hookForms;

  useEffect(() => {
    CarregarContasContabeis();
  }, []);

  useEffect(() => {
    filtraContasContabeis();
  }, [watch("destino")]);

  useEffect(() => {
    if (!getValues("idContaContabilDestino")) {
      filtraContasContabeis();
    }

    setValue(
      "finalidadeContaContabil",
      dataSourceContaContabilGridCarregado.filter(
        (y) => y.id == getValues("idContaContabilDestino")
      )[0]?.finalidade ?? FinalidadeTipo.Estoque
    );
  }, [watch("idContaContabilDestino")]);

  async function CarregarContasContabeis() {
    // Carregar opções com limite de 1000
    dataSourceContaContabilGrid.loadOptions().take = 1000;
    await dataSourceContaContabilGrid.load();
    dataSourceContaContabilGridCarregado = dataSourceContaContabilGrid.items();

    // Filtrar contas contábeis
    filtraContasContabeis();
  }

  function filtraContasContabeis() {
    const destino = getValues("destino");
    const isEstoque = destino == EstoqueMovimentacaoTipo.Estoque;

    const contasFiltradas = dataSourceContaContabilGridCarregado.filter((x) =>
      isEstoque
        ? FinalidadeTipoHelper.isFinalidadeDeEstoque(x.finalidade)
        : !FinalidadeTipoHelper.isFinalidadeDeEstoque(x.finalidade)
    );

    const contasFormatadas = contasFiltradas.map((x) => ({
      valor: x.id,
      descricao: x.apelido,
    }));

    setDataSourceContaContabilGridFiltrado(contasFormatadas);
    if (
      contasFiltradas.length > 0 &&
      !contasFiltradas.some((x) => x.id == getValues("idContaContabilDestino"))
    ) {
      setValue("idContaContabilDestino", contasFiltradas[0].id);
    }
  }

  const limparEstoqueCasoDestinoSejaContaContabil = useCallback(
    (x: EstoqueMovimentacaoTipo) => {
      if (x == EstoqueMovimentacaoTipo.ContaContabil) {
        setValue("idEnderecoDeEstoque", null);
      }
    },
    [setValue]
  );

  return (
    <>
      <Linha>
        <input
          type="hidden"
          {...register("finalidadeContaContabil")}
          defaultValue={0}
        />
        <Coluna md={3}>
          <FormSelectBox
            name="destino"
            titulo="Destino"
            control={control}
            somenteLeitura={props.somenteLeitura}
            dataSource={datasouceDestinos}
            onValueChange={limparEstoqueCasoDestinoSejaContaContabil}
            requerido
          />
        </Coluna>
        <Coluna md={4}>
          <FormSelectBox
            name="idContaContabilDestino"
            titulo="‎" //Caractere invisível para não quebrar o layout
            control={control}
            somenteLeitura={props.somenteLeitura}
            dataSource={dataSourceContaContabilGridFiltrado}
            labelSemDados="Sem dados"
          />
        </Coluna>
        <Coluna md={5}>
          {watch("destino") != EstoqueMovimentacaoTipo.ContaContabil && (
            <ComboEnderecoEstoqueMxp
              name="idEnderecoDeEstoque"
              titulo="Endereço de estoque"
              control={control}
              somenteLeitura={
                watch("destino") !== EstoqueMovimentacaoTipo.Estoque ||
                props.somenteLeitura
              }
            />
          )}
        </Coluna>
      </Linha>
      <Linha>
        {FinalidadeTipoHelper.isFinalidadeDeTerceiros(
          watch("finalidadeContaContabil")
        ) && (
          <Coluna md={6}>
            <ComboEmpresaMxp
              name="empresaDonaId"
              titulo="Dono"
              control={control}
              requerido
              tituloSeletor="Selecionar empresa dona do estoque"
            />
          </Coluna>
        )}
        {FinalidadeTipoHelper.isFinalidadeEmTerceiros(
          watch("finalidadeContaContabil")
        ) && (
          <Coluna md={6}>
            <ComboEmpresaMxp
              name="empresaPosseId"
              titulo="Em poder de"
              control={control}
              requerido
              tituloSeletor="Selecionar empresa posse do estoque"
            />
          </Coluna>
        )}
      </Linha>
    </>
  );
}
