import DataSource from "devextreme/data/data_source";
import {
  assertConfiguracaoExibicaoEBuscaType,
  ConfiguracaoExibicaoEBusca,
} from "../../../../components/formularios/selectbox-lazy-mxp";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import { ServiceBase } from "../../../../services/comum/serviceBase";
import { DataSourceOpcoesBuilder } from "../../../../utils/grid/data-source-factory";
import { EmpresaGridModel } from "../models/empresa-grid";
import EmpresaFiltrosHelpers from "../utils/data-source/EmpresaFiltrosHelpers";

export default class EmpresaServico extends ServiceBase {
  constructor() {
    super(NomesEndpoints.Empresa);
  }

  public GetDadosSelectBoxEmpresa(
    dataSourceOpcoes?: DataSourceOpcoesBuilder<EmpresaGridModel>
  ): [
    DataSource<EmpresaGridModel, number>,
    ConfiguracaoExibicaoEBusca<EmpresaGridModel>
  ] {
    const dataSource = this.ObterDataSourceParaSelectBoxLazy<EmpresaGridModel>({
      quantidadeRegistros: dataSourceOpcoes?.quantidadeRegistros,
      camposRetorno: dataSourceOpcoes?.camposRetorno || [
        "id",
        "apelido",
        "cnpjCpfCodigo",
        "razaoSocial",
      ],
      camposOrdenacao: dataSourceOpcoes?.camposOrdenacao || [
        { campo: "apelido", desc: false },
      ],
      camposFiltro:
        dataSourceOpcoes?.camposFiltro || EmpresaFiltrosHelpers.FiltrarAtivos,
    });

    const configs = assertConfiguracaoExibicaoEBuscaType<EmpresaGridModel>({
      expressaoDeBusca: ["apelido", "cnpjCpfCodigo"],
      nomeCampoChave: "id",
      nomeCampoExibicao: (c) => {
        if (c) {
          return `${c.apelido} (${c.cnpjCpfCodigo})`;
        }

        return "";
      },
    });
    return [dataSource, configs];
  }
}
