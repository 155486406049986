import {
  ConfiguracoesErros,
  TipoErro,
} from "../../../../../models/api/comum/erros";
import { ErroLote } from "./lote.erros";

export const configuracoesErrosLote = Object.freeze<
  ConfiguracoesErros<ErroLote>
>({
  "LOT-0001": {
    tipo: TipoErro.PopUpAgrupado,
    formatador({ dadosExtras }) {
      return (
        <div>
          <p>
            Não foi possível salvar esta ordem de produção, pois, ao tentar
            criar os números de série para o item {dadosExtras.item}, um ou mais
            números de série já foram utilizados.
          </p>
          <p>
            Para continuar, verifique o próximo sequencial do prefixo e sufixo
            do número de série ou o cadastro dos números de série deste item em
            questão.
          </p>
        </div>
      );
    },
  },
  "LOT-0002": {
    tipo: TipoErro.PopUpAgrupado,
    formatador({ dadosExtras }) {
      return (
        <div>
          <p>Não é possível diminuir a quantidade a ser produzida.</p>
          <p>
            A ordem de produção foi criada com estocagem por número de série e
            existem {dadosExtras} números de série previstos para esta produção.
          </p>
          <p>
            Antes de alterar a quantidade, é necessário cancelar os números de
            série associados a esta ordem de produção que não serão produzidos.
          </p>
          <p>
            É possível cancelar números de série a partir da tela{" "}
            <b>&quot;Estoque &gt; Lotes&quot;</b>.
          </p>
        </div>
      );
    },
  },
});
