import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import ProvedorAjuda from "../../../../../../components/ajuda/provedor-ajuda";
import BotaoCancelarMxp from "../../../../../../components/botoes/botao-cancelar-mxp";
import BotaoSalvarMxp from "../../../../../../components/botoes/botao-salvar-mxp";
import {
  FormGrupo,
  FormNumberBox,
  FormTextBox,
} from "../../../../../../components/formularios";
import FormMxp from "../../../../../../components/layout/form";
import { ContainerFormMxp } from "../../../../../../components/layout/form/styles";
import { Coluna, Linha } from "../../../../../../components/layout/grid-system";
import ToolbarMxp from "../../../../../../components/layout/toolbar-mxp";
import {
  IFormularioEditavelBase,
  ResultadoAcaoFormulario,
} from "../../../../../../models/shared/ui/formularios";
import {
  checarResponse,
  tratarErroApi,
} from "../../../../../../utils/api/api-utils";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../../utils/common/notificacoes-utils";
import { LinkAjudaRequestDTO } from "../../../models/link-ajuda";
import LinkAjudaConstantes from "../../../models/link-ajuda-constantes";
import LinkDeAjudaService from "../../../servicos/link-ajuda.service";

interface EditFormLinkAjudaPaginaProps extends IFormularioEditavelBase {
  codigo: string;
}

const linkAjudaService = new LinkDeAjudaService();

const linkhome = process.env.REACT_APP_HELP_HOME as string;

export default function EditFormLinkAjudaPagina(
  props: EditFormLinkAjudaPaginaProps
) {
  const novoRegistro: LinkAjudaRequestDTO = {
    id: 0,
    codigo: props.codigo,
    slug: "",
    textoExibicao: "",
    ordemExibicao: 0,
  };

  const [carregando, setCarregando] = useState(false);

  const schema = yup.object().shape({
    id: yup.number().required().moreThan(-1).integer(),
    codigo: yup
      .string()
      .required()
      .max(LinkAjudaConstantes.CodigoTamanhoMaximo),
    slug: yup.string().required().max(LinkAjudaConstantes.SlugTamanhoMaximo),
    textoExibicao: yup
      .string()
      .required()
      .max(LinkAjudaConstantes.TextoExibicaoTamanhoMaximo),
    ordemExibicao: yup
      .number()
      .required()
      .moreThan(
        LinkAjudaConstantes.OrdemExbicaoTamanhos.MaiorQue,
        "Mínimo de 0 e máximo de 999"
      )
      .lessThan(
        LinkAjudaConstantes.OrdemExbicaoTamanhos.MenorQue,
        "Mínimo de 0 e máximo de 999"
      )
      .integer(),
  });

  const { register, control, handleSubmit, getValues, reset } =
    useForm<LinkAjudaRequestDTO>({
      resolver: yupResolver(schema),
    });

  //Hook usado para carregar os dados da tela
  useEffect(() => {
    if (Number.isNaN(props.idRegistroEmEdicao)) {
      return;
    }

    preencherTela();
  }, [props.idRegistroEmEdicao]);

  async function preencherTela() {
    if (props.idRegistroEmEdicao == 0) {
      limparTela();
    } else if (props.idRegistroEmEdicao > 0) {
      await carregarRegistroDoId();
    }
  }

  async function carregarRegistroDoId() {
    try {
      const resposta = await linkAjudaService.ObterPorId(
        props.idRegistroEmEdicao
      );
      checarResponse(resposta);
      reset(resposta.model);
    } catch (erro) {
      tratarErroApi(erro);
    }
  }

  function limparTela() {
    reset(novoRegistro);
  }

  async function handleSalvar() {
    setCarregando(true);
    const model = getValues();
    try {
      const resposta =
        props.idRegistroEmEdicao > 0
          ? await linkAjudaService.EditarPorId(model as LinkAjudaRequestDTO)
          : await linkAjudaService.InserirPorId(model as LinkAjudaRequestDTO);

      checarResponse(resposta);

      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: resposta.mensagem,
          tipo: TipoNotificacao.Sucesso,
        });
        fechar(ResultadoAcaoFormulario.AcaoConcluida);
      }
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      setCarregando(false);
    }
  }

  function handleCancelar() {
    fechar(ResultadoAcaoFormulario.AcaoCancelada);
  }

  function fechar(resultado: ResultadoAcaoFormulario) {
    limparTela();
    props.handleCallback(resultado);
  }

  return (
    <>
      <ContainerFormMxp>
        <FormMxp carregando={carregando}>
          <ProvedorAjuda id="edit-form-link-ajuda">
            <input type="hidden" {...register("id")} defaultValue={0} />
            <input
              type="hidden"
              {...register("codigo")}
              defaultValue={props.codigo}
            />
            <FormGrupo>
              <Linha>
                <Coluna md={12}>
                  <FormTextBox
                    name="textoExibicao"
                    titulo="Texto de exibição"
                    toolTip="Texto de exibição"
                    control={control}
                    requerido
                    tamanhoMaximo={
                      LinkAjudaConstantes.TextoExibicaoTamanhoMaximo
                    }
                  />
                </Coluna>
              </Linha>
              <Linha>
                <Coluna md={12}>
                  <FormTextBox
                    name="slug"
                    titulo={linkhome}
                    toolTip={linkhome}
                    control={control}
                    requerido
                    tamanhoMaximo={LinkAjudaConstantes.SlugTamanhoMaximo}
                  />
                </Coluna>
              </Linha>
              <Linha>
                <Coluna md={4}>
                  <FormNumberBox
                    name="ordemExibicao"
                    titulo="Ordem de exibição"
                    toolTip="Ordem de exibição"
                    control={control}
                    requerido
                    minimo={0}
                    maximo={999}
                  />
                </Coluna>
              </Linha>
            </FormGrupo>
          </ProvedorAjuda>
        </FormMxp>
        <ToolbarMxp>
          <BotaoSalvarMxp handleClick={handleSubmit(handleSalvar)} />
          <BotaoCancelarMxp handleClick={handleCancelar} />
        </ToolbarMxp>
      </ContainerFormMxp>
    </>
  );
}
