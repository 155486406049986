import DataGrid, {
  Column,
  DataGridRef,
  DataGridTypes,
} from "devextreme-react/data-grid";
import DataSource from "devextreme/data/data_source";
import {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { renderToStaticMarkup } from "react-dom/server";
import styled from "styled-components";
import ModalAccordion from "../../../../../components/dialogos/modal-accordion";
import { AccordionDataSource } from "../../../../../components/dialogos/modal-accordion/modal-accordion";
import GridColunaAcoes from "../../../../../components/grid-mxp/grid-mxp-coluna-acoes";
import { ModalMxp } from "../../../../../components/layout/modal-mxp";
import ContextoMenuEdicaoOrdemDeProducao from "../../../../../components/ordem-de-producao/contexto-menu-op";
import { NomesMenuEdicaoOrdemDeProducao } from "../../../../../components/ordem-de-producao/menu-edicao-op";
import { LinkButton } from "../../../../../components/templates-celulas-grid/celula-controle-edicao-mxp/styles";
import {
  DadosModalSelecaoInsumosFilhosDoInsumoAlternativo,
  InsumoDaOrdemDeProducaoGridModel,
  InsumoDaOrdemDeProducaoResponseDTO,
  InsumosEstornarEmMassaResponse,
  ObterDescricaoInsumo,
} from "../../../../../features/producao/insumo-da-ordem-de-producao/models/insumo-da-ordem-de-producao";
import { InsumoDaOrdemDeProducaoService } from "../../../../../features/producao/insumo-da-ordem-de-producao/servicos/insumo-da-ordem-de-producao";
import { OrdemDeProducaoEstado } from "../../../../../features/producao/ordem-de-producao/utils/enums/ordem-de-producao-enums";
import { useRegistrarAtalhosGrid } from "../../../../../hooks/atalhos.hooks";
import { useExcluirRegistroGrid } from "../../../../../hooks/grid.hooks";
import { useParametroId } from "../../../../../hooks/route.hooks";
import { useAppDispatch } from "../../../../../hooks/store.hooks";
import { PermissoesInsumosDasOrdensDeProducao } from "../../../../../models/permissoes/producao/insumo-da-ordem-de-producao/InsumoDaOrdemDeProducaoPermissoes";
import { ResultadoAcaoFormulario } from "../../../../../models/shared/ui/formularios";
import { GridMxpProps } from "../../../../../models/shared/ui/grid";
import { bloquearUI, desbloquearUI } from "../../../../../store/ui/ui.slice";
import {
  checarResponseExibeMensagemExclusaoDeSucesso,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import criarNameof from "../../../../../utils/common/cria-name-of";
import NomesModais from "../../../../../utils/common/nomes-modais";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import NormalizaTituloModal from "../../../../../utils/common/normaliza-titulo";
import {
  GeradorMensagensNotificacao,
  JanelasDeNotificacaoTitulos,
} from "../../../../../utils/common/notificacoes-utils";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../utils/common/permissoes-utils";
import { ItemContextMenuMxp } from "../../../../../utils/context-menu/context-menu-utils";
import { GestorEventoClickMultiplasLinhas } from "../../../../../utils/context-menu/gestor-evento-click";
import { exibirAlerta, exibirConfirmacao } from "../../../../../utils/dialogos";
import { formatarNumero } from "../../../../../utils/formatadores/formatador-de-numeros";
import GridBuilder from "../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../utils/grid/grid-controller";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import UrlUtils from "../../../../../utils/url/url-utils";
import { formatarMensagensAcaoAccordion } from "../../../../comum/utils/accordions/gerador-de-mensagens";
import { ModalSelecaoFilhosDoInsumoAlternativo } from "../../../estrutura-de-produto/componentes/modal-selecao-estrutura-insumo-alternativo";
import EstruturaDeProdutoServico from "../../../estrutura-de-produto/servicos/estrutura-de-produto.service";
import {
  InsumoEstado,
  InsumoTipoBaixa,
} from "../../utils/enum/insumo-da-ordem-de-producao-enums";
import { colunasInsumos } from "../colunas-insumos/colunas-insumos";
import ContextoOperacoesInsumo from "../contexto-funcoes-insumo";
import FormInsumoDaOrdemDeProducao from "../formulario";
import { ModalBaixarInsumo } from "../modal-baixar-insumo";
import { ModalEstornarInsumo } from "../modal-estornar-insumo";
import { QuantidadeABaixarEditavelComponent } from "../quantidade-a-baixar-editavel/quantidade-a-baixar-editavel";

const service = new InsumoDaOrdemDeProducaoService();
const estruturaDoProdutoService = new EstruturaDeProdutoServico();
const nameOfGridHandler = criarNameof<InsumoDaOrdemDeProducaoGridModel>();

interface GridEmbutidaInsumoDaOrdemDeProducaoProps
  extends GridMxpProps<InsumoDaOrdemDeProducaoGridModel> {
  ordemDeProducaoId: number;
  ordemDeProducaoEstado: OrdemDeProducaoEstado | undefined;
}

const ItemDaListaEstornoBaixa = styled.li`
  padding: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  min-width: 0;
  color: rgba(0, 0, 0, 0.87);
  border-top: 1px solid #e0e0e0;

  &:hover {
    background-color: whitesmoke;
    border-radius: 4px;
  }
`;

const ListaItensEstornoBaixa = styled.ul`
  max-height: 200px;
  padding-inline-start: 0px;
  list-style-type: none;
  overflow: auto;
`;

const ConfirmarEstornarSelecionados = (
  dataSource: InsumoDaOrdemDeProducaoGridModel[]
) => {
  return (
    <div>
      <p>
        Tem certeza de que deseja estornar as baixas do(s) insumo(s)
        selecionados?
      </p>
      <ListaItensEstornoBaixa>
        {dataSource.map((item) => (
          <ItemDaListaEstornoBaixa key={item.id}>
            {ObterDescricaoInsumo(item)}
          </ItemDaListaEstornoBaixa>
        ))}
      </ListaItensEstornoBaixa>
    </div>
  );
};

const formataDescricao = (data: DataGridTypes.ColumnCellTemplateData) => {
  const dadosLinha: InsumoDaOrdemDeProducaoGridModel = data.row.data;
  if (dadosLinha.tipoDeBaixa == InsumoTipoBaixa.NaoExigeBaixa) {
    return (
      <>
        <div className={`ic-text-cell-with-icon`}>
          <span className={`ic-text-cell`}>{dadosLinha.descricao}</span>
          <span
            className={`ic-column-icon ic-2x ic-material-symbols-outlined ic-block ic-warning`}
            title="O item não exige baixa"
          ></span>
        </div>
      </>
    );
  }

  if (dadosLinha.insumoPaiAlternativo) {
    return (
      <>
        <div className={`ic-text-cell-with-icon`}>
          <span className={`ic-text-cell`}>{dadosLinha.descricao}</span>
          <span
            className={`ic-column-icon ic-2x ic-material-symbols-outlined ic-alt-route ic-warning`}
            title="Insumo selecionado do item alternativo"
          ></span>
        </div>
      </>
    );
  }

  return <>{dadosLinha.descricao}</>;
};

const formataQuantidadeBaixada = (
  data: DataGridTypes.ColumnCellTemplateData
) => {
  const dadosLinha: InsumoDaOrdemDeProducaoGridModel = data.row.data;
  const quantidadeBaixada = formatarNumero(dadosLinha.quantidadeBaixada, 2, 5);

  if (dadosLinha.quantidadeBaixada > dadosLinha.quantidadeTotal) {
    return (
      <>
        {quantidadeBaixada}
        <span
          className={`ic-column-icon ic-2x ic-material-symbols-outlined ic-keyboard-arrow-right ic-warning`}
          title="Estoque baixado acima do necessário."
        ></span>
      </>
    );
  }

  return <>{quantidadeBaixada}</>;
};

function gerarMensagemAccordion(insumos: InsumosEstornarEmMassaResponse[]) {
  const comSucesso = insumos.filter((x) => x.valido);
  const semSucesso = insumos.filter((x) => !x.valido);

  return formatarMensagensAcaoAccordion(comSucesso, semSucesso, {
    acaoSingular: "estornado",
    acaoPlural: "estornados",
    entidadeSingular: "insumo",
    entidadePlural: "insumos",
    formatarEntidade: (entidade) => entidade.item,
  });
}

export default function GridEmbutidaInsumoDaOrdemDeProducao(
  props: GridEmbutidaInsumoDaOrdemDeProducaoProps
) {
  const dispatch = useAppDispatch();
  const gridRef = useRef<DataGridRef>(null);
  const parametroId = useParametroId();

  const [idRegistroEdicao, setIdRegistroEdicao] = useState(NaN);
  const [modalAccordionVisivel, setModalAccordionVisivel] = useState(false);
  const [detalhamentoAccordion, setDetalhamentoAccordion] =
    useState<AccordionDataSource>();

  const [dataSourceInsumoAlternativo, setDataSourceInsumoAlternativo] =
    useState<DataSource | undefined>(undefined);
  const [
    modalSelecaoInsumosFilhosDoAlternativoVisivel,
    setmodalSelecaoInsumosFilhosDoAlternativoVisivel,
  ] = useState(false);
  const [
    dadosModalSelecaoInsumosFilhosDoAlternativo,
    setDadosModalSelecaoInsumosFilhosDoAlternativo,
  ] = useState<DadosModalSelecaoInsumosFilhosDoInsumoAlternativo>();

  const dataSource = service.ObterDataSourceParaGrid(props.filtrosNoServidor);

  const gridController = useMemo(
    () =>
      new GridController<InsumoDaOrdemDeProducaoGridModel>(() =>
        gridRef.current?.instance()
      ),
    []
  );

  const { addItensMenu } = useContext(ContextoMenuEdicaoOrdemDeProducao);

  const { funcoes } = useContext(ContextoOperacoesInsumo);

  const handleAtualizarGrid = useCallback(() => {
    gridController.atualizar();
  }, [gridController]);

  const handleFecharModalAccordion = useCallback(
    (
      setModalAccordionVisivel: (value: React.SetStateAction<boolean>) => void,
      setDetalhamentoModalAccordion: (
        value: React.SetStateAction<AccordionDataSource | undefined>
      ) => void
    ) => {
      setModalAccordionVisivel(false);
      setDetalhamentoModalAccordion(undefined);
    },
    []
  );

  const estornarSelecionados = useCallback(
    async (insumosSelecionados: InsumoDaOrdemDeProducaoGridModel[]) => {
      const htmlConfirmarEstorno = renderToStaticMarkup(
        ConfirmarEstornarSelecionados(insumosSelecionados)
      );
      const confirmar = await exibirConfirmacao(
        JanelasDeNotificacaoTitulos.Atencao,
        htmlConfirmarEstorno
      );

      if (!confirmar) {
        return;
      }

      dispatch(bloquearUI("Estornando..."));

      const resultado = await service.EstornarInsumos(
        insumosSelecionados.map((i) => i.id)
      );

      if (resultado.sucesso) {
        setModalAccordionVisivel(true);
        setDetalhamentoAccordion({
          model: gerarMensagemAccordion(resultado.model),
        });

        handleAtualizarGrid();
      }

      dispatch(desbloquearUI());
    },
    [handleAtualizarGrid, dispatch]
  );

  const menus: ItemContextMenuMxp[] = useMemo(
    () => [
      {
        icon: "ic-material-symbols-outlined ic-category",
        hint: "Menu de insumos",
        text: NomesMenuEdicaoOrdemDeProducao.insumos.name,
        name: NomesMenuEdicaoOrdemDeProducao.insumos.name,
        items: [
          {
            icon: "ic-material-symbols-outlined ic-undo",
            hint: NomesMenuEdicaoOrdemDeProducao.insumos.estornarSelecionados,
            text: NomesMenuEdicaoOrdemDeProducao.insumos.estornarSelecionados,
            name: NomesMenuEdicaoOrdemDeProducao.insumos.estornarSelecionados,
            gestorEventoClick: new GestorEventoClickMultiplasLinhas(
              estornarSelecionados,
              () => gridController
            ),
          },
        ],
      },
    ],
    [gridController, estornarSelecionados]
  );

  useEffect(() => {
    if (parametroId) {
      setIdRegistroEdicao(parametroId);
    }

    if (!gridRef || !gridController) return;

    funcoes.definirAtualizaGridDeInsumos(() => {
      handleAtualizarGrid();
    });

    addItensMenu(menus);
  }, [
    addItensMenu,
    funcoes,
    gridController,
    gridRef,
    handleAtualizarGrid,
    menus,
    parametroId,
  ]);

  const handleNovoRegistro = useCallback(() => {
    if (
      !verificaComNotificacaoSeUsuarioPossuiPermissoes([
        PermissoesInsumosDasOrdensDeProducao.InserirEditar,
      ])
    ) {
      return;
    }

    setIdRegistroEdicao(0);
  }, []);

  const handleEditarRegistro = useCallback(
    (registro: InsumoDaOrdemDeProducaoGridModel) => {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesInsumosDasOrdensDeProducao.InserirEditar,
        ])
      ) {
        return;
      }

      if (registro.estado != InsumoEstado.ABaixar) {
        exibirAlerta(
          JanelasDeNotificacaoTitulos.Atencao,
          "Não é possível editar este insumo, pois ele possui baixas de estoque.<br> Para prosseguir com a edição, estorne as baixas deste insumo e tente novamente."
        );
        return;
      }

      if (props.ordemDeProducaoEstado == OrdemDeProducaoEstado.Cancelada) {
        exibirAlerta(
          JanelasDeNotificacaoTitulos.Atencao,
          "Não é possível editar os insumos desta ordem de produção, pois ela está cancelada."
        );
        return;
      }

      setIdRegistroEdicao(registro.id);
    },
    [props.ordemDeProducaoEstado]
  );

  const handleExcluirRegistro = useExcluirRegistroGrid(
    async (registro: InsumoDaOrdemDeProducaoGridModel) => {
      try {
        if (
          !verificaComNotificacaoSeUsuarioPossuiPermissoes([
            PermissoesInsumosDasOrdensDeProducao.Excluir,
          ])
        ) {
          return;
        }

        const excluir = await exibirConfirmacao(
          "Confirmar exclusão",
          `Tem certeza que deseja excluir o insumo ${ObterDescricaoInsumo(
            registro
          )}?`
        );

        if (excluir) {
          const resposta = await service.Excluir(registro.id);

          if (resposta) {
            checarResponseExibeMensagemExclusaoDeSucesso(
              resposta,
              GeradorMensagensNotificacao.ExcluidoComSucessoMasculino("Insumo")
            );
            handleAtualizarGrid();
          }
        }
      } catch (erro) {
        tratarErroApi(erro);
      }
    }
  );

  const handleFecharModal = useCallback(() => {
    setIdRegistroEdicao(NaN);
    UrlUtils.RemoverParametroId(parametroId);
  }, [parametroId]);

  const handleCallbackFormulario = useCallback(
    (resultado: ResultadoAcaoFormulario) => {
      setIdRegistroEdicao(NaN);

      if (resultado == ResultadoAcaoFormulario.AcaoConcluida) {
        handleAtualizarGrid();
      }

      UrlUtils.RemoverParametroId(parametroId);
    },
    [handleAtualizarGrid, parametroId]
  );

  useRegistrarAtalhosGrid<InsumoDaOrdemDeProducaoGridModel>({
    controller: gridController,
    handleNovo:
      props.ordemDeProducaoEstado != OrdemDeProducaoEstado.AProduzir
        ? handleNovoRegistro
        : undefined,
    handleEditar: handleEditarRegistro,
    handleExcluir: handleExcluirRegistro,
  });

  const botaoNovoVisivel =
    props.ordemDeProducaoEstado === OrdemDeProducaoEstado.AProduzir;

  const abrirModalSelecaoInsumosFilhosDoAlternativo = useCallback(
    async (data: InsumoDaOrdemDeProducaoGridModel) => {
      setmodalSelecaoInsumosFilhosDoAlternativoVisivel(true);

      const quantidadeASelecionarFaltante =
        data.quantidadeASelecionarInsumoAlternativo ?? data.quantidadeTotal;

      const respostaFilhosDoInsumoAlternativo =
        await service.ObterInsumosFilhosPorInsumoAlternativoId<InsumoDaOrdemDeProducaoResponseDTO>(
          data.id
        );

      if (respostaFilhosDoInsumoAlternativo.sucesso) {
        const modelInsumosFilhosDoInsumoAlternativo =
          respostaFilhosDoInsumoAlternativo.model;
        const dataSourceInsumoAlternativo =
          estruturaDoProdutoService.ObterDataSourceDaDaEstruturaDoItemPaiAlternativo(
            data.itemId,
            data.quantidadeTotal,
            quantidadeASelecionarFaltante,
            modelInsumosFilhosDoInsumoAlternativo
          );

        const dadosModalSelecaoInsumoAlternativo: DadosModalSelecaoInsumosFilhosDoInsumoAlternativo =
          {
            insumoPaiId: data.id,
            quantidadeTotalInsumoPai: data.quantidadeTotal,
          };

        setDadosModalSelecaoInsumosFilhosDoAlternativo(
          dadosModalSelecaoInsumoAlternativo
        );
        setDataSourceInsumoAlternativo(dataSourceInsumoAlternativo);
      }
    },
    []
  );

  const fecharModalSelecaoInsumosFilhosDoAlternativo = () => {
    setmodalSelecaoInsumosFilhosDoAlternativoVisivel(false);
    setDataSourceInsumoAlternativo(undefined);
  };

  function gerarBotoesAdicionais(
    data: InsumoDaOrdemDeProducaoGridModel
  ): ReactNode[] {
    const botoesAdicionais: ReactNode[] = [];

    if (data.isProcedenciaAlternativo) {
      botoesAdicionais.push(
        <LinkButton
          key={"btn-selecionar-insumos-filhos-do-alternativo"}
          onClick={() => abrirModalSelecaoInsumosFilhosDoAlternativo(data)}
          title="Abrir modal de seleção de insumos filhos do alternativo"
          className="btn-selecionar-insumos-filhos-do-alternativo"
          isDisabled={data.estado == InsumoEstado.BaixadoCompletamente}
        >
          <i className="ic-material-symbols-outlined ic-alt-route icone-linha-grid"></i>
        </LinkButton>
      );
    }

    return botoesAdicionais;
  }

  const configuracoesGrid = useMemo(() => {
    let configsGrid = GridBuilder.criar(
      "insumo-daordem-de-producao-grid-embutida",
      () => gridRef.current?.instance(),
      false,
      props.filtrosNoCliente
    )
      .definirStyles(props.style)
      .definirDataSource(dataSource)
      .definirFiltros()
      .definirRolagem()
      .definirDuploCliqueLinha(handleEditarRegistro)
      .configurarSelecionadorDeColunas()
      .definirSelecao()
      .definirGravacaoPreferenciasGrid()
      .definirPaginacao()
      .configurarExportacao(NomesTelas.insumosDasOrdensDeProducao)
      .definirBotaoRefresh(handleAtualizarGrid)
      .definirOrdenacao()
      .definirEditavel()
      .definirOperacoesNoLadoDoCliente();

    if (botaoNovoVisivel) {
      configsGrid = configsGrid.definirBotaoNovo(handleNovoRegistro);
    }

    return configsGrid.build();
  }, [
    props.filtrosNoCliente,
    props.style,
    dataSource,
    handleEditarRegistro,
    handleAtualizarGrid,
    botaoNovoVisivel,
    handleNovoRegistro,
  ]);

  return (
    <>
      <DataGrid ref={gridRef} {...configuracoesGrid}>
        <Column {...obterConfiguracaoColuna("colunaDeEspaco")} />
        {GridColunaAcoes<InsumoDaOrdemDeProducaoGridModel>({
          handleEditar: handleEditarRegistro,
          handleExcluir: handleExcluirRegistro,
          gerarBotoesAdicionais: gerarBotoesAdicionais,
        })}
        <Column
          key={nameOfGridHandler("operacao")}
          dataField={nameOfGridHandler("operacao")}
          {...obterConfiguracaoColuna("stringM")}
          allowEditing={false}
          sortOrder="asc"
          sortIndex={1}
          caption="Operação"
        />
        <Column
          key={nameOfGridHandler("descricao")}
          dataField={nameOfGridHandler("descricao")}
          {...obterConfiguracaoColuna("stringM")}
          allowEditing={false}
          width={350}
          caption="Descrição"
          visibleIndex={3}
          cellRender={formataDescricao}
        />
        <Column
          key={nameOfGridHandler("insumoPaiAlternativo")}
          dataField={nameOfGridHandler("insumoPaiAlternativo")}
          {...obterConfiguracaoColuna("stringM")}
          caption="Insumo pai alternativo"
          visibleIndex={4}
          width={350}
          allowEditing={false}
          visible={false}
        />
        <Column
          key={nameOfGridHandler("quantidadeASelecionarInsumoAlternativo")}
          dataField={nameOfGridHandler(
            "quantidadeASelecionarInsumoAlternativo"
          )}
          {...obterConfiguracaoColuna(
            "quantidadeComNoMaximoCincoCasasDecimais"
          )}
          caption="Qt a selecionar"
          visibleIndex={9}
          width={150}
          allowEditing={false}
          visible={false}
        />
        <Column
          key={nameOfGridHandler("quantidadeBaixada")}
          dataField={nameOfGridHandler("quantidadeBaixada")}
          {...obterConfiguracaoColuna(
            "quantidadeComNoMaximoCincoCasasDecimais"
          )}
          allowEditing={false}
          visibleIndex={10}
          caption="Qt baixada"
          cellRender={formataQuantidadeBaixada}
        />
        <Column
          key={nameOfGridHandler("quantidadeABaixar")}
          dataField={nameOfGridHandler("quantidadeABaixar")}
          {...obterConfiguracaoColuna("quantidade")}
          allowEditing={true}
          showEditorAlways={true}
          width={200}
          visibleIndex={11}
          caption="Qt a baixar"
          editCellComponent={QuantidadeABaixarEditavelComponent}
        ></Column>
        {colunasInsumos}
      </DataGrid>

      <ModalBaixarInsumo />

      <ModalEstornarInsumo />

      <ModalAccordion
        modalAccordionVisivel={modalAccordionVisivel}
        handlerFecharModalAccordion={() =>
          handleFecharModalAccordion(
            setModalAccordionVisivel,
            setDetalhamentoAccordion
          )
        }
        dataSource={detalhamentoAccordion?.model}
        modalTitulo={detalhamentoAccordion?.accordionTitulo}
        accordionId="accordion-acao-insumo"
        itemDoAccordionAltura="auto"
      />

      <ModalMxp
        titulo={NormalizaTituloModal.Normalizar(
          idRegistroEdicao,
          NomesModais.insumoDaOrdemDeProducao
        )}
        visivel={!Number.isNaN(idRegistroEdicao)}
        handleFechar={handleFecharModal}
        largura={"max(30vw, 800px)"}
        altura={" auto"}
      >
        <FormInsumoDaOrdemDeProducao
          idRegistroEmEdicao={idRegistroEdicao}
          ordemDeProducaoId={props.ordemDeProducaoId}
          handleCallback={handleCallbackFormulario}
        />
      </ModalMxp>
      {dadosModalSelecaoInsumosFilhosDoAlternativo && (
        <ModalSelecaoFilhosDoInsumoAlternativo
          dados={dataSourceInsumoAlternativo}
          dadosModal={dadosModalSelecaoInsumosFilhosDoAlternativo}
          visivel={modalSelecaoInsumosFilhosDoAlternativoVisivel}
          fecharModal={fecharModalSelecaoInsumosFilhosDoAlternativo}
        />
      )}
    </>
  );
}
