import { useCallback } from "react";
import ImportadorDeRegistros, {
  colunaParaNumero,
  colunaUtcParaData,
  MxpSchemaImportar,
} from "../../../../../components/formularios/importador";
import { InserirEmMassaResponse } from "../../../../../models/api/comum/inserir-varios";
import { ResponseModel } from "../../../../../models/api/comum/response-base";
import {
  quantidadeValorMaximo,
  quantidadeValorMinimo,
} from "../../../../../utils/common/constantes";
import yupPt from "../../../../../utils/validacao/validacao";
import {
  OrdemDeProducaoInserirVariosExcelRequest,
  quantidadeDePerdaPrevistaMaxima,
  quantidadeDePerdaPrevistaMinima,
} from "../../models/ordem-de-producao.api";
import { OrdemDeProducaoService } from "../../servicos/ordem-de-producao.service";

const service = new OrdemDeProducaoService();

const schema: MxpSchemaImportar<OrdemDeProducaoInserirVariosExcelRequest> = {
  "Item *": {
    prop: "codigoItem",
    type: String,
    dataType: "string",
  },
  "Quantidade *": {
    prop: "quantidade",
    type: colunaParaNumero,
    dataType: "number",
  },
  "Necessidade *": {
    prop: "necessidadeData",
    type: colunaUtcParaData,
    dataType: "date",
  },
  "Perda prevista": {
    prop: "quantidadeDePerdaPrevista",
    type: colunaParaNumero,
    dataType: "number",
  },
  Observações: {
    prop: "observacoes",
    type: String,
    dataType: "string",
  },
};

const schemaYup = yupPt.object({
  codigoItem: yupPt.string().required().label("Item"),
  quantidade: yupPt
    .number()
    .required()
    .moreThan(quantidadeValorMinimo.toNumber())
    .lessThan(quantidadeValorMaximo.toNumber())
    .label("Quantidade"),
  necessidadeData: yupPt.date().required().label("Necessidade"),
  quantidadeDePerdaPrevista: yupPt
    .number()
    .notRequired()
    .min(quantidadeDePerdaPrevistaMinima.toNumber())
    .lessThan(quantidadeDePerdaPrevistaMaxima.toNumber())
    .label("Perda prevista"),
});

export interface ModalImportacaoExcelProps {
  visivel: boolean;
  onFechar: () => void;
  onImportar: (resultado: ResponseModel<InserirEmMassaResponse[]>) => void;
}

export default function ModalImportacaoExcel({
  visivel,
  onFechar,
  onImportar,
}: ModalImportacaoExcelProps) {
  const handleImportarExcel = useCallback(
    async (registros: OrdemDeProducaoInserirVariosExcelRequest[]) => {
      const resultado = await service.InserirEmMassaExcel(registros);
      onImportar(resultado);
      return resultado;
    },
    [onImportar]
  );

  return (
    <ImportadorDeRegistros
      titulo="Importar ordens de produção de arquivo Excel"
      onFechar={onFechar}
      nomeDaPlanilha="Ordens de Produção"
      visivel={visivel}
      onImportar={handleImportarExcel}
      modeloUrl={
        "../modelosXlsx/Planilha_Modelo_Ordens_de_Produção_MAXIPROD.xlsx"
      }
      schema={schema}
      schemaYup={schemaYup}
    />
  );
}
