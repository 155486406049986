import getApi from "../../../../configs/api";
import {
  IdStringResponse,
  ListaSimplesResponse,
  ResponseModel,
} from "../../../../models/api/comum/response-base";
import { SelectBoxLazyOpcoesMxp } from "../../../../models/api/comum/selectboxlazy-options";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import { criarDataSourceSelectBoxLazy } from "../../../../utils/api/api-utils";
import criarDatasourceGrid from "../../../../utils/grid/back-end-grid-utils";
import DataSourceFactory, {
  DataSourceFiltragem,
} from "../../../../utils/grid/data-source-factory";
import { TipoValorOpcao, ValorOpcaoGridModel } from "../models/valor-opcao.api";

export default class ValorOpcaoService {
  private _nomeEndpoint: NomesEndpoints;

  constructor() {
    this._nomeEndpoint = NomesEndpoints.ValorOpcao;
  }

  public async ObterListaSimplesDoTiposDeDocumentosFiscaisDoIcmsParaOSped() {
    const response = await getApi().get<ListaSimplesResponse>(
      `${this._nomeEndpoint}/tipos-documentos-fiscais-para-sped/lista-simples`
    );

    return response.data;
  }

  public async obterIdPorCodigoDocumentoFiscal(codigos: string[]) {
    const api = getApi();
    const response = await api.post<ResponseModel<IdStringResponse[]>>(
      `${this._nomeEndpoint}/ObterIdPorCodigoDocumentoFiscal`,
      Array.from(new Set(codigos))
    );

    const map = {} as Record<string, number>;
    if (!response.data.sucesso) {
      return map;
    }
    for (const x of response.data.model) {
      map[x.valor] = x.id;
    }
    return map;
  }

  public ObterGridValorOpcaoConjuntoAssuntoAnotacao(
    tipoValorOpcao: TipoValorOpcao,
    filtroPadrao?: DataSourceFiltragem<ValorOpcaoGridModel>[]
  ) {
    let endpointTipoValorOpcao = "";

    if (tipoValorOpcao == TipoValorOpcao.AcaoAnotacao) {
      endpointTipoValorOpcao = "GridValorOpcaoConjuntoAcaoAnotacao";
    }

    if (tipoValorOpcao == TipoValorOpcao.AssuntoAnotacao) {
      endpointTipoValorOpcao = "GridValorOpcaoConjuntoAssuntoAnotacao";
    }

    return DataSourceFactory.CriarParaGrid<ValorOpcaoGridModel, number>(
      `${this._nomeEndpoint}/${endpointTipoValorOpcao}`,
      filtroPadrao
    );
  }

  public GetDataSourceSelectBoxLazyMxp<T>(
    tipoValorOpcao: TipoValorOpcao,
    opcoes: SelectBoxLazyOpcoesMxp<T>
  ) {
    const camposSelect = JSON.stringify(opcoes.camposRetorno);
    let endpointTipoValorOpcao = "";

    if (tipoValorOpcao == TipoValorOpcao.AcaoAnotacao) {
      endpointTipoValorOpcao = "GridValorOpcaoConjuntoAcaoAnotacao";
    }

    if (tipoValorOpcao == TipoValorOpcao.AssuntoAnotacao) {
      endpointTipoValorOpcao = "GridValorOpcaoConjuntoAssuntoAnotacao";
    }

    const dataSource = criarDatasourceGrid(
      `${this._nomeEndpoint}/${endpointTipoValorOpcao}?select=${camposSelect}`
    );

    return criarDataSourceSelectBoxLazy(dataSource, opcoes);
  }
}
