import { LoadPanel } from "devextreme-react";
import { useAppSelector } from "../../../hooks/store.hooks";
import {
  obterOpcionaisAssinaturaFaltantesFormatada,
  verificaSeUsuarioPossuiOpcionaisAssinatura,
} from "../../../utils/common/opcionais-assinatura-utils";
import {
  obterPermissoesFaltantesFormatada,
  obterPermissoesFormatadas,
  verificaSeUsuarioPossuiPermissoes,
} from "../../../utils/common/permissoes-utils";
import PartialOpcionaisAssinaturaFaltando from "../../dialogos/requer-opcional-assinatura/parts/listagem-opcionais-assinatura-faltantes";
import PartialPermissoesFaltando from "../../dialogos/requer-permissao/parts/listagem-permissoes-faltantes";

interface RequerOpcionalAssinaturaProps {
  codigoPermissoesNaoIncluidas: string[];
  codigoPlano: string[];
  modo?: "todas" | "alguma";
  ignoraSeForMaster?: boolean;
  children: React.ReactNode;
}

export default function RequerOpcionalAssinatura({
  codigoPermissoesNaoIncluidas,
  codigoPlano: codigoOpcional,
  modo = "todas",
  ignoraSeForMaster = false,
  children,
}: RequerOpcionalAssinaturaProps) {
  const master = useAppSelector(
    (state) => state.sessao.dadosSessao?.usuario.isUsuarioMaster
  );
  const carregandoDados = useAppSelector(
    (state) =>
      state.sessao.dadosSessao == null && state.sessao.usuarioAutenticado
  );
  const possuiOpcionais =
    (ignoraSeForMaster && master) ||
    verificaSeUsuarioPossuiOpcionaisAssinatura(codigoOpcional, modo);

  const possuiPermissoes =
    (ignoraSeForMaster && master) ||
    verificaSeUsuarioPossuiPermissoes(codigoPermissoesNaoIncluidas, modo);

  if (possuiOpcionais && possuiPermissoes) {
    return <>{children}</>;
  }

  if (carregandoDados) {
    return <LoadPanel message={"Checando opcionais..."} />;
  }

  if (!possuiOpcionais) {
    const permissoesDescricao = obterPermissoesFormatadas(
      codigoPermissoesNaoIncluidas
    );

    const opcionaisFaltantes =
      obterOpcionaisAssinaturaFaltantesFormatada(codigoOpcional);

    return (
      <PartialOpcionaisAssinaturaFaltando
        permissoesNaoIncluidasFormatadas={permissoesDescricao}
        opcionaisAssinaturaFormatados={opcionaisFaltantes}
        alinhamentoTexto="center"
      />
    );
  }

  if (!possuiPermissoes) {
    const permissoesFaltantes = obterPermissoesFaltantesFormatada(
      codigoPermissoesNaoIncluidas
    );

    return (
      <PartialPermissoesFaltando
        permissoesFormatadas={permissoesFaltantes}
        alinhamentoTexto="center"
      />
    );
  }

  return <>{children}</>;
}
