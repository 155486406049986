import { formatarTemplate } from "../strings/string-utils";

export enum FormatadoresSelectBox {
  /**
   * Formata uma string com dois itens.
   *
   * @param X
   * @param Y
   * @returns Uma string formatada "`X` (`Y`)"
   */
  CodigoDescricaoParenteses = 0,

  /**
   * Formata uma string com dois itens.
   *
   * @param X
   * @param Y
   * @returns Uma string formatada "`X` `Y`"
   */
  CodigoDescricaoEspaco = 1,

  /**
   * Formata uma string com dois itens.
   *
   * @param X
   * @param Y
   * @returns Uma string formatada "`X` - `Y`"
   */
  CodigoDescricaoTraco = 2,

  /**
   * Formata uma string com três itens.
   *
   * @param X
   * @param Y
   * @param Z
   * @returns Uma string formatada "`X` - `Y` `Z`"
   */
  TipoCodigoDescricaoTracoEspaco = 3,

  /**
   * Formata uma string com um item.
   *
   * @param X
   * @returns Uma string formatada "`X`"
   */
  SomenteDescricao = 4,

  /**
   * Formata uma string de item de nota fiscal.
   *
   * @param NumeroDaNotaFiscal
   * @param SequenciaDoItem
   * @param DataEmissao
   * @param CodigoDoItem
   * @param DescricaoDoItem
   * @returns Uma string formatada "`NumeroDaNotaFiscal` #`SequenciaDoItem` (Emissão: `DataEmissao`) - `CodigoDoItem` (`DescricaoDoItem`)."
   */
  ItemNF = 5,

  /**
   * Formata uma string com três itens.
   *
   * @param X
   * @param Y
   * @param Z
   * @returns Uma string formatada "`X` `Y` (`Z`)"
   */
  ClassificacaoCodigoDescricaoEspacoParenteses = 6,

  /**
   * Formata uma string com três itens, com traço e entre parenteses.
   *
   * @param X
   * @param Y
   * @param Z
   * @returns Uma string formatada "`X` - `Y` (`Z`)"
   */
  NumeroTracoCodigoEspacoDescricaoEntreParenteses = 7,
}

export const DicionarioFormatadores: {
  [key in FormatadoresSelectBox]: string;
} = {
  [FormatadoresSelectBox.CodigoDescricaoParenteses]: "{0} ({1})",
  [FormatadoresSelectBox.CodigoDescricaoEspaco]: "{0} {1}",
  [FormatadoresSelectBox.CodigoDescricaoTraco]: "{0} - {1}",
  [FormatadoresSelectBox.TipoCodigoDescricaoTracoEspaco]: "{0} - {1} {2}",
  [FormatadoresSelectBox.SomenteDescricao]: "{0}",
  [FormatadoresSelectBox.ItemNF]: "{0}{1} #{2} {3}{4} - {5} ({6})",
  [FormatadoresSelectBox.ClassificacaoCodigoDescricaoEspacoParenteses]:
    "{0} {1} ({2})",
  [FormatadoresSelectBox.NumeroTracoCodigoEspacoDescricaoEntreParenteses]:
    "{0} - {1} ({2})",
};

export function FormataDescricao(
  formatador: FormatadoresSelectBox,
  ...args: any[]
) {
  return formatarTemplate(DicionarioFormatadores[formatador], ...args);
}
