import { DataGridRef } from "devextreme-react/cjs/data-grid";
import { LoadOptions } from "devextreme/data";
import { ReactNode, useEffect, useState } from "react";
import ModeloDeRelatorioServico from "../../../../sistema/modelo-de-relatorio/servicos/modelo-de-relatorio.servico";
import RelatorioPersonalizadoContexto, {
  DetalhamentoGrid,
  ItemDoMenuDeImpressaoDeRelatorios,
} from "../../contexts/relatorio-personalizado.context";
import { FinalidadeModeloRelatorio } from "../../models/relatorio-personalizado.enums";
import ControleImpressaoRelatoriosPersonalizados from "../controle-impressao";

interface ProvedorRelatorioPersonalizadoProps {
  children: ReactNode;
  finalidade: FinalidadeModeloRelatorio;
}

const service = new ModeloDeRelatorioServico();

function obterDetalhesFiltro(referenciaGrid: DataGridRef | null | undefined) {
  if (!referenciaGrid) {
    return {
      filtros: "",
      quantidadeDeRegistrosFiltrados: 0,
    };
  }

  const loadOptions = referenciaGrid
    ?.instance()
    ?.getDataSource()
    ?.loadOptions() as LoadOptions<any>;

  if (!loadOptions) {
    return {
      filtros: "",
      quantidadeDeRegistrosFiltrados: 0,
    };
  }

  const obj = {
    ...loadOptions,
    filter: referenciaGrid.instance().getCombinedFilter(),
  };

  const loadOptionsUri = Object.entries(obj)
    .filter(([key, val]) => val != null && val != undefined && key != undefined)
    .map(([key, val]) => `${key}=${encodeURIComponent(JSON.stringify(val))}`)
    .join("&");

  const loadOptionsSearchParams = new URLSearchParams(
    loadOptionsUri
  ).toString();

  return {
    filtros: loadOptionsSearchParams,
    quantidadeDeRegistrosFiltrados: referenciaGrid.instance().totalCount(),
  };
}

export default function ProvedorRelatorioPersonalizado(
  props: ProvedorRelatorioPersonalizadoProps
) {
  const [finalidade] = useState<FinalidadeModeloRelatorio>(props.finalidade);
  const [
    modeloDeRelatorioSelecionadoParaImpressao,
    setModeloDeRelatorioSelecionadoParaImpressao,
  ] = useState<ItemDoMenuDeImpressaoDeRelatorios>();
  const [itensDoMenu, setItensDoMenu] = useState<
    ItemDoMenuDeImpressaoDeRelatorios[]
  >([]);
  const [modalConfigurarModeloVisivel, setModalConfigurarModeloVisivel] =
    useState(false);
  const [modalGerarRelatorioVisivel, setModalGerarRelatorioVisivel] =
    useState(false);
  const [modalDicionarioTagsVisivel, setModalDicionarioTagsVisivel] =
    useState(false);
  const [
    idDoRegistroParaImpressaoDoModeloFavorito,
    definirIdDoRegistroParaImpressaoDoModeloFavorito,
  ] = useState(0);
  const [referenciaGrid, setReferenciaGrid] = useState<
    DataGridRef | null | undefined
  >(null);
  const [detalhamentoGrid, setDetalhamentoGrid] = useState<DetalhamentoGrid>({
    filtros: null,
    idsRegistrosSelecionados: [],
    quantidadeRegistrosFiltrados: 0,
  });

  useEffect(() => {
    carregarModelos();
  }, []);

  useEffect(() => {
    if (!modalGerarRelatorioVisivel) {
      return;
    }

    const detalhesFiltro = obterDetalhesFiltro(referenciaGrid);

    setDetalhamentoGrid({
      filtros: detalhesFiltro.filtros,
      quantidadeRegistrosFiltrados:
        detalhesFiltro.quantidadeDeRegistrosFiltrados,
      idsRegistrosSelecionados:
        referenciaGrid?.instance()?.getSelectedRowKeys() ?? [],
    });
  }, [modalGerarRelatorioVisivel]);

  useEffect(() => {
    if (!idDoRegistroParaImpressaoDoModeloFavorito) {
      return;
    }

    const detalhesFiltro = obterDetalhesFiltro(referenciaGrid);

    setDetalhamentoGrid({
      filtros: detalhesFiltro.filtros,
      quantidadeRegistrosFiltrados:
        detalhesFiltro.quantidadeDeRegistrosFiltrados,
      idsRegistrosSelecionados:
        referenciaGrid?.instance()?.getSelectedRowKeys() ?? [],
    });
  }, [idDoRegistroParaImpressaoDoModeloFavorito]);

  const carregarModelos = async () => {
    const resposta = await service.ObterModelosDaFinalidade(finalidade);

    if (resposta.sucesso) {
      setItensDoMenu(
        resposta.model.map((x) => {
          return {
            texto: x.descricao ?? x.nomeArquivoCompleto,
            modeloRelatorioId: x.id,
            modeloPadrao: x.modeloPadrao,
            excel: x.excel,
            word: x.word,
          };
        })
      );
    }
  };

  return (
    <RelatorioPersonalizadoContexto.Provider
      value={{
        finalidade: finalidade,
        itensDoMenuDeImpressaoDeRelatorios: itensDoMenu,
        definirItensDoMenuDeImpressaoDeRelatorios: setItensDoMenu,
        modalConfigurarModeloDeRelatorioVisivel: modalConfigurarModeloVisivel,
        definirModalConfigurarModeloDeRelatorioVisivel:
          setModalConfigurarModeloVisivel,
        modalDicionarioTagsVisivel: modalDicionarioTagsVisivel,
        definirModalDicionarioTagsVisivel: setModalDicionarioTagsVisivel,
        modalImpressaoDoRelatorioVisivel: modalGerarRelatorioVisivel,
        definirModalImpressaoDoRelatorioVisivel: setModalGerarRelatorioVisivel,
        referenciaGrid: referenciaGrid,
        definirReferenciaGrid: setReferenciaGrid,
        modeloDeRelatorioSelecionadoParaImpressao:
          modeloDeRelatorioSelecionadoParaImpressao,
        definirModeloDeRelatorioSelecionadoParaImpressao:
          setModeloDeRelatorioSelecionadoParaImpressao,
        detalhamentoGrid: detalhamentoGrid,
        carregarMenu: carregarModelos,
        idDoRegistroParaImpressaoDoModeloFavorito:
          idDoRegistroParaImpressaoDoModeloFavorito,
        definirIdDoRegistroParaImpressaoDoModeloFavorito:
          definirIdDoRegistroParaImpressaoDoModeloFavorito,
      }}
    >
      {props.children}

      <ControleImpressaoRelatoriosPersonalizados />
    </RelatorioPersonalizadoContexto.Provider>
  );
}
