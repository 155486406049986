import DataGrid, {
  Column,
  DataGridRef,
  DataGridTypes,
} from "devextreme-react/cjs/data-grid";
import DataSource from "devextreme/data/data_source";
import { ReactNode, useCallback, useEffect, useMemo, useRef } from "react";
import GridColunaAcoes from "../../../../../components/grid-mxp/grid-mxp-coluna-acoes";
import { ModalMxp } from "../../../../../components/layout/modal-mxp";
import { LinkButton } from "../../../../../components/templates-celulas-grid/celula-controle-edicao-mxp/styles";
import { useRegistrarAtalhosGrid } from "../../../../../hooks/atalhos.hooks";
import { useControlarFormDeEdicao } from "../../../../../hooks/form.hooks";
import { useMenuDeContextosGrid } from "../../../../../hooks/menus.hooks";
import { useParametroId } from "../../../../../hooks/route.hooks";
import { PermissoesTarefaOuAnotacao } from "../../../../../models/permissoes/crm/tarefa-ou-anoracao/permissoes-tarefa-ou-anotacao";
import { ResultadoAcaoFormulario } from "../../../../../models/shared/ui/formularios";
import { GridMxpProps } from "../../../../../models/shared/ui/grid";
import GetColunasDeAuditoria from "../../../../../parts/layout/grid-defaults/colunasDeAuditoria";
import {
  checarResponseExibeMensagemExclusaoDeSucesso,
  checarResponseExibeMensagemExecutadoComSucesso,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import criarNameof from "../../../../../utils/common/cria-name-of";
import {
  abrirModalMxpAntigo,
  DadosLink,
} from "../../../../../utils/common/menu-utils";
import NomesModais from "../../../../../utils/common/nomes-modais";
import NormalizaTituloModal from "../../../../../utils/common/normaliza-titulo";
import { GeradorMensagensNotificacao } from "../../../../../utils/common/notificacoes-utils";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../utils/common/permissoes-utils";
import { ItemContextMenuMxp } from "../../../../../utils/context-menu/context-menu-utils";
import { exibirConfirmacao } from "../../../../../utils/dialogos";
import GridBuilder from "../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../utils/grid/grid-controller";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import UrlUtils from "../../../../../utils/url/url-utils";
import { OrdemDeProducaoService } from "../../../../producao/ordem-de-producao/servicos/ordem-de-producao.service";
import FormTarefaOuAnotacao from "../../formulario";
import {
  TarefaOuAnotacaoGridModel,
  VinculosTarefaOuAnotacao,
} from "../../models/tarefa-ou-anotacao.api";
import TarefaOuAnotacaoService from "../../servicos/tarefa-ou-anotacao.servico";
import {
  EstadoTarefaOuAnotacao,
  EstadoTarefaOuAnotacaoHelper,
  TipoTarefaOuAnotacao,
  TipoTarefaOuAnotacaoHelper,
  TipoVinculoTarefaOuAnotacao,
  TipoVinculoTarefaOuAnotacaoHelper,
  TipoVinculoTarefaOuAnotacaoParaCarregamentoDataSource,
} from "../../utils/enums/tarefa-ou-anotacao.api.enums";
import { ChipTarefasEAnotacoes } from "../chip";

const service = new TarefaOuAnotacaoService();
const ordemDeProducaoService = new OrdemDeProducaoService();
const nameOfGridHandler = criarNameof<TarefaOuAnotacaoGridModel>();

interface GridEmbutidaTarefaEAnotacoesProps
  extends GridMxpProps<TarefaOuAnotacaoGridModel> {
  tipoVinculoTarefaOuAnotacaoParaCarregamentoDataSource: TipoVinculoTarefaOuAnotacaoParaCarregamentoDataSource;
  ordemDeProducaoId?: number;
  tarefaGeradoraId?: number;
}

function formataEstado(data: DataGridTypes.ColumnCellTemplateData) {
  const dadosLinha: TarefaOuAnotacaoGridModel = data.row.data;

  if (!dadosLinha || !dadosLinha.estado) {
    return;
  }

  return EstadoTarefaOuAnotacaoHelper.getDescricao(dadosLinha.estado);
}

function formataTipo(data: DataGridTypes.ColumnCellTemplateData) {
  const dadosLinha: TarefaOuAnotacaoGridModel = data.row.data;

  if (!dadosLinha || !dadosLinha.tipo) {
    return;
  }

  return TipoTarefaOuAnotacaoHelper.getDescricao(dadosLinha.tipo);
}

function formataVinculadaA(data: DataGridTypes.ColumnCellTemplateData) {
  const dadosLinha: TarefaOuAnotacaoGridModel = data.row.data;
  const tagsEntidades: (JSX.Element | undefined)[] = [];

  if (!dadosLinha) {
    return;
  }

  const vinculos = criaArrayVinculos(dadosLinha);

  vinculos.forEach((vinculo) => {
    tagsEntidades.push(
      criaTagVinculoDaTarefaEAnotacoes(
        vinculo.id,
        vinculo.numero,
        vinculo.nomeDaEntidade,
        vinculo.tipoDaEntidade
      )
    );
  });

  return tagsEntidades;
}

function criaArrayVinculos(data: TarefaOuAnotacaoGridModel) {
  const vinculos: VinculosTarefaOuAnotacao[] = [];

  if (data.vinculoOrdemDeProducao) {
    vinculos.push(data.vinculoOrdemDeProducao);
  }

  if (data.vinculoPropostaDeVenda) {
    vinculos.push(data.vinculoPropostaDeVenda);
  }

  if (data.vinculoPedidoDeVenda) {
    vinculos.push(data.vinculoPedidoDeVenda);
  }

  if (data.vinculoNotaFiscal) {
    vinculos.push(data.vinculoNotaFiscal);
  }

  if (data.vinculoCotacaoDeCompra) {
    vinculos.push(data.vinculoCotacaoDeCompra);
  }

  if (data.vinculoPedidoDeCompra) {
    vinculos.push(data.vinculoPedidoDeCompra);
  }

  if (data.vinculoNotaFiscalRecebida) {
    vinculos.push(data.vinculoNotaFiscalRecebida);
  }

  if (data.vinculoTitulo) {
    vinculos.push(data.vinculoTitulo);
  }

  return vinculos;
}

function criaTagVinculoDaTarefaEAnotacoes(
  id: number,
  numero: number,
  nomeDaEntidade: string,
  tipoDaEntidade: TipoVinculoTarefaOuAnotacao
) {
  const textoChip = `${nomeDaEntidade} ${numero}`;

  //Insere a rota e a função 'NotaFiscal/[entidade]' como padrão pois é a que tem mais ocorrencias
  let rotaMxp1: string = `${TipoVinculoTarefaOuAnotacaoHelper.getDescricao(
    TipoVinculoTarefaOuAnotacao.NotaFiscal
  )}/${TipoVinculoTarefaOuAnotacaoHelper.getDescricao(tipoDaEntidade)}`;
  let funcaoAbrirMxp1: string = `_NotaFiscal_AbrirModalEdicaoAtravesMxp2`;

  if (tipoDaEntidade == TipoVinculoTarefaOuAnotacao.OrdemDeProducao) {
    rotaMxp1 = "";
    funcaoAbrirMxp1 = "";
  } else if (tipoDaEntidade == TipoVinculoTarefaOuAnotacao.NotaFiscal) {
    rotaMxp1 = TipoVinculoTarefaOuAnotacaoHelper.getDescricao(tipoDaEntidade);
  } else if (tipoDaEntidade == TipoVinculoTarefaOuAnotacao.Titulo) {
    rotaMxp1 = TipoVinculoTarefaOuAnotacaoHelper.getDescricao(tipoDaEntidade);
    funcaoAbrirMxp1 = "_Titulo_AbrirModalEdicaoAtravesMxp2";
  }

  const dadosLink: DadosLink = {
    rota: funcaoAbrirMxp1,
    tipo: "javaScriptMxpAntigo",
    paramFunction: () => `(${id.toString()})`,
  };

  return (
    <ChipTarefasEAnotacoes
      key={`${id + nomeDaEntidade}`}
      onClick={() => handleOnClickChip(dadosLink, rotaMxp1)}
      texto={textoChip}
    />
  );
}

function handleOnClickChip(dadosLink: DadosLink, rota: string) {
  const abrirEmNovaAba = true;

  if (!rota || !dadosLink) {
    return false;
  }

  abrirModalMxpAntigo(dadosLink, abrirEmNovaAba, rota);
  return false;
}

export default function GridEmbutidaTarefasEAnotacoes(
  props: GridEmbutidaTarefaEAnotacoesProps
) {
  const gridRef = useRef<DataGridRef>(null);
  const parametroId = useParametroId();

  const {
    idRegistroEmEdicao,
    setIdRegistroEmEdicao,
    modalVisivel,
    encerrarEdicao,
  } = useControlarFormDeEdicao(NaN);

  const dataSource = gerarDataSource(
    props.tipoVinculoTarefaOuAnotacaoParaCarregamentoDataSource
  );

  function gerarDataSource(
    tipo: TipoVinculoTarefaOuAnotacaoParaCarregamentoDataSource
  ) {
    switch (tipo) {
      case TipoVinculoTarefaOuAnotacaoParaCarregamentoDataSource.OrdemDeProducao:
        return ordemDeProducaoService.ObterDataSourceParaGridTarefasEAnotacoes(
          props.ordemDeProducaoId ?? 0
        );
      case TipoVinculoTarefaOuAnotacaoParaCarregamentoDataSource.TarefaOuAnotacaoGeradora:
        return service.ObterDataSourceDaTarefaGeradoraParaGridTarefasEAnotacoes(
          props.tarefaGeradoraId
        );
      default:
        return new DataSource<TarefaOuAnotacaoGridModel, number>([]);
    }
  }

  useEffect(() => {
    if (parametroId) {
      setIdRegistroEmEdicao(parametroId);
    }
  }, []);

  const handleNovoRegistro = useCallback(() => {
    if (
      verificaComNotificacaoSeUsuarioPossuiPermissoes([
        PermissoesTarefaOuAnotacao.InserirEditar,
      ])
    ) {
      setIdRegistroEmEdicao(0);
    }
  }, []);

  const handleEditarRegistro = useCallback(
    (registro: TarefaOuAnotacaoGridModel) => {
      if (
        verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesTarefaOuAnotacao.InserirEditar,
        ])
      ) {
        setIdRegistroEmEdicao(registro.id);
      }
    },
    []
  );

  const handleExcluirRegistro = useCallback(
    async (registro: TarefaOuAnotacaoGridModel) => {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesTarefaOuAnotacao.ConsultarSomenteDoUsuario,
        ])
      ) {
        return;
      }

      const excluir = await exibirConfirmacao(
        "Confirmar exclusão",
        `Tem certeza de que deseja excluir esta tarefa ou anotação?`
      );

      if (excluir) {
        try {
          const resposta = await service.Excluir(registro.id);

          if (resposta) {
            checarResponseExibeMensagemExclusaoDeSucesso(
              resposta,
              GeradorMensagensNotificacao.ExcluidoComSucessoFeminino(
                "Tarefa/anotação"
              )
            );
            handleAtualizarGrid();
          }
        } catch (erro) {
          tratarErroApi(erro);
        }
      }
    },
    []
  );

  const gridController = new GridController<TarefaOuAnotacaoGridModel>(() =>
    gridRef.current?.instance()
  );

  function handleAtualizarGrid() {
    gridController.atualizar();
  }

  const handleCallbackFormulario = useCallback(
    (resultado: ResultadoAcaoFormulario) => {
      encerrarEdicao();

      if (resultado == ResultadoAcaoFormulario.AcaoConcluida) {
        handleAtualizarGrid();
      }

      UrlUtils.RemoverParametroId(parametroId);
    },
    [parametroId]
  );

  const handleFecharModal = useCallback(() => {
    encerrarEdicao();
    UrlUtils.RemoverParametroId(parametroId);
  }, [parametroId]);

  const menusDeContexto: ItemContextMenuMxp[] = [];

  useRegistrarAtalhosGrid({
    controller: gridController,
    handleNovo: handleNovoRegistro,
    handleEditar: handleEditarRegistro,
    handleExcluir: handleExcluirRegistro,
  });

  useMenuDeContextosGrid(menusDeContexto);

  const configuracoesGrid = useMemo(
    () =>
      GridBuilder.criar("tarefas-e-anotacoes", () =>
        gridRef?.current?.instance()
      )
        .definirDataSource(dataSource)
        .definirFiltros()
        .definirRolagem()
        .definirBotaoNovo(handleNovoRegistro)
        .configurarSelecionadorDeColunas()
        .definirGravacaoPreferenciasGrid()
        .definirPaginacao()
        .configurarExportacao("Tarefas e anotações da ordem de produção")
        .definirBotaoRefresh(handleAtualizarGrid)
        .definirOrdenacao()
        .definirMenuDeContexto(menusDeContexto)
        .definirDuploCliqueLinha(handleEditarRegistro)
        .build(),
    [
      dataSource,
      handleNovoRegistro,
      handleAtualizarGrid,
      menusDeContexto,
      handleEditarRegistro,
    ]
  );

  async function finalizarTarefa(idTarefaOuAnotacao: number) {
    const mensagem = "Tem certeza de que deseja finalizar esta tarefa?";

    const cancelar = await exibirConfirmacao("Confirmar finalização", mensagem);

    if (cancelar) {
      service
        .FinalizarTarefaOuAnotacao(idTarefaOuAnotacao)
        .then((resposta) => {
          if (resposta) {
            checarResponseExibeMensagemExecutadoComSucesso(resposta);
            handleAtualizarGrid();
          }
        })
        .catch((erro) => tratarErroApi(erro));
    }
  }

  function abrirModalTarefaGeradora(idTarefaGeradora: number | null) {
    if (!idTarefaGeradora) {
      return;
    }

    if (
      verificaComNotificacaoSeUsuarioPossuiPermissoes([
        PermissoesTarefaOuAnotacao.InserirEditar,
      ])
    ) {
      setIdRegistroEmEdicao(idTarefaGeradora);
    }
  }

  function gerarBotoesAdicionais(data: TarefaOuAnotacaoGridModel): ReactNode[] {
    const botoesAdicionais: ReactNode[] = [];

    if (
      data.tipo == TipoTarefaOuAnotacao.Tarefa &&
      data.estado == EstadoTarefaOuAnotacao.Pendente
    ) {
      botoesAdicionais.push(
        <LinkButton
          key={"btn-finalizar-tarefa-linha"}
          onClick={() => finalizarTarefa(data.id)}
          title="Finalizar tarefa"
          className="btn-finalizar-tarefa-linha"
        >
          <i className="ic-material-symbols-outlined ic-event-avaliable icone-linha-grid"></i>
        </LinkButton>
      );
    }

    if (data.tarefaGeradoraId) {
      botoesAdicionais.push(
        <LinkButton
          key={"btn-abrir-tarefa-geradora"}
          onClick={() => abrirModalTarefaGeradora(data.tarefaGeradoraId)}
          title="Abrir tarefa geradora"
          className="btn-abrir-tarefa-geradora-linha"
        >
          <i className="ic-material-symbols-outlined ic-assignment-return icone-linha-grid"></i>
        </LinkButton>
      );
    }

    return botoesAdicionais;
  }

  return (
    <>
      <DataGrid ref={gridRef} {...configuracoesGrid} height={"100%"}>
        <Column {...obterConfiguracaoColuna("colunaDeEspaco")} />
        {GridColunaAcoes<TarefaOuAnotacaoGridModel>({
          handleEditar: handleEditarRegistro,
          handleExcluir: handleExcluirRegistro,
          gerarBotoesAdicionais: gerarBotoesAdicionais,
        })}

        <Column
          key={nameOfGridHandler("tipo")}
          dataField={nameOfGridHandler("tipo")}
          {...obterConfiguracaoColuna("stringM")}
          caption="Tipo"
          cellRender={formataTipo}
          width={100}
        />
        <Column
          key={nameOfGridHandler("estado")}
          dataField={nameOfGridHandler("estado")}
          {...obterConfiguracaoColuna("stringM")}
          caption="Estado"
          cellRender={formataEstado}
          width={100}
        />
        <Column
          key={"vinculadaA"}
          dataField="vinculadaA"
          {...obterConfiguracaoColuna("stringMG")}
          caption="Vinculada a "
          cellRender={formataVinculadaA}
        />
        <Column
          key={nameOfGridHandler("descricao")}
          dataField={nameOfGridHandler("descricao")}
          {...obterConfiguracaoColuna("stringG")}
          caption="Descrição"
        />
        <Column
          key={nameOfGridHandler("dataDaTarefa")}
          dataField={nameOfGridHandler("dataDaTarefa")}
          {...obterConfiguracaoColuna("dataAnoCurtoComHoraMinutoSegundos")}
          caption="Data da tarefa"
          sortOrder="desc"
          sortIndex={0}
          width={150}
        />
        <Column
          key={nameOfGridHandler("responsavelApelido")}
          dataField={nameOfGridHandler("responsavelApelido")}
          {...obterConfiguracaoColuna("stringG")}
          caption="Responsável da tarefa"
        />
        <Column
          key={nameOfGridHandler("contatoNome")}
          dataField={nameOfGridHandler("contatoNome")}
          {...obterConfiguracaoColuna("stringM")}
          caption="Contato"
        />
        <Column
          key={nameOfGridHandler("assuntoDescricao")}
          dataField={nameOfGridHandler("assuntoDescricao")}
          {...obterConfiguracaoColuna("stringM")}
          caption="Assunto"
        />
        <Column
          key={nameOfGridHandler("classeDescricao")}
          dataField={nameOfGridHandler("classeDescricao")}
          {...obterConfiguracaoColuna("stringM")}
          caption="Classes"
        />
        <Column
          key={nameOfGridHandler("finalizadoEm")}
          dataField={nameOfGridHandler("finalizadoEm")}
          {...obterConfiguracaoColuna("dataAnoCurtoComHoraMinutoSegundos")}
          caption="Finalizada em"
          visible={false}
        />
        <Column
          key={nameOfGridHandler("finalizadaPorApelido")}
          dataField={nameOfGridHandler("finalizadaPorApelido")}
          {...obterConfiguracaoColuna("stringM")}
          caption="Finalizada por"
          visible={false}
        />
        {GetColunasDeAuditoria(false, false, [
          { ordenarPor: "criacaoData", ordem: "desc" },
        ])}
      </DataGrid>

      <ModalMxp
        titulo={NormalizaTituloModal.Normalizar(
          idRegistroEmEdicao,
          NomesModais.tarefaOuAnotação,
          true
        )}
        visivel={modalVisivel}
        handleFechar={handleFecharModal}
        largura={"max(60vw, 950px)"}
        altura={"max(60vh, 900px)"}
      >
        <FormTarefaOuAnotacao
          idRegistroEmEdicao={idRegistroEmEdicao}
          tarefaGeradoraId={props.tarefaGeradoraId ?? null}
          ordemDeProducaoId={props.ordemDeProducaoId ?? null}
          handleCallback={handleCallbackFormulario}
        />
      </ModalMxp>
    </>
  );
}
