import { HttpStatusCode } from "axios";
import { createStore } from "devextreme-aspnet-data-nojquery";
import { atualizarToken, getHeaders, getUrl } from "../../configs/api";
import { StringsComum } from "../../features/comum/strings";
import store from "../../store";
import { definirMensagemLogoff } from "../../store/ui/ui.slice";
import { tratarErroApi } from "../api/api-utils";
import { aguardar } from "../common/common-utils";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../common/notificacoes-utils";
import { RemoverSessaoReduxELocalStorage } from "../oauth/oauth-utils";

const getEstadoAtualizacaoToken = () =>
  store.getState().estadoUI.atualizandoToken;

type ArgumentosCreateStore = Parameters<typeof createStore>[0];
type OpcoesDataStore = Omit<
  ArgumentosCreateStore,
  "loadUrl" | "onBeforeSend" | "onAjaxError"
> & {
  urlRelativa: string;
};

export default class DataStoreFactory {
  public static Criar({ urlRelativa, ...opcoes }: OpcoesDataStore) {
    const dataSource = createStore({
      ...opcoes,
      loadUrl: getUrl(urlRelativa),
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.headers = getHeaders();
      },
      onAjaxError: ({ xhr, error }) => {
        // Enum para comparação do erro
        enum serverErrors {
          Locked = 423,
          InternalServerError = 500,
          NetworkConnectTimeoutError = 599,
        }

        // Trata erro na requisição do grid com os valores de erro do servidor
        if (
          xhr.status === HttpStatusCode.Locked &&
          !store.getState().estadoUI.redirecionando
        ) {
          store.dispatch(definirMensagemLogoff(JSON.parse(xhr.response)));
          RemoverSessaoReduxELocalStorage(true);
        } else if (
          xhr.status >= serverErrors.InternalServerError &&
          xhr.status <= serverErrors.NetworkConnectTimeoutError
        ) {
          exibirNotificacaoToast({
            mensagem: StringsComum.falhaCarregarDadosGrade,
            tipo: TipoNotificacao.Erro,
          });
          return;
        } else if (xhr.status === HttpStatusCode.Forbidden) {
          // TODO: Esperando a decisão do que será feito
          // exibirNotificacaoToast({
          //   mensagem: error.toString() ?? StringsComum.falhaCarregarDadosGrade,
          //   tipo: TipoNotificacao.Erro,
          // });
          return;
        } else if (
          xhr.status === HttpStatusCode.Unauthorized &&
          getEstadoAtualizacaoToken() != "ajax-grid"
        ) {
          if (getEstadoAtualizacaoToken() == "ocioso") {
            atualizarToken("ajax-grid", error, false).then(() => {
              dataSource.load();
            });
          } else {
            aguardar(5000).then(() => {
              dataSource.load();
            });
          }
        } else {
          tratarErroApi(error ?? xhr);
        }
      },
    });

    return dataSource;
  }
}
