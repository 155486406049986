import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import ProvedorAjuda from "../../../../../components/ajuda/provedor-ajuda";
import BotaoCancelarMxp from "../../../../../components/botoes/botao-cancelar-mxp";
import BotaoSalvarMxp from "../../../../../components/botoes/botao-salvar-mxp";
import {
  FormCheckBox,
  FormGrupo,
  FormNumberBox,
  FormSelectBox,
  FormTextBox,
} from "../../../../../components/formularios";
import FormTextArea from "../../../../../components/formularios/textarea";
import FormMxp from "../../../../../components/layout/form";
import { ContainerFormMxp } from "../../../../../components/layout/form/styles";
import { Coluna, Linha } from "../../../../../components/layout/grid-system";
import ToolbarMxp from "../../../../../components/layout/toolbar-mxp";
import AuditavelDTO from "../../../../../models/api/comum/auditavel-dto";
import {
  IFormularioEditavelBase,
  ResultadoAcaoFormulario,
} from "../../../../../models/shared/ui/formularios";
import {
  checarResponse,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../utils/common/notificacoes-utils";
import { NUMERO_INTEIRO_FORMATADOR } from "../../../../../utils/formatadores/formatador-de-numeros";
import { CfopRequestDTO, CfopResponseDTO } from "../../models/cfop";
import ConstantesCfop from "../../models/cfop.constantes";
import {
  EntradaSaida,
  TipoCfop,
  TipoInterestadual,
  entradaSaidaAsSelectItem,
  tipoCfopAsSelectItem,
  tipoInterestadualAsSelectItem,
} from "../../models/cfop.enums";
import CfopServico from "../../servicos/cfop.service";

const novoRegistro: CfopRequestDTO = {
  id: 0,
  codigo: null,
  descricao: "",
  interstadual: null,
  entradaSaida: null,
  ativo: true,
  devolucao: false,
  tipo: null,
  descricaoDetalhada: "",
  observacoes: "",
};

const service = new CfopServico();

let dadosAuditoria: AuditavelDTO | undefined = undefined;

export default function EditFormCfop(props: IFormularioEditavelBase) {
  const [carregando, setCarregando] = useState(false);

  const schema = yup.object().shape({
    id: yup.number().required().moreThan(-1).integer(),
    codigo: yup.number().required().typeError("O código tem que ser numérico"),
    descricao: yup
      .string()
      .required()
      .max(ConstantesCfop.Descricao.TamanhoMaximo),
    interstadual: yup
      .mixed<TipoInterestadual>()
      .nullable()
      .transform((v) => (v >= 0 ? v : null))
      .oneOf(
        Object.values(TipoInterestadual).map((x) => x as number),
        "Valor inválido"
      )
      .required(),
    entradaSaida: yup
      .mixed<EntradaSaida>()
      .nullable()
      .transform((v) => (v >= 0 ? v : null))
      .oneOf(
        Object.values(EntradaSaida).map((x) => x as number),
        "Valor inválido"
      )
      .required(),
    tipo: yup
      .mixed<TipoCfop>()
      .nullable()
      .transform((v) => (v >= 0 ? v : null))
      .oneOf(
        Object.values(TipoCfop).map((x) => x as number),
        "Valor inválido"
      )
      .required(),
  });

  const { register, control, handleSubmit, getValues, reset } =
    useForm<CfopRequestDTO>({
      resolver: yupResolver(schema),
    });

  //Hook usado para carregar os dados da tela
  useEffect(() => {
    if (Number.isNaN(props.idRegistroEmEdicao)) {
      return;
    }

    preencherTela();
  }, [props.idRegistroEmEdicao]);

  async function preencherTela() {
    if (props.idRegistroEmEdicao == 0) {
      limparTela();
    } else if (props.idRegistroEmEdicao > 0) {
      await carregarRegistroDoId();
    }
  }

  async function carregarRegistroDoId() {
    try {
      setCarregando(true);
      const resposta =
        await service.ObterPorIdComDadosAuditoria<CfopResponseDTO>(
          props.idRegistroEmEdicao
        );
      checarResponse(resposta);
      dadosAuditoria = resposta.model;
      reset(resposta.model);
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      setCarregando(false);
    }
  }

  function limparTela() {
    dadosAuditoria = undefined;
    reset(novoRegistro);
  }

  async function handleSalvar() {
    setCarregando(true);
    const model = getValues();
    try {
      const resposta =
        props.idRegistroEmEdicao > 0
          ? await service.Atualizar(model)
          : await service.Inserir(model);

      checarResponse(resposta);

      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: resposta.mensagem,
          tipo: TipoNotificacao.Sucesso,
        });
        fechar(ResultadoAcaoFormulario.AcaoConcluida);
      }
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      setCarregando(false);
    }
  }

  function handleCancelar() {
    fechar(ResultadoAcaoFormulario.AcaoCancelada);
  }

  function fechar(resultado: ResultadoAcaoFormulario) {
    limparTela();
    props.handleCallback(resultado);
  }

  return (
    <>
      <ContainerFormMxp>
        <FormMxp carregando={carregando}>
          <ProvedorAjuda id="edit-form-cfop">
            <input type="hidden" {...register("id")} defaultValue={0} />
            <FormGrupo>
              <Linha>
                <Coluna md={2}>
                  <FormNumberBox
                    name="codigo"
                    titulo="Código"
                    control={control}
                    requerido
                    formato={NUMERO_INTEIRO_FORMATADOR}
                    minimo={ConstantesCfop.Codigo.TamanhoMinimo}
                    maximo={ConstantesCfop.Codigo.TamanhoMaximo}
                  />
                </Coluna>
                <Coluna md={7}>
                  <FormTextBox
                    name="descricao"
                    titulo={"Descrição"}
                    requerido
                    tamanhoMaximo={ConstantesCfop.Descricao.TamanhoMaximo}
                    control={control}
                  />
                </Coluna>
                <Coluna md={1} centralizar>
                  <FormCheckBox name="ativo" titulo="Ativo" control={control} />
                </Coluna>
                <Coluna md={2} centralizar>
                  <FormCheckBox
                    name="devolucao"
                    titulo="Devolução"
                    control={control}
                  />
                </Coluna>
              </Linha>
              <Linha>
                <Coluna md={4}>
                  <FormSelectBox
                    name="interstadual"
                    titulo="Interestadual"
                    toolTip="Interestadual"
                    control={control}
                    requerido
                    dataSource={tipoInterestadualAsSelectItem}
                    habilitaBusca
                    tipoBusca="contains"
                  />
                </Coluna>
                <Coluna md={4}>
                  <FormSelectBox
                    name="entradaSaida"
                    titulo="Entrada/Saída"
                    toolTip="Entrada ou saída"
                    control={control}
                    requerido
                    dataSource={entradaSaidaAsSelectItem}
                    habilitaBusca
                    tipoBusca="contains"
                  />
                </Coluna>
                <Coluna md={4}>
                  <FormSelectBox
                    name="tipo"
                    titulo="Tipo"
                    toolTip="Tipo"
                    control={control}
                    requerido
                    dataSource={tipoCfopAsSelectItem}
                    habilitaBusca
                    tipoBusca="contains"
                  />
                </Coluna>
              </Linha>
              <Linha>
                <Coluna md={6}>
                  <FormTextArea
                    name="descricaoDetalhada"
                    titulo={"Descrição detalhada"}
                    valor={""}
                    control={control}
                    height={15}
                  />
                </Coluna>
                <Coluna md={6}>
                  <FormTextArea
                    name="observacoes"
                    titulo={"Observações"}
                    valor={""}
                    control={control}
                    height={15}
                  />
                </Coluna>
              </Linha>
            </FormGrupo>
          </ProvedorAjuda>
        </FormMxp>
        <ToolbarMxp dadosAuditoria={dadosAuditoria}>
          <BotaoSalvarMxp handleClick={handleSubmit(handleSalvar)} />
          <BotaoCancelarMxp handleClick={handleCancelar} />
        </ToolbarMxp>
      </ContainerFormMxp>
    </>
  );
}
