import { yupResolver } from "@hookform/resolvers/yup";
import ArrayStore from "devextreme/data/array_store";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import BotaoCancelarMxp from "../../../../../components/botoes/botao-cancelar-mxp";
import BotaoConfirmarMxp from "../../../../../components/botoes/botao-confirmar-mxp";
import {
  FormGrupo,
  FormNumberBox,
  FormTextBox,
} from "../../../../../components/formularios";
import FormMxp from "../../../../../components/layout/form";
import { Coluna, Linha } from "../../../../../components/layout/grid-system";
import { ModalMxp } from "../../../../../components/layout/modal-mxp";
import ToolbarMxp from "../../../../../components/layout/toolbar-mxp";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../utils/common/notificacoes-utils";
import { exibirConfirmacao } from "../../../../../utils/dialogos";
import {
  formatarNumeroQuantidade,
  obterFormatStringNumero,
} from "../../../../../utils/formatadores/formatador-de-numeros";
import { ModalSelecionarMovimentacoesParaEstornar } from "../../../../estoque/estoque-movimentacao/componentes/modal-selecionar-movimentacoes-para-estornar";
import {
  DadosModalEstornoDeInsumos,
  InsumoDaOrdemDeProducaoGridModel,
  ObterDescricaoInsumo,
} from "../../models/insumo-da-ordem-de-producao";
import { InsumoDaOrdemDeProducaoService } from "../../servicos/insumo-da-ordem-de-producao";
import ContextoOperacoesInsumo from "../contexto-funcoes-insumo";

const APIInsumos = new InsumoDaOrdemDeProducaoService();

const schema = yup.object().shape({
  quantidadeAEstornar: yup.number().required().moreThan(0),
});

export const ModalEstornarInsumo = () => {
  const [insumoEstornar, setInsumoEstornar] = useState<
    InsumoDaOrdemDeProducaoGridModel | undefined
  >(undefined);
  const [dataSourceSelecionarEstoque, setDataSourceSelecionarEstoque] =
    useState<ArrayStore | undefined>(undefined);
  const [carregando, setCarregando] = useState(false);

  const { funcoes } = useContext(ContextoOperacoesInsumo);

  const { control, getValues, handleSubmit, setValue, reset } =
    useForm<DadosModalEstornoDeInsumos>({
      resolver: yupResolver(schema),
    });

  useEffect(() => {
    funcoes.definfirSetRegistroEstornar(
      (insumo: InsumoDaOrdemDeProducaoGridModel) => {
        setInsumoEstornar(insumo);
      }
    );
  }, []);

  useEffect(() => {
    reset();
    setValue("quantidadeAEstornar", insumoEstornar?.quantidadeBaixada ?? 0);
  }, [insumoEstornar, setValue, reset]);

  function fechar() {
    setInsumoEstornar(undefined);
    funcoes.atualizaGridDeInsumos();
  }

  const fecharModal = () => {
    setDataSourceSelecionarEstoque(undefined);

    fechar();
  };

  async function confirmarEstorno() {
    const model = getValues();
    const quantidadeAEstornar = model.quantidadeAEstornar;
    const quantidadeBaixada = insumoEstornar?.quantidadeBaixada ?? 0;

    if (quantidadeAEstornar > quantidadeBaixada) {
      const confirmacao = await exibirConfirmacao(
        "Aviso",
        `A quantidade a estornar (${formatarNumeroQuantidade(
          quantidadeAEstornar
        )}) é maior que a quantidade total 
            já baixada do insumo (${formatarNumeroQuantidade(
              quantidadeBaixada
            )}). 
            Deseja prosseguir com o estorno da quantidade total já baixada?`
      );

      if (!confirmacao) {
        return;
      }
    }
    try {
      setCarregando(true);
      const resposta = await APIInsumos.EstornarInsumo(
        insumoEstornar?.id ?? 0,
        quantidadeAEstornar ?? 0
      );

      if (resposta.sucesso) {
        if (resposta.precisaSelecionarMovs) {
          setDataSourceSelecionarEstoque(
            new ArrayStore({
              data: resposta.movimentacoes,
              key: "id",
            })
          );
        } else {
          exibirNotificacaoToast({
            mensagem: "Insumo estornado com sucesso.",
            tipo: TipoNotificacao.Sucesso,
          });

          fechar();
        }
      }
    } finally {
      setCarregando(false);
    }
  }

  return (
    <>
      <ModalMxp
        titulo={"Estornar baixa de insumo"}
        visivel={insumoEstornar != undefined}
        handleFechar={fechar}
        larguraMaxima={"max(30vw, 400px)"}
      >
        <FormMxp carregando={carregando}>
          <FormGrupo>
            <Linha>
              <Coluna md={12}>
                <FormTextBox
                  name="descricaoInsumo"
                  titulo="Insumo a estornar"
                  control={control}
                  somenteLeitura
                  defaultValue={`${ObterDescricaoInsumo(insumoEstornar)}`}
                  style={{ marginBottom: "10px" }}
                />
              </Coluna>
            </Linha>
            <Linha>
              <Coluna md={12}>
                <FormNumberBox
                  name="quantidadeAEstornar"
                  titulo="Quantidade a estornar"
                  control={control}
                  formato={obterFormatStringNumero(5)}
                  minimo={0}
                />
              </Coluna>
            </Linha>
          </FormGrupo>
        </FormMxp>
        <ToolbarMxp dadosAuditoria={undefined}>
          <BotaoConfirmarMxp handleClick={handleSubmit(confirmarEstorno)} />
          <BotaoCancelarMxp handleClick={fechar} />
        </ToolbarMxp>
      </ModalMxp>
      <ModalSelecionarMovimentacoesParaEstornar
        dados={dataSourceSelecionarEstoque}
        fecharModal={fecharModal}
        mensagens={{
          erro: "Ocorreu um erro ao estornar o insumo.",
          sucesso: "Insumo estornado com sucesso!",
        }}
      />
    </>
  );
};
