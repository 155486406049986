import DataGrid, {
  Column,
  DataGridRef,
  DataGridTypes,
} from "devextreme-react/cjs/data-grid";
import NumberBox, { NumberBoxTypes } from "devextreme-react/cjs/number-box";
import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { GridNumberBoxProps } from "../../../../../../components/templates-celulas-grid/inputs/number-box/number-box-grid.edit";
import { useRegistrarAtalhosGrid } from "../../../../../../hooks/atalhos.hooks";
import { useSeletorGridMxp } from "../../../../../../hooks/seletor.hooks";
import { GridMxpProps } from "../../../../../../models/shared/ui/grid";
import criarNameof from "../../../../../../utils/common/cria-name-of";
import { formatarNumero } from "../../../../../../utils/formatadores/formatador-de-numeros";
import GridBuilder from "../../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../../utils/grid/grid-controller";
import obterConfiguracaoColuna from "../../../../../../utils/grid/padroes-colunas";
import { DadosModalSelecaoInsumosFilhosDoInsumoAlternativo } from "../../../../insumo-da-ordem-de-producao/models/insumo-da-ordem-de-producao";
import { InsumosFilhosDoInsumoAlternativoGridModel } from "../../../models/estrutura-de-produto.api";

const nameOfGridHandler =
  criarNameof<InsumosFilhosDoInsumoAlternativoGridModel>();

interface GridSelecaoInsumosFilhosDoInsumoAlternativoProps
  extends GridMxpProps<InsumosFilhosDoInsumoAlternativoGridModel> {
  dataSource: DataSource | ArrayStore;
  dadosModal: DadosModalSelecaoInsumosFilhosDoInsumoAlternativo;
}

const formataQuantidadePrevista = (
  data: DataGridTypes.ColumnCellTemplateData,
  quantidadeFaltaParaSelecionar: number,
  quantidadeSelecionada: number
) => {
  const dadosLinha: InsumosFilhosDoInsumoAlternativoGridModel = data.row.data;
  const proporcao = dadosLinha.proporcaoParaItemPai ?? 1;
  const quantidadePrevista =
    quantidadeFaltaParaSelecionar * proporcao -
    quantidadeSelecionada * proporcao;

  return <>{formatarNumero(Math.max(quantidadePrevista, 0), 2, 5)}</>;
};

export default function GridSelecaoInsumosFilhosDoInsumoAlternativo(
  props: GridSelecaoInsumosFilhosDoInsumoAlternativoProps
) {
  const gridRef = useRef<DataGridRef>(null);

  useSeletorGridMxp(() => gridRef.current, props.filtrosNoCliente);

  const gridController = useMemo(
    () =>
      new GridController<InsumosFilhosDoInsumoAlternativoGridModel>(() =>
        gridRef.current?.instance()
      ),
    []
  );

  const [quantidadeSelecionada, setQuantidadeSelecionada] = useState(0);

  useEffect(() => {
    // Insere a quantidade selecionada sempre com o valor inteiro, pois em todas as vezes
    // o primeiro registro será preenchido com a quantidade que falta para selecionar
    setQuantidadeSelecionada(props.dadosModal.quantidadeTotalInsumoPai);
  }, [props.dataSource, props.dadosModal.quantidadeTotalInsumoPai]);

  const QuantidadeEditavelComponent = (propsComponente: any) => {
    const onValueChanged = useCallback(
      (e: NumberBoxTypes.ValueChangedEvent) => {
        let novaQuantidade = e.value || 0;
        const proporcao = propsComponente.data.data.proporcaoParaItemPai ?? 1;
        const quantidadeFaltante =
          props.dadosModal.quantidadeTotalInsumoPai * proporcao;
        const quantidadeDisponivel =
          quantidadeFaltante -
          (quantidadeSelecionada * proporcao - e.previousValue);

        // Condições.
        const excedeLimite = novaQuantidade > quantidadeFaltante;
        const excedeDisponivel = novaQuantidade > quantidadeDisponivel;
        const aumentaAlémDoPermitido =
          quantidadeDisponivel - novaQuantidade < 0 &&
          novaQuantidade > e.previousValue;

        if (excedeLimite || excedeDisponivel || aumentaAlémDoPermitido) {
          novaQuantidade = undefined;
        }

        // Aqui dividimos pela proporção pois a quantidade selecionada é com base na
        // quantidade do insumo pai com proporção = 1
        const novaQuantidadeSelecionada =
          novaQuantidade >= 0
            ? novaQuantidade / proporcao +
              (quantidadeSelecionada - e.previousValue / proporcao)
            : quantidadeSelecionada;

        propsComponente.data.setValue(novaQuantidade);
        setQuantidadeSelecionada(novaQuantidadeSelecionada);
      },
      [propsComponente]
    );

    return (
      <NumberBox
        name="quantidadeASelecionar"
        defaultValue={propsComponente.data.value}
        onValueChanged={onValueChanged}
        min={0}
        {...GridNumberBoxProps}
      />
    );
  };

  const handleAtualizarGrid = useCallback(() => {
    gridController.atualizar();
  }, [gridController]);

  useRegistrarAtalhosGrid({
    controller: gridController,
  });

  const configuracoesGrid = useMemo(
    () =>
      GridBuilder.criar("selecao-insumos-filhos-do-insumo-alternativo", () =>
        gridRef?.current?.instance()
      )
        .definirDataSource(props.dataSource)
        .definirRolagem()
        .configurarSelecionadorDeColunas()
        .definirGravacaoPreferenciasGrid()
        .definirPaginacao()
        .definirOrdenacao()
        .definirOperacoesNoLadoDoCliente()
        .definirEditavel()
        .definirBotaoRefresh(handleAtualizarGrid)
        .build(),
    [gridRef, handleAtualizarGrid, props.dataSource]
  );

  return (
    <>
      <p>
        Quantidade prevista:{" "}
        <b>{formatarNumero(props.dadosModal.quantidadeTotalInsumoPai, 2, 5)}</b>
      </p>
      <DataGrid ref={gridRef} {...configuracoesGrid} height={"100%"}>
        <Column
          key={nameOfGridHandler("operacao")}
          dataField={nameOfGridHandler("operacao")}
          {...obterConfiguracaoColuna("stringM")}
          allowEditing={false}
          caption="Operação"
        />
        <Column
          key={nameOfGridHandler("codigo")}
          dataField={nameOfGridHandler("codigo")}
          {...obterConfiguracaoColuna("stringM")}
          allowEditing={false}
          caption="Item"
        />
        <Column
          key={nameOfGridHandler("descricao")}
          dataField={nameOfGridHandler("descricao")}
          {...obterConfiguracaoColuna("stringG")}
          allowEditing={false}
          caption="Descrição"
          width={315}
        />
        <Column
          key={nameOfGridHandler("grupo")}
          dataField={nameOfGridHandler("grupo")}
          {...obterConfiguracaoColuna("stringM")}
          allowEditing={false}
          caption="Grupo"
          visible={false}
        />
        <Column
          key={nameOfGridHandler("procedencia")}
          dataField={nameOfGridHandler("procedencia")}
          {...obterConfiguracaoColuna("stringM")}
          allowEditing={false}
          caption="Procedência"
          visible={false}
          width={85}
        />
        <Column
          key={nameOfGridHandler("ordem")}
          dataField={nameOfGridHandler("ordem")}
          {...obterConfiguracaoColuna("stringP")}
          allowEditing={false}
          caption="Ordem"
          visible={false}
          width={56}
        />
        <Column
          key={nameOfGridHandler("proporcaoParaItemPai")}
          dataField={nameOfGridHandler("proporcaoParaItemPai")}
          {...obterConfiguracaoColuna(
            "quantidadeComNoMaximoCincoCasasDecimais"
          )}
          allowEditing={false}
          caption="Proporção para o item-pai"
          width={180}
        />
        <Column
          key={nameOfGridHandler("quantidadePrevista")}
          dataField={nameOfGridHandler("quantidadePrevista")}
          {...obterConfiguracaoColuna(
            "quantidadeComNoMaximoCincoCasasDecimais"
          )}
          allowEditing={false}
          caption="Qt prevista"
          cellRender={(data) =>
            formataQuantidadePrevista(
              data,
              props.dadosModal.quantidadeTotalInsumoPai,
              quantidadeSelecionada
            )
          }
        />
        <Column
          key={nameOfGridHandler("quantidadeASelecionar")}
          dataField={nameOfGridHandler("quantidadeASelecionar")}
          {...obterConfiguracaoColuna("quantidade")}
          allowEditing={true}
          showEditorAlways={true}
          width={200}
          editCellComponent={QuantidadeEditavelComponent}
        />
        <Column
          key={nameOfGridHandler("unidade")}
          dataField={nameOfGridHandler("unidade")}
          {...obterConfiguracaoColuna("stringM")}
          allowEditing={false}
          caption="Unid"
          width={60}
        />
        <Column
          key={nameOfGridHandler("custoOrcadoUnitario")}
          dataField={nameOfGridHandler("custoOrcadoUnitario")}
          {...obterConfiguracaoColuna(
            "quantidadeComNoMaximoCincoCasasDecimais"
          )}
          allowEditing={false}
          caption="Custo orçado unitário (R$)"
          width={172}
        />
        <Column
          key={nameOfGridHandler("custoOrcadoTotal")}
          dataField={nameOfGridHandler("custoOrcadoTotal")}
          {...obterConfiguracaoColuna(
            "quantidadeComNoMaximoCincoCasasDecimais"
          )}
          allowEditing={false}
          caption="Custo orçado total (R$)"
          width={156}
        />
        <Column
          key={nameOfGridHandler("estado")}
          dataField={nameOfGridHandler("estado")}
          {...obterConfiguracaoColuna("stringM")}
          allowEditing={false}
          caption="Estado"
          visible={false}
          width={76}
        />
        <Column
          key={nameOfGridHandler("estocagemPor")}
          dataField={nameOfGridHandler("estocagemPor")}
          {...obterConfiguracaoColuna("stringM")}
          allowEditing={false}
          caption="Estocagem Por"
          visible={false}
        />
        <Column
          key={nameOfGridHandler("observacoes")}
          dataField={nameOfGridHandler("observacoes")}
          {...obterConfiguracaoColuna("stringG")}
          allowEditing={false}
          caption="Observações"
          visible={false}
        />
      </DataGrid>
    </>
  );
}
