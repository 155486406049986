import { Button } from "devextreme-react";
import ArrayStore from "devextreme/data/array_store";
import { useCallback, useContext, useEffect, useState } from "react";
import BotaoCancelarMxp from "../../../../../components/botoes/botao-cancelar-mxp";
import FormMxp from "../../../../../components/layout/form";
import { ModalMxp } from "../../../../../components/layout/modal-mxp";
import ToolbarMxp from "../../../../../components/layout/toolbar-mxp";
import {
  EstoqueGridModelSelecionar,
  EstoqueSelecionadoParaBaixaDeInsumo,
  EstoqueSituacao,
} from "../../../../../models/api/estoque/estoque";
import { checarResponse } from "../../../../../utils/api/api-utils";
import exibirNotificacaoToast, {
  GeradorMensagensNotificacao,
  JanelasDeNotificacaoTitulos,
  TipoNotificacao,
} from "../../../../../utils/common/notificacoes-utils";
import { exibirAlerta, exibirConfirmacao } from "../../../../../utils/dialogos";
import { formatarNumeroQuantidade } from "../../../../../utils/formatadores/formatador-de-numeros";
import { renderToStringClient } from "../../../../../utils/react/react-utils";
import GridSelecaoEstoque from "../../../../estoque/estoque/componentes/grids/grid-selecao-de-estoque";
import { InsumoDaOrdemDeProducaoService } from "../../servicos/insumo-da-ordem-de-producao";
import ContextoOperacoesInsumo from "../contexto-funcoes-insumo";

const APIInsumos = new InsumoDaOrdemDeProducaoService();

const DatasourceVazia = new ArrayStore({
  data: [],
  key: "id",
});

export interface ModalBaixarInsumosProps {
  popupVisivel: boolean;
  dados: ArrayStore;
  quantidadeParaBaixar: number;
  insumoId: number;
}

export const ConfrimacaoBaixarQuantidadeAcimaDaEsperada = (
  quantidade: number,
  quantidadePrevista: number,
  codigoItem: string,
  unidade: string
) => {
  return (
    <>
      <p>
        A quantidade a movimentar ({formatarNumeroQuantidade(quantidade)}{" "}
        {unidade}) é maior que a quantidade prevista (
        {formatarNumeroQuantidade(quantidadePrevista)} {unidade}) para o insumo{" "}
        &quot;{codigoItem}&quot;.
      </p>
      <p>Deseja continuar?</p>
    </>
  );
};

export const ModalBaixarInsumo = () => {
  const [modalBaixarInsumosProps, setModalBaixarInsumosProps] =
    useState<ModalBaixarInsumosProps | null>(null);
  const [carregando, setCarregando] = useState(false);

  const { funcoes, referenciasGridInsumos } = useContext(
    ContextoOperacoesInsumo
  );

  useEffect(() => {
    funcoes.definirSetModalBaixarInsumosProps((prop) => {
      setModalBaixarInsumosProps(prop);
    });
  }, [
    modalBaixarInsumosProps?.insumoId,
    modalBaixarInsumosProps?.quantidadeParaBaixar,
  ]);

  const acaoAtualizarGrid = useCallback(async () => {
    setCarregando(true);
    const estoquesResponse =
      await APIInsumos.obterEstoquesDisponiveisParaInsumo(
        modalBaixarInsumosProps?.insumoId ?? 0,
        modalBaixarInsumosProps?.quantidadeParaBaixar ?? 0
      );

    if (estoquesResponse.sucesso) {
      const dados = new ArrayStore({
        data: estoquesResponse.model as EstoqueGridModelSelecionar[],
        key: "id",
      });

      setModalBaixarInsumosProps({
        ...modalBaixarInsumosProps,
        dados: dados,
      } as ModalBaixarInsumosProps);
    } else {
      exibirNotificacaoToast({
        mensagem: estoquesResponse.mensagem,
        tipo: TipoNotificacao.Erro,
      });
    }
    setCarregando(false);
  }, [
    modalBaixarInsumosProps?.insumoId,
    modalBaixarInsumosProps?.quantidadeParaBaixar,
  ]);

  const handleFecharModal = () => {
    referenciasGridInsumos.referenciaGridSelecaoEstoqueBaixaInsumo?.current
      ?.instance()
      .cancelEditData();
    funcoes.atualizaGridDeInsumos();
    setModalBaixarInsumosProps(null);
  };

  async function baixarEstoquesSelecionadosSemForcarConclusao() {
    await baixarEstoquesSelecionados(false);
  }

  async function baixarEstoquesSelecionadosForcandoConclusao() {
    await baixarEstoquesSelecionados(true);
  }

  async function baixarEstoquesSelecionados(forcarConclusao: boolean) {
    // Verifica se a referência do grid está disponível
    const gridRef =
      referenciasGridInsumos.referenciaGridSelecaoEstoqueBaixaInsumo?.current;
    if (!gridRef) {
      exibirNotificacaoToast({
        mensagem: "Não foi possível carregar os estoques.",
        tipo: TipoNotificacao.Erro,
      });
      handleFecharModal();
      return;
    }

    if (forcarConclusao) {
      const confirmacao = await exibirConfirmacao(
        JanelasDeNotificacaoTitulos.Atencao,
        "Tem certeza que deseja forçar a conclusão deste insumo?"
      );

      if (!confirmacao) {
        return;
      }
    }

    // Bloqueia a UI e extrai dados do grid
    setCarregando(true);

    const estoquesDisponiveis = gridRef
      .instance()
      .getVisibleRows()
      .map((row) => row.data as EstoqueGridModelSelecionar)
      .filter(
        (data) =>
          data.quantidadeMovimentar > 0 &&
          data.situacaoEstoque !== EstoqueSituacao.Indisponivel
      );

    if (estoquesDisponiveis.length == 0) {
      exibirNotificacaoToast({
        mensagem:
          "É necessário que ao menos um estoque tenha quantidade maior que 0 para fazer a baixa.",
        tipo: TipoNotificacao.Erro,
      });
      return;
    }

    const estoqueParaBaixar = estoquesDisponiveis.map(
      (data) =>
        new EstoqueSelecionadoParaBaixaDeInsumo(
          data.id,
          data.idReserva,
          data.quantidadeMovimentar
        )
    );

    const quantidadeParaBaixarTotal = estoqueParaBaixar.reduce(
      (sum, current) => sum + current.quantidadeParaBaixar,
      0
    );

    const quantidadeEsperadaParaBaixar =
      modalBaixarInsumosProps?.quantidadeParaBaixar ?? 0;
    if (quantidadeParaBaixarTotal > quantidadeEsperadaParaBaixar) {
      const codigoItem = `${estoquesDisponiveis[0].codigoItem} (${estoquesDisponiveis[0].descricaoItem})`;
      const unidade = estoquesDisponiveis[0].unidadeMedidaEngenharia ?? "un";
      const confirmacao = await exibirConfirmacao(
        JanelasDeNotificacaoTitulos.Atencao,
        renderToStringClient(
          ConfrimacaoBaixarQuantidadeAcimaDaEsperada(
            quantidadeParaBaixarTotal,
            quantidadeEsperadaParaBaixar,
            codigoItem,
            unidade
          )
        )
      );

      if (!confirmacao) {
        setCarregando(false);
        return;
      }
    }

    try {
      // Chama a API e processa a resposta
      const resposta = await APIInsumos.BaixarEstoquesSelecionadosParaInsumo(
        modalBaixarInsumosProps?.insumoId ?? NaN,
        estoqueParaBaixar,
        forcarConclusao
      );

      checarResponse(resposta);
      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem:
            GeradorMensagensNotificacao.RealizadoComSucessoMasculino(
              "Baixa de insumo"
            ),
          tipo: TipoNotificacao.Sucesso,
        });
      }
    } catch (error) {
      // Trata erros na chamada da API
      exibirAlerta("Erro", "Não foi possível realizar a operação.");
    } finally {
      // Desbloqueia a UI e fecha o modal independentemente do resultado
      setCarregando(false);
      handleFecharModal();
    }
  }

  return (
    <ModalMxp
      titulo={"Seleção de estoque para baixa de insumos da OP"}
      visivel={modalBaixarInsumosProps?.popupVisivel ?? false}
      altura={"90vh"}
      largura={"90vw"}
      handleFechar={handleFecharModal}
    >
      <FormMxp carregando={carregando}>
        <span style={{ fontSize: "14px" }}>
          Quantidade a baixar na unidade de estoque:
          <b>
            {" " +
              formatarNumeroQuantidade(
                modalBaixarInsumosProps?.quantidadeParaBaixar ?? 0
              )}
          </b>
        </span>
        <GridSelecaoEstoque
          dataSource={modalBaixarInsumosProps?.dados ?? DatasourceVazia}
          acaoAtualizaGrid={acaoAtualizarGrid}
          style={{ height: "calc(100% - 30px)" }}
        ></GridSelecaoEstoque>
      </FormMxp>
      <ToolbarMxp dadosAuditoria={undefined}>
        <Button
          type="success"
          text={"Confirmar"}
          icon={"ic-material-symbols-outlined ic-arrow-download"}
          onClick={baixarEstoquesSelecionadosSemForcarConclusao}
        />
        <Button
          key="btn-forcar-baixa"
          type="success"
          text="Confirmar e forçar conclusão"
          onClick={baixarEstoquesSelecionadosForcandoConclusao}
          icon="ic-material-symbols-outlined ic-task-alt"
        />
        <BotaoCancelarMxp handleClick={handleFecharModal} />
      </ToolbarMxp>
    </ModalMxp>
  );
};
