import { DataGridRef } from "devextreme-react/cjs/data-grid";
import { useContext, useEffect } from "react";
import RelatorioPersonalizadoContexto from "../contexts/relatorio-personalizado.context";

export function useRelatorioPersonalizado(
  referenciaGrid: () => DataGridRef | null | undefined
) {
  const {
    definirReferenciaGrid,
    definirIdDoRegistroParaImpressaoDoModeloFavorito,
  } = useContext(RelatorioPersonalizadoContexto);

  useEffect(() => {
    definirReferenciaGrid(referenciaGrid);
  }, [definirReferenciaGrid, referenciaGrid]);

  return { definirIdDoRegistroParaImpressaoDoModeloFavorito };
}
