import { Button, RadioGroup } from "devextreme-react";
import { RadioGroupTypes } from "devextreme-react/cjs/radio-group";
import { useCallback, useContext, useEffect, useState } from "react";
import BotaoCancelarMxp from "../../../../../components/botoes/botao-cancelar-mxp";
import FormSelectBoxSimples from "../../../../../components/formularios/selectbox-simples";
import FormMxp from "../../../../../components/layout/form";
import { ContainerFormMxp } from "../../../../../components/layout/form/styles";
import { Coluna, Linha } from "../../../../../components/layout/grid-system";
import { ModalMxp } from "../../../../../components/layout/modal-mxp";
import ToolbarMxp from "../../../../../components/layout/toolbar-mxp";
import { useAppDispatch } from "../../../../../hooks/store.hooks";
import { bloquearUI, desbloquearUI } from "../../../../../store/ui/ui.slice";
import {
  checarResponseBaixarArquivo,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../utils/common/notificacoes-utils";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../utils/common/permissoes-utils";
import RelatorioPersonalizadoContexto from "../../../../relatorio/relatorio-personalizado/contexts/relatorio-personalizado.context";
import { ModeloDeRelatorioPermissoes } from "../../../../sistema/modelo-de-relatorio/models/modelo-de-relatorio.enums";
import {
  GerarRelatorioPersonalizadoFavoritoRequestDTO,
  GerarRelatorioPersonalizadoRequestDTO,
} from "../../models/relatorio-personalizado.api";
import {
  DimensionamentoExcel,
  SelectItemEnumDimensionamentoExcel,
} from "../../models/relatorio-personalizado.enums";
import RelatorioPersonalizadoService from "../../servicos/relatorio-personalizado.service";
import VisualizadorDePdf from "../modal-visualizar-pdf";

enum OpcoesParaImpressao {
  UtilizarRegistrosSelecionados = 1,
  UtilizarRegistrosFiltrados = 2,
}

interface ArquivoPdf {
  arquivo: Blob;
  nome: string;
}

const relatorioPersonalizadoService = new RelatorioPersonalizadoService();

let arquivoPdf: ArquivoPdf | undefined = undefined;

export default function ModalGerarRelatorioPersonalizado() {
  const {
    finalidade,
    modalImpressaoDoRelatorioVisivel,
    definirModalImpressaoDoRelatorioVisivel,
    modeloDeRelatorioSelecionadoParaImpressao,
    detalhamentoGrid,
    idDoRegistroParaImpressaoDoModeloFavorito,
    definirIdDoRegistroParaImpressaoDoModeloFavorito,
  } = useContext(RelatorioPersonalizadoContexto);

  const [dimensionamentoExcel, setDimensionamentoExcel] =
    useState<DimensionamentoExcel>(DimensionamentoExcel.SemDimensionamento);
  const [opcaoDeImpressao, setOpcaoDeImpressao] = useState<OpcoesParaImpressao>(
    OpcoesParaImpressao.UtilizarRegistrosSelecionados
  );
  const [carregando, setCarregando] = useState(false);
  const [visualizadorPdfVisivel, setVisualizadorPdfVisivel] = useState(false);

  useEffect(() => {
    if (!modalImpressaoDoRelatorioVisivel) {
      setOpcaoDeImpressao(OpcoesParaImpressao.UtilizarRegistrosSelecionados);
    }
  }, [modalImpressaoDoRelatorioVisivel]);

  useEffect(() => {
    if (idDoRegistroParaImpressaoDoModeloFavorito < 1) {
      return;
    }

    visualizarPdfFavorito();
  }, [idDoRegistroParaImpressaoDoModeloFavorito]);

  const dispatch = useAppDispatch();

  const handleFecharCancelar = useCallback(() => {
    definirModalImpressaoDoRelatorioVisivel(false);
  }, []);

  const changeOpcaoDeImpressao = useCallback(
    (e: RadioGroupTypes.ValueChangedEvent) => {
      setOpcaoDeImpressao(e.value);
    },
    []
  );

  function validacoes() {
    if (
      opcaoDeImpressao == OpcoesParaImpressao.UtilizarRegistrosSelecionados &&
      detalhamentoGrid.idsRegistrosSelecionados.length == 0
    ) {
      exibirNotificacaoToast({
        tipo: TipoNotificacao.Advertencia,
        mensagem: "Selecione ao menos um registro.",
      });

      return false;
    }

    if (
      opcaoDeImpressao == OpcoesParaImpressao.UtilizarRegistrosFiltrados &&
      detalhamentoGrid.quantidadeRegistrosFiltrados == 0
    ) {
      exibirNotificacaoToast({
        tipo: TipoNotificacao.Advertencia,
        mensagem: "Nenhum registro filtrado.",
      });

      return false;
    }

    return true;
  }

  function obterArquivoDeRequest(gerarComoPdf: boolean) {
    const request: GerarRelatorioPersonalizadoRequestDTO = {
      filtroGrid:
        opcaoDeImpressao == OpcoesParaImpressao.UtilizarRegistrosFiltrados
          ? detalhamentoGrid.filtros
          : null,
      ids:
        opcaoDeImpressao == OpcoesParaImpressao.UtilizarRegistrosSelecionados
          ? detalhamentoGrid.idsRegistrosSelecionados?.join(";")
          : null,
      relatorioId:
        modeloDeRelatorioSelecionadoParaImpressao?.modeloRelatorioId ?? 0,
      pdf: gerarComoPdf,
      dimensionamento: modeloDeRelatorioSelecionadoParaImpressao?.excel
        ? dimensionamentoExcel
        : null,
    };

    return request;
  }

  function obterArquivoDeRequestFavorito() {
    const request: GerarRelatorioPersonalizadoFavoritoRequestDTO = {
      finalidade: finalidade!,
      ids: idDoRegistroParaImpressaoDoModeloFavorito.toString(),
    };

    return request;
  }

  async function handleDownload() {
    if (!validacoes()) {
      return;
    }

    if (
      modeloDeRelatorioSelecionadoParaImpressao?.excel &&
      !verificaComNotificacaoSeUsuarioPossuiPermissoes([
        ModeloDeRelatorioPermissoes.BaixarRelatorioEmExcel,
      ])
    ) {
      return;
    }

    if (
      modeloDeRelatorioSelecionadoParaImpressao?.word &&
      !verificaComNotificacaoSeUsuarioPossuiPermissoes([
        ModeloDeRelatorioPermissoes.BaixarRelatorioEmWord,
      ])
    ) {
      return;
    }

    try {
      const request = obterArquivoDeRequest(false);

      setCarregando(true);

      const { response, nomeArquivo, extensao } =
        await relatorioPersonalizadoService.gerarRelatorio(request);

      const sucesso = await checarResponseBaixarArquivo(
        response,
        nomeArquivo ?? "relatório",
        extensao
      );

      if (sucesso) {
        exibirNotificacaoToast({
          mensagem: "Relatório gerado com sucesso.",
          tipo: TipoNotificacao.Sucesso,
        });
        handleFecharCancelar();
      }
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      setCarregando(false);
    }
  }

  async function visualizarPdf() {
    try {
      setCarregando(true);

      if (!validacoes()) {
        return;
      }

      const request = obterArquivoDeRequest(true);

      const { response, nomeArquivo, extensao } =
        await relatorioPersonalizadoService.gerarRelatorio(request);

      arquivoPdf = {
        arquivo: response as Blob,
        nome: `${nomeArquivo}.${extensao}`,
      };

      setVisualizadorPdfVisivel(true);
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      setCarregando(false);
    }
  }

  async function visualizarPdfFavorito() {
    try {
      dispatch(bloquearUI("Gerando relatório..."));

      if (idDoRegistroParaImpressaoDoModeloFavorito == 0) {
        exibirNotificacaoToast({
          tipo: TipoNotificacao.Advertencia,
          mensagem: "Selecione ao menos um registro.",
        });

        return;
      }

      const request = obterArquivoDeRequestFavorito();

      const { response, nomeArquivo, extensao } =
        await relatorioPersonalizadoService.gerarRelatorioFavorito(request);

      arquivoPdf = {
        arquivo: response as Blob,
        nome: `${nomeArquivo}.${extensao}`,
      };

      setVisualizadorPdfVisivel(true);
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      dispatch(desbloquearUI());
    }
  }

  return (
    <>
      <ModalMxp
        titulo={"Gerar relatório"}
        visivel={modalImpressaoDoRelatorioVisivel}
        handleFechar={handleFecharCancelar}
        largura={"max(38vw, 600px)"}
      >
        <ContainerFormMxp>
          <FormMxp carregando={carregando}>
            <Linha>
              <Coluna md={12}>
                <RadioGroup
                  items={[
                    {
                      id: OpcoesParaImpressao.UtilizarRegistrosSelecionados,
                      text: `Utilizar registros selecionados (registros: ${detalhamentoGrid.idsRegistrosSelecionados.length})`,
                    },
                    {
                      id: OpcoesParaImpressao.UtilizarRegistrosFiltrados,
                      text: `Utilizar registros filtrados (registros: ${detalhamentoGrid.quantidadeRegistrosFiltrados})`,
                    },
                  ]}
                  value={opcaoDeImpressao}
                  onValueChanged={changeOpcaoDeImpressao}
                  valueExpr="id"
                  displayExpr="text"
                />
              </Coluna>
            </Linha>
            <div
              style={{
                display: modeloDeRelatorioSelecionadoParaImpressao?.excel
                  ? "block"
                  : "none",
              }}
            >
              <Linha>
                <Coluna md={6}>
                  <FormSelectBoxSimples
                    titulo="Opções de dimensionamento"
                    dataSource={SelectItemEnumDimensionamentoExcel}
                    valor={dimensionamentoExcel}
                    onValueChange={(e) => setDimensionamentoExcel(e)}
                  />
                </Coluna>
              </Linha>
            </div>

            <div
              style={{
                border: "1px solid",
                backgroundColor: "rgb(252, 248, 227)",
                padding: "0.5em",
                color: "#8a6d3b",
                marginTop: "5px",
                marginBottom: "15px",
              }}
            >
              <i className="fa-solid fa-triangle-exclamation"></i> Gerar
              relatórios com consultas complexas ou com muitos registros pode
              resultar em demora para finalizar a ação.
            </div>
          </FormMxp>
          <ToolbarMxp>
            <Button
              type={"success"}
              text={"Visualizar PDF"}
              icon={"ic-material-symbols-outlined ic-print"}
              onClick={visualizarPdf}
            />
            <Button
              type={"success"}
              text={"Download"}
              icon={"ic-material-symbols-outlined ic-file-save"}
              onClick={handleDownload}
            />
            <BotaoCancelarMxp handleClick={handleFecharCancelar} />
          </ToolbarMxp>
        </ContainerFormMxp>
      </ModalMxp>

      <VisualizadorDePdf
        visivel={visualizadorPdfVisivel}
        handleFechar={() => {
          setVisualizadorPdfVisivel(false);
          definirIdDoRegistroParaImpressaoDoModeloFavorito(0);
        }}
        arquivo={arquivoPdf?.arquivo}
        titulo={arquivoPdf?.nome}
      />
    </>
  );
}
