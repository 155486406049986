import { DataGridTypes } from "devextreme-react/cjs/data-grid";
import NumberBox, { NumberBoxTypes } from "devextreme-react/cjs/number-box";
import ArrayStore from "devextreme/data/array_store";
import { custom } from "devextreme/ui/dialog";
import { useCallback, useContext } from "react";
import styled from "styled-components";
import { LinkButton } from "../../../../../components/templates-celulas-grid/celula-controle-edicao/styles";
import { GridNumberBoxProps } from "../../../../../components/templates-celulas-grid/inputs/number-box/number-box-grid.edit";
import { useAppDispatch } from "../../../../../hooks/store.hooks";
import { bloquearUI, desbloquearUI } from "../../../../../store/ui/ui.slice";
import exibirNotificacaoToast, {
  GeradorMensagensNotificacao,
  JanelasDeNotificacaoTitulos,
  TipoNotificacao,
} from "../../../../../utils/common/notificacoes-utils";
import { exibirConfirmacao } from "../../../../../utils/dialogos";
import { formatarNumeroQuantidade } from "../../../../../utils/formatadores/formatador-de-numeros";
import { renderToStringClient } from "../../../../../utils/react/react-utils";
import {
  InsumoDaOrdemDeProducaoGridModel,
  ObterDescricaoInsumo,
} from "../../models/insumo-da-ordem-de-producao";
import { InsumoDaOrdemDeProducaoService } from "../../servicos/insumo-da-ordem-de-producao";
import { InsumoEstado } from "../../utils/enum/insumo-da-ordem-de-producao-enums";
import ContextoOperacoesInsumo from "../contexto-funcoes-insumo";
import { ConfrimacaoBaixarQuantidadeAcimaDaEsperada } from "../modal-baixar-insumo";

const APIInsumos = new InsumoDaOrdemDeProducaoService();
const focarBaixaTrue = true;
const forcarBaixaFalse = false;

const mensagemBaixaParcial = (
  quantidadeABaixar: number,
  unidade: string,
  codigoDescricao: string
) => {
  return (
    <div style={{ maxWidth: "50vw" }}>
      <p>
        Você está baixando uma quantidade parcial de{" "}
        {formatarNumeroQuantidade(quantidadeABaixar)} {unidade} do item &quot;
        {codigoDescricao}&quot;.
      </p>
      <p>
        Ao clicar em &quot;Baixar&quot;, a quantidade informada será baixada.
      </p>
      <p>
        Ao clicar em &quot;Baixar e forçar a conclusão do insumo&quot;, a
        quantidade informada será baixada, e a quantidade restante do insumo
        será concluída forçadamente. Logo, a quantidade restante que não foi
        informada não movimentará o estoque.
      </p>
      <p>O que deseja fazer?</p>
    </div>
  );
};

const mensagemBaixarInsumosTerceirosNaoTotalmenteReservados = () => {
  return (
    <>
      <p>
        Este insumo é fornecido pelo cliente, mas a quantidade desta ordem de
        produção não está totalmente reservada para pedidos de um único cliente.
      </p>

      <p>Tem certeza de que deseja continuar com a baixa deste insumo?</p>

      <p>
        Esta verificação pode ser desativada em &quot;Configurações &gt;
        Planejamento e produção &gt;{" "}
        <b>
          Permitir a baixa de insumos de terceiros para OP que não estiver
          totalmente reservada para pedidos deste terceiro
        </b>
        &quot;.
      </p>
    </>
  );
};

const insumoNaoEstaBaixadoCompletamente = (
  data: DataGridTypes.ColumnEditCellTemplateData<
    InsumoDaOrdemDeProducaoGridModel,
    number
  >
) => {
  return (
    data.data != undefined &&
    data.data.estado != InsumoEstado.BaixadoCompletamente
  );
};

const ColunaBaixarInsumo = styled.div`
  display: flex;
  align-items: center;
  margin-block: -5px;
`;

export const QuantidadeABaixarEditavelComponent = ({
  data,
}: {
  data: DataGridTypes.ColumnEditCellTemplateData<
    InsumoDaOrdemDeProducaoGridModel,
    number
  >;
}) => {
  const onValueChanged = useCallback((e: NumberBoxTypes.ValueChangedEvent) => {
    data.setValue(e.value);
  }, []);

  const dispatch = useAppDispatch();

  const {
    setRegistroEstornar,
    setModalBaixarInsumosProps,
    atualizaGridDeInsumos,
  } = useContext(ContextoOperacoesInsumo).funcoes;

  function getDados() {
    return data.data;
  }

  function getDadosAtualizados() {
    const colunaEditada = data.component
      .getVisibleRows()
      .filter((x) => x.key == data.data?.id)[0];
    const colunaEditataValor = colunaEditada.data;

    // Cancelar edicao da celula mantendo o valor antigo
    const colunaEditadaAntiga = (colunaEditada as any).oldData;
    if (colunaEditadaAntiga) {
      colunaEditadaAntiga.quantidadeABaixar =
        colunaEditataValor.quantidadeABaixar;
      data.component.closeEditCell();
      data.component.cancelEditData();
    }

    return colunaEditataValor;
  }

  const onClickBaixarInsumo = useCallback(() => {
    confirmarBaixarInsumos(getDadosAtualizados());
  }, [data]);

  const onClickForcarBaixaInsumo = useCallback(async () => {
    const confirmacao = await exibirConfirmacao(
      JanelasDeNotificacaoTitulos.Atencao,
      "Tem certeza que deseja forçar a conclusão deste insumo?"
    );

    if (!confirmacao) {
      return;
    }

    forcarBaixaInsumos(getDadosAtualizados());
  }, [data]);

  const onClickEstornarBaixaInsumo = useCallback(() => {
    estornarInsumo(getDados());
  }, [data]);

  async function forcarBaixaInsumos(dados?: InsumoDaOrdemDeProducaoGridModel) {
    if (!dados) {
      exibirNotificacaoToast({
        mensagem:
          "Não foi possivel encontrar o insumo selecionado para forçar a baixa.",
        tipo: TipoNotificacao.Erro,
      });
      return;
    }
    try {
      dispatch(bloquearUI("Carregando..."));
      await APIInsumos.ForcarConclusaoDeInsumo(dados?.id);
      atualizaGridDeInsumos();
    } finally {
      dispatch(desbloquearUI());
    }
  }

  async function estornarInsumo(dados?: InsumoDaOrdemDeProducaoGridModel) {
    if (!dados) {
      exibirNotificacaoToast({
        mensagem:
          "Não foi possivel encontrar o insumo selecionado para estornar.",
        tipo: TipoNotificacao.Erro,
      });
      return;
    }

    setRegistroEstornar(dados);
  }

  async function BaixarInsumos(
    dados: InsumoDaOrdemDeProducaoGridModel,
    forcar: boolean
  ) {
    if (forcar) {
      const confirmacao = await exibirConfirmacao(
        JanelasDeNotificacaoTitulos.Atencao,
        "Tem certeza que deseja forçar a conclusão deste insumo?"
      );

      if (!confirmacao) {
        return;
      }
    }

    try {
      dispatch(bloquearUI("Carregando..."));

      const permiteBaixarInsumosTerceirosNaoTotalmenteReservados =
        await APIInsumos.VerificarSePermiteBaixarInsumosTerceirosNaoTotalmenteReservados(
          dados.id
        );

      if (permiteBaixarInsumosTerceirosNaoTotalmenteReservados == undefined) {
        return;
      }

      if (permiteBaixarInsumosTerceirosNaoTotalmenteReservados == false) {
        const confirmacao = await exibirConfirmacao(
          JanelasDeNotificacaoTitulos.Atencao,
          renderToStringClient(
            mensagemBaixarInsumosTerceirosNaoTotalmenteReservados()
          )
        );

        if (!confirmacao) {
          return;
        }
      }

      const resultado = await APIInsumos.BaixaAutomatica(
        dados.id,
        dados.quantidadeABaixar,
        forcar
      );

      if (!resultado.sucesso) {
        return;
      }

      if (resultado.model.estoquesParaBaixar.length > 0) {
        setModalBaixarInsumosProps({
          popupVisivel: true,
          dados: new ArrayStore({
            data: resultado.model.estoquesParaBaixar,
            key: "id",
          }),
          quantidadeParaBaixar: dados.quantidadeABaixar,
          insumoId: dados.id,
        });
      } else {
        if (resultado.model.quantidadeBaixada <= 0) {
          exibirNotificacaoToast({
            mensagem:
              "Não foi encontrado nenhum estoque disponível para baixar.",
            tipo: TipoNotificacao.Erro,
          });
          return;
        }

        exibirNotificacaoToast({
          mensagem:
            GeradorMensagensNotificacao.RealizadoComSucessoMasculino(
              "Baixa de insumo"
            ),
          tipo: TipoNotificacao.Sucesso,
        });
        atualizaGridDeInsumos();
      }
    } finally {
      dispatch(desbloquearUI());
    }
  }

  async function confirmarBaixarInsumos(
    dados?: InsumoDaOrdemDeProducaoGridModel
  ) {
    if (!dados) {
      exibirNotificacaoToast({
        mensagem: "Não foi possível carregar o insumo selecionado.",
        tipo: TipoNotificacao.Erro,
      });
      return;
    }

    const quantidadePrevista = dados.quantidadeTotal - dados.quantidadeBaixada;
    const quantidadeABaixar = dados.quantidadeABaixar;
    const unidade = dados.unidade ?? "un";
    const codigoDescricao = `${ObterDescricaoInsumo(dados)}`;

    if (quantidadeABaixar == 0) {
      exibirNotificacaoToast({
        mensagem: "A quantidade a baixar deve ser maior que zero.",
        tipo: TipoNotificacao.Advertencia,
      });
      return;
    }

    if (quantidadeABaixar < quantidadePrevista) {
      const mensagem = renderToStringClient(
        mensagemBaixaParcial(quantidadeABaixar, unidade, codigoDescricao)
      );

      const confirm = custom({
        title: "Confirmar baixa parcial",
        messageHtml: mensagem,
        buttons: [
          {
            text: "Baixar",
            onClick: () => BaixarInsumos(dados, forcarBaixaFalse),
          },
          {
            text: "Baixar e forçar a conclusão do insumo",
            onClick: () => BaixarInsumos(dados, focarBaixaTrue),
          },
          { text: "Cancelar" },
        ],
      });

      confirm.show();
      return;
    }

    if (quantidadeABaixar > quantidadePrevista) {
      const confirmacao = await exibirConfirmacao(
        JanelasDeNotificacaoTitulos.Atencao,
        renderToStringClient(
          ConfrimacaoBaixarQuantidadeAcimaDaEsperada(
            quantidadeABaixar,
            quantidadePrevista,
            codigoDescricao,
            unidade
          )
        )
      );

      if (!confirmacao) {
        return;
      }
    }

    BaixarInsumos(dados, forcarBaixaFalse);
  }

  return (
    <ColunaBaixarInsumo>
      {insumoNaoEstaBaixadoCompletamente(data) && (
        <NumberBox
          name="quantidadeABaixar"
          defaultValue={data.value}
          onValueChanged={onValueChanged}
          min={0}
          {...GridNumberBoxProps}
        />
      )}
      {!insumoNaoEstaBaixadoCompletamente(data) && (
        <div
          style={{
            width: "100%",
          }}
        ></div>
      )}
      <div style={{ width: "64px", display: "flex", flex: "none" }}>
        {insumoNaoEstaBaixadoCompletamente(data) && (
          <LinkButton onClick={onClickBaixarInsumo} title="Baixar Insumo">
            <i className="ic-material-symbols-outlined ic-success ic-arrow-download icone-linha-grid"></i>
          </LinkButton>
        )}
        {insumoNaoEstaBaixadoCompletamente(data) && (
          <LinkButton
            onClick={onClickForcarBaixaInsumo}
            title="Força a baixa do insumo"
          >
            <i className="ic-material-symbols-outlined ic-task-alt icone-linha-grid"></i>
          </LinkButton>
        )}
        {data.data != undefined && data.data.estado != InsumoEstado.ABaixar && (
          <LinkButton
            onClick={onClickEstornarBaixaInsumo}
            title="Estornar Insumo"
          >
            <i className="ic-material-symbols-outlined ic-undo icone-linha-grid"></i>
          </LinkButton>
        )}
      </div>
    </ColunaBaixarInsumo>
  );
};
