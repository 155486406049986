import getApi from "../../../../configs/api";
import {
  ResponseBase,
  ResponseModel,
} from "../../../../models/api/comum/response-base";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import DataSourceFactory from "../../../../utils/grid/data-source-factory";
import {
  LinhaApuracaoDoInformativoDaComposicaoDeCustosDaEcfEditarRequest,
  LinhaApuracaoDoInformativoDaComposicaoDeCustosDaEcfGridModel,
  LinhaApuracaoDoInformativoDaComposicaoDeCustosDaEcfResponseDTO,
} from "../models/linha-apuracao-informativo-composicao-custos-ecf";

export default class LinhaApuracaoDoInformativoDaComposicaoDeCustosDaEcfService {
  protected _nomeEndpoint: NomesEndpoints;
  constructor() {
    this._nomeEndpoint =
      NomesEndpoints.LinhaApuracaoDoInformativoDaComposicaoDeCustosDaEcf;
  }

  public async ObterPorIdComDadosAuditoria(id: number) {
    const response = await getApi().get<
      ResponseModel<LinhaApuracaoDoInformativoDaComposicaoDeCustosDaEcfResponseDTO>
    >(`${this._nomeEndpoint}/ObterPorIdComDadosAuditoria?id=${id}`);

    return response.data;
  }

  public async Editar(
    model: LinhaApuracaoDoInformativoDaComposicaoDeCustosDaEcfEditarRequest
  ) {
    const response = await getApi().put<ResponseBase>(
      `${this._nomeEndpoint}`,
      model
    );

    return response.data;
  }

  public async TornarValorLivre(id: number) {
    const response = await getApi().patch<ResponseBase>(
      `${this._nomeEndpoint}/TornarValorLivre?id=${id}`
    );

    return response.data;
  }

  public GetGridSourceDaApuracao(apuracaoId: number) {
    return DataSourceFactory.CriarParaGrid<LinhaApuracaoDoInformativoDaComposicaoDeCustosDaEcfGridModel>(
      `${this._nomeEndpoint}/grid?apuracaoId=${apuracaoId}`
    );
  }
}
