import { yupResolver } from "@hookform/resolvers/yup";
import { CheckBox } from "devextreme-react";
import { forwardRef, useImperativeHandle, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { FormSelectBox } from "../../../../../components/formularios";
import { Coluna, Linha } from "../../../../../components/layout/grid-system";
import TabContainer from "../../../../../components/layout/tab-container";
import { TipoUnidadeTransporte } from "../../../../../features/vendas/veiculo/models/veiculo.enums";
import { useCarregarDadosDoModelo } from "../../../../../hooks/form.hooks";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../hooks/store.hooks";
import { TipoMDFeVeiculo } from "../../../../../models/api/mdfe/mdfe-enums";
import { PermissoesConjuntoVeiculo } from "../../../../../models/permissoes/vendas/conjunto-veiculo/permissoes-conjunto-veiculo";
import { PermissoesVeiculo } from "../../../../../models/permissoes/vendas/veiculo/permissoes-veiculo";
import { IFormulario } from "../../../../../models/shared/ui/formularios";
import SelectItem from "../../../../../models/shared/ui/select-item";
import { MDFeAbaVeiculoViewModel } from "../../../../../models/viewmodels/vendas/mdfe/mdfe-edit-form-view-model";
import APIConjuntoVeiculo from "../../../../../services/conjunto-veiculo/conjunto-veiculo.service";
import APIVeiculo from "../../../../../services/veiculo/veiculo.service";
import { definirDadosVeiculos } from "../../../../../store/mdfe/mdfe.slice";
import {
  checarResponse,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import { checarSeFormFoiModificado } from "../../../../../utils/common/form-utils";
import { verificaSeUsuarioPossuiPermissoes } from "../../../../../utils/common/permissoes-utils";
import "./index.scss";

interface DadosVeiculosProps {
  idRegistro: number;
  abaSomenteLeitura: boolean;
}

async function carregarVeiculosTracao() {
  try {
    if (!verificaSeUsuarioPossuiPermissoes([PermissoesVeiculo.Consultar])) {
      return [];
    }

    const resposta = await APIVeiculo.obterListaSimples([
      TipoUnidadeTransporte.RodoviarioTracao,
    ]);
    checarResponse(resposta);
    return resposta.model.map((x) => ({
      valor: x.valor,
      descricao: x.descricao,
    }));
  } catch (erro) {
    tratarErroApi(erro);
  }
  return [];
}

async function carregarVeiculosReboque() {
  try {
    if (!verificaSeUsuarioPossuiPermissoes([PermissoesVeiculo.Consultar])) {
      return [];
    }

    const resposta = await APIVeiculo.obterListaSimples([
      TipoUnidadeTransporte.RodoviarioReboque,
      TipoUnidadeTransporte.Outros,
    ]);
    checarResponse(resposta);
    return resposta.model.map((x) => ({
      valor: x.valor,
      descricao: x.descricao,
    }));
  } catch (erro) {
    tratarErroApi(erro);
  }
  return [];
}

async function carregarConjutosVeiculo() {
  try {
    if (
      !verificaSeUsuarioPossuiPermissoes([PermissoesConjuntoVeiculo.Consultar])
    ) {
      return [];
    }

    const resposta = await APIConjuntoVeiculo.obterListaSimples();
    checarResponse(resposta);
    return resposta.model.map((x) => ({
      valor: x.valor,
      descricao: x.descricao,
    }));
  } catch (erro) {
    tratarErroApi(erro);
  }
  return [];
}

// Cria um componente referenciável
export const MDFeAbaDadosVeiculos = forwardRef(
  ({ idRegistro, abaSomenteLeitura }: DadosVeiculosProps, ref) => {
    const model: MDFeAbaVeiculoViewModel = useAppSelector((state) => {
      const doc = state.mdfe.documentoAtual.modalRodoviario;
      const rodoviariosReboque = doc.veiculos.filter(
        (x) => x.tipo === TipoMDFeVeiculo.Reboque
      );
      const idVeiculoReboque1 =
        rodoviariosReboque.length > 0 ? rodoviariosReboque[0].idVeiculo : null;
      const idReboque1 =
        rodoviariosReboque.length > 0 ? rodoviariosReboque[0].id : null;
      const idVeiculoReboque2 =
        rodoviariosReboque.length > 1 ? rodoviariosReboque[1].idVeiculo : null;
      const idReboque2 =
        rodoviariosReboque.length > 1 ? rodoviariosReboque[1].id : null;
      const idVeiculoReboque3 =
        rodoviariosReboque.length > 2 ? rodoviariosReboque[2].idVeiculo : null;
      const idReboque3 =
        rodoviariosReboque.length > 2 ? rodoviariosReboque[2].id : null;
      const idVeiculoTracao =
        doc.veiculos.find((x) => x.tipo == TipoMDFeVeiculo.Tracao)?.idVeiculo ??
        0;
      const idTracao =
        doc.veiculos.find((x) => x.tipo == TipoMDFeVeiculo.Tracao)?.id ?? 0;
      return {
        id: doc.id,
        inicializarComDadosConjuntoVeiculo: false,
        idConjuntoVeiculo: null,
        idTracao: idTracao,
        idVeiculoTracao: idVeiculoTracao,
        idVeiculoReboque1: idVeiculoReboque1,
        idReboque1: idReboque1,
        idVeiculoReboque2: idVeiculoReboque2,
        idReboque2: idReboque2,
        idVeiculoReboque3: idVeiculoReboque3,
        idReboque3: idReboque3,
      };
    });

    const [conjuntosVeiculo, setConjuntoVeiculo] = useState<SelectItem[]>([]);
    const [veiculosTracao, setVeiculoTracao] = useState<SelectItem[]>([]);
    const [veiculosReboque, setVeiculoReboque] = useState<SelectItem[]>([]);
    const [
      inicializarComDadosConjuntoVeiculo,
      setInicializarComDadosConjuntoVeiculo,
    ] = useState(false);

    useCarregarDadosDoModelo(idRegistro, carregarTela);

    async function carregarTela() {
      reset(model);
      setVeiculoTracao(await carregarVeiculosTracao());
      setVeiculoReboque(await carregarVeiculosReboque());
      setConjuntoVeiculo(await carregarConjutosVeiculo());
    }

    const dispatch = useAppDispatch();

    const schema = yup.object().shape({
      id: yup.number().required().moreThan(-1).integer(),
      idConjuntoVeiculo: yup
        .number()
        .positive()
        .integer()
        .transform((v) => (v ? v : null))
        .nullable()
        .oneOf(
          conjuntosVeiculo.map((x) => x.valor as number),
          "Campo inválido"
        ),
      idVeiculoTracao: yup
        .number()
        .required()
        .positive()
        .integer()
        .transform((v) => (v ? v : null))
        .oneOf(
          veiculosTracao.map((x) => x.valor as number),
          "Campo inválido"
        ),
      idTracao: yup.number().integer().required(),
      idVeiculoReboque1: yup
        .number()
        .positive()
        .integer()
        .nullable()
        .transform((v) => (v ? v : null))
        .oneOf(
          veiculosReboque.map((x) => x.valor as number),
          "Campo inválido"
        )
        .test(
          "reboque1IgualAo2Tests",
          "Campo igual ao reboque 2",
          function (value) {
            if (!value) {
              return true;
            }
            return this.parent.idVeiculoReboque2 != value;
          }
        )
        .test(
          "reboque1IgualAo3Tests",
          "Campo igual ao reboque 3",
          function (value) {
            if (!value) {
              return true;
            }
            return this.parent.idVeiculoReboque3 != value;
          }
        ),
      idReboque1: yup.number().integer().nullable(),
      idVeiculoReboque2: yup
        .number()
        .positive()
        .integer()
        .transform((v) => (v ? v : null))
        .nullable()
        .oneOf(
          veiculosReboque.map((x) => x.valor as number),
          "Campo inválido"
        )
        .test(
          "reboque2IgualAo1Tests",
          "Campo igual ao reboque 1",
          function (value) {
            if (!value) {
              return true;
            }
            return this.parent.idVeiculoReboque1 != value;
          }
        )
        .test(
          "reboque2IgualAo3Tests",
          "Campo igual ao reboque 3",
          function (value) {
            if (!value) {
              return true;
            }
            return this.parent.idVeiculoReboque3 != value;
          }
        ),
      idReboque2: yup.number().integer().nullable(),
      idVeiculoReboque3: yup
        .number()
        .positive()
        .integer()
        .transform((v) => (v ? v : null))
        .nullable()
        .oneOf(
          veiculosReboque.map((x) => x.valor as number),
          "Campo inválido"
        )
        .test(
          "reboque3IgualAo1Tests",
          "Campo igual ao reboque 1",
          function (value) {
            if (!value) {
              return true;
            }

            return this.parent.idVeiculoReboque1 != value;
          }
        )
        .test(
          "reboque3IgualAo2Tests",
          "Campo igual ao reboque 2",
          function (value) {
            if (!value) {
              return true;
            }
            return this.parent.idVeiculoReboque2 != value;
          }
        ),
      idReboque3: yup.number().integer().nullable(),
    });

    const onInicializarComDadosConjuntoVeiculoChanged = () => {
      if (inicializarComDadosConjuntoVeiculo) {
        setValue("idConjuntoVeiculo", null);
        onConjuntosVeiculoChanged(null);
      }

      setInicializarComDadosConjuntoVeiculo(
        !inicializarComDadosConjuntoVeiculo
      );
    };

    async function onConjuntosVeiculoChanged(value: number | null) {
      if (value == null) {
        return;
      }

      const resposta = await APIConjuntoVeiculo.obterPorId(value);
      checarResponse(resposta);
      const dados = resposta.model;
      setTracaoReboquesConformeConjuntosVeiculo(
        dados.idVeiculoTracao,
        dados.idVeiculoReboque1,
        dados.idVeiculoReboque2,
        dados.idVeiculoReboque3
      );

      await trigger();
    }

    async function setTracaoReboquesConformeConjuntosVeiculo(
      idVeiculoTracao: number,
      idVeiculoReboque1: number,
      idVeiculoReboque2?: number | null,
      idVeiculoReboque3?: number | null
    ) {
      setValue("idVeiculoTracao", idVeiculoTracao);
      setValue("idVeiculoReboque1", idVeiculoReboque1);
      setValue("idVeiculoReboque2", idVeiculoReboque2 ?? null);
      setValue("idVeiculoReboque3", idVeiculoReboque3 ?? null);
    }

    const {
      setValue,
      trigger,
      reset,
      register,
      control,
      formState,
      handleSubmit,
    } = useForm<MDFeAbaVeiculoViewModel>({
      mode: "onChange",
      reValidateMode: "onChange",
      resolver: yupResolver(schema),
    });

    let form: HTMLFormElement | null;

    function handleSubmitInterno(data: MDFeAbaVeiculoViewModel) {
      dispatch(definirDadosVeiculos(data));
    }

    // Repassar referências para componente pai
    useImperativeHandle(
      ref,
      (): IFormulario => ({
        requestSubmit() {
          form?.requestSubmit();
        },
        valido() {
          form?.requestSubmit();
          return Object.keys(formState.errors).length == 0;
        },
        isDirty() {
          return checarSeFormFoiModificado(formState);
        },
      })
    );

    return (
      <TabContainer>
        <form
          ref={(ref) => (form = ref)}
          onSubmit={handleSubmit(handleSubmitInterno)}
        >
          <input type="hidden" {...register("idTracao")} defaultValue={0} />
          <input type="hidden" {...register("idReboque1")} defaultValue={0} />
          <input type="hidden" {...register("idReboque2")} defaultValue={0} />
          <input type="hidden" {...register("idReboque3")} defaultValue={0} />

          <Linha>
            <Coluna md={6} classe="centralizar-itens">
              <CheckBox
                name="inicializarComDadaosConjuntoVeiculo"
                text="Inicializar com os dados do conjunto de veículos"
                onValueChanged={onInicializarComDadosConjuntoVeiculoChanged}
                readOnly={abaSomenteLeitura}
              />
            </Coluna>
            <Coluna md={6}>
              <FormSelectBox
                name="idConjuntoVeiculo"
                titulo="Conjunto de veículos"
                control={control}
                dataSource={conjuntosVeiculo}
                habilitaBusca
                tipoBusca="contains"
                onValueChange={onConjuntosVeiculoChanged}
                somenteLeitura={
                  abaSomenteLeitura || !inicializarComDadosConjuntoVeiculo
                }
                permissoesNecessarias={[PermissoesConjuntoVeiculo.Consultar]}
              />
            </Coluna>
          </Linha>
          <div className="container-veiculo">
            <Linha>
              <Coluna md={6}>
                <FormSelectBox
                  name="idVeiculoTracao"
                  titulo="Tração"
                  control={control}
                  dataSource={veiculosTracao}
                  habilitaBusca
                  tipoBusca="contains"
                  requerido={true}
                  somenteLeitura={abaSomenteLeitura}
                  permissoesNecessarias={[PermissoesVeiculo.Consultar]}
                />
              </Coluna>
              <Coluna md={6}>
                <FormSelectBox
                  name="idVeiculoReboque1"
                  titulo="Reboque 1"
                  control={control}
                  dataSource={veiculosReboque}
                  habilitaBusca
                  tipoBusca="contains"
                  somenteLeitura={abaSomenteLeitura}
                  permissoesNecessarias={[PermissoesVeiculo.Consultar]}
                />
              </Coluna>
            </Linha>
            <Linha>
              <Coluna md={6} />
              <Coluna md={6}>
                <FormSelectBox
                  name="idVeiculoReboque2"
                  titulo="Reboque 2"
                  control={control}
                  dataSource={veiculosReboque}
                  habilitaBusca
                  tipoBusca="contains"
                  somenteLeitura={abaSomenteLeitura}
                  permissoesNecessarias={[PermissoesVeiculo.Consultar]}
                />
              </Coluna>
            </Linha>
            <Linha>
              <Coluna md={6} />
              <Coluna md={6}>
                <FormSelectBox
                  name="idVeiculoReboque3"
                  titulo="Reboque 3"
                  control={control}
                  dataSource={veiculosReboque}
                  habilitaBusca
                  tipoBusca="contains"
                  somenteLeitura={abaSomenteLeitura}
                  permissoesNecessarias={[PermissoesVeiculo.Consultar]}
                />
              </Coluna>
            </Linha>
          </div>
        </form>
      </TabContainer>
    );
  }
);

export default MDFeAbaDadosVeiculos;
