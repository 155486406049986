import DataGrid, { Column, DataGridRef } from "devextreme-react/cjs/data-grid";
import { useMemo, useRef } from "react";
import ProvedorAjuda from "../../../../../../components/ajuda/provedor-ajuda";
import { useSeletorGridMxp } from "../../../../../../hooks/seletor.hooks";
import GetColunasDeAuditoria from "../../../../../../parts/layout/grid-defaults/colunasDeAuditoria";
import criarNameof from "../../../../../../utils/common/cria-name-of";
import NomesTelas from "../../../../../../utils/common/nomes-telas";
import GridBuilder from "../../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../../utils/grid/grid-controller";
import obterConfiguracaoColuna from "../../../../../../utils/grid/padroes-colunas";
import { AdicaoExclusaoCompensacaoLalurGridModel } from "../../models/adicao-exclusao-compensacao-lalur-irpj";
import AdicaoExclusaoCompensacaoLalurIrpjService from "../../servicos/adicao-exclusao-compensacao-lalur-irpj.service";

const service = new AdicaoExclusaoCompensacaoLalurIrpjService();
const dataSource = service.ObterDataSourceParaGrid();
const nameOfGridHandler =
  criarNameof<AdicaoExclusaoCompensacaoLalurGridModel>();

export default function GridAdicaoExclusaoCompensacaoLalur() {
  const gridRef = useRef<DataGridRef>(null);

  useSeletorGridMxp(() => gridRef.current);

  const gridController =
    new GridController<AdicaoExclusaoCompensacaoLalurGridModel>(() =>
      gridRef.current?.instance()
    );

  const configuracoesGrid = useMemo(() => {
    return GridBuilder.criar("adicao-exclusao-compensacao-lalur", () =>
      gridRef.current?.instance()
    )
      .definirStyles({ height: "100%" })
      .definirDataSource(dataSource)
      .definirFiltros()
      .definirRolagem()
      .configurarSelecionadorDeColunas()
      .definirGravacaoPreferenciasGrid()
      .configurarExportacao(NomesTelas.adicaoExclusaoCompensacaoLalurIrpj)
      .definirPaginacao()
      .definirSelecao()
      .definirBotaoRefresh(handleAtualizarGrid)
      .definirOrdenacao()
      .build();
  }, []);

  function handleAtualizarGrid() {
    gridController.atualizar();
  }

  return (
    <>
      <ProvedorAjuda id={"adicao-exclusao-compensacao-lalur"}>
        <DataGrid ref={gridRef} {...configuracoesGrid}>
          <Column
            key={nameOfGridHandler("codigo")}
            dataField={nameOfGridHandler("codigo")}
            {...obterConfiguracaoColuna("stringG")}
            caption="Código"
          />
          <Column
            key={nameOfGridHandler("descricao")}
            dataField={nameOfGridHandler("descricao")}
            {...obterConfiguracaoColuna("stringGG")}
            caption="Descrição"
          />
          <Column
            key={nameOfGridHandler("tipoDecodificado")}
            dataField={nameOfGridHandler("tipoDecodificado")}
            {...obterConfiguracaoColuna("stringG")}
            caption="Tipo"
          />
          {GetColunasDeAuditoria()},
        </DataGrid>
      </ProvedorAjuda>
    </>
  );
}
