import criarNameof from "../../../../utils/common/cria-name-of";
import { DataSourceFiltragem } from "../../../../utils/grid/data-source-factory";
import { ContaDaParteBGridModel } from "./conta-parte-b";
import { TipoTributoContaParteB } from "./conta-parte-b.enums";

export default class FiltrosGridContaParteB {
  static readonly ativo: any[] = [
    criarNameof<ContaDaParteBGridModel>()("ativo"),
    "=",
    true,
  ];

  static readonly SomenteCsll: DataSourceFiltragem<ContaDaParteBGridModel>[] = [
    {
      campo: "tipoTributo",
      operador: "=",
      valor: TipoTributoContaParteB.CSLL,
    },
  ];

  static readonly SomenteIrpj: DataSourceFiltragem<ContaDaParteBGridModel>[] = [
    {
      campo: "tipoTributo",
      operador: "=",
      valor: TipoTributoContaParteB.IRPJ,
    },
  ];
}
