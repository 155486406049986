import { Column } from "devextreme-react/data-grid";
import ArrayStore from "devextreme/data/array_store";
import {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { MxpGrid } from "../../../../../components/grid";
import { Coluna, Linha } from "../../../../../components/layout/grid-system";
import TabContainer from "../../../../../components/layout/tab-container";
import { Seletor } from "../../../../../components/selecao/seletor";
import { GridSelecaoCondutor } from "../../../../../features/sistema/usuario/componentes/grid-selecao-condutor";
import CondutorSelecaoGridModel from "../../../../../features/sistema/usuario/models/usuario";
import { usePropagarReferenciaGrid } from "../../../../../hooks/grid.hooks";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../hooks/store.hooks";
import { PermissoesUsuario } from "../../../../../models/permissoes/seguranca/usuario/permissoes-usuario";
import {
  IFormulario,
  IGridSelecao,
} from "../../../../../models/shared/ui/formularios";
import { GridCondutorViewModel } from "../../../../../models/viewmodels/vendas/mdfe/mdfe-edit-form-view-model";
import {
  adicionarCondutor,
  removerCondutor,
} from "../../../../../store/mdfe/mdfe.slice";
import criarNameof from "../../../../../utils/common/cria-name-of";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import exibirNotificacaoToast, {
  MensagensParaNotificacao,
  TipoNotificacao,
} from "../../../../../utils/common/notificacoes-utils";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../utils/common/permissoes-utils";
import { exibirConfirmacao } from "../../../../../utils/dialogos";
import { GridProps } from "../../../../../utils/grid/grid-utils";
import { renderToStringClient } from "../../../../../utils/react/react-utils";

interface DadosCondutorProps extends GridProps {
  idRegistro: number;
}

const gridId = "mdfe-lista-condutores";
const nameof = criarNameof<GridCondutorViewModel>();

const colunas = [
  <Column
    key={nameof("nome")}
    dataField={nameof("nome")}
    caption="Nome"
    allowResizing
    width={150}
  />,
  <Column
    key={nameof("cpfCnpj")}
    dataField={nameof("cpfCnpj")}
    caption="CPF"
    allowResizing
    width={150}
  />,
];

export const MDFeAbaCondutor = forwardRef((props: DadosCondutorProps, ref) => {
  const [seletorVisivel, setSeletorVisivel] = useState(false);

  const condutores = useAppSelector((state) => {
    const listaCondutores: GridCondutorViewModel[] = [];

    for (const condutor of state.mdfe.documentoAtual.modalRodoviario
      ?.condutores ?? []) {
      listaCondutores.push({
        id: condutor.id,
        cpfCnpj: condutor.condutor?.cpfCnpj,
        nome: condutor.condutor?.nome,
        apelido: condutor.condutor?.apelido,
        idCondutor: condutor.idCondutor,
      });
    }
    return listaCondutores;
  });

  const dataSourceCondutores = useMemo(
    () => new ArrayStore({ data: condutores, key: "Id" }),
    [condutores]
  );

  const gridRef = useRef<IGridSelecao>(null);
  usePropagarReferenciaGrid(ref, gridRef);

  function novoRegistro() {
    if (
      !verificaComNotificacaoSeUsuarioPossuiPermissoes([
        PermissoesUsuario.Consultar,
      ])
    ) {
      return;
    }

    setSeletorVisivel(true);
  }

  const dispatch = useAppDispatch();

  function adicionarSelecaoCondutores(dados: CondutorSelecaoGridModel[]) {
    if (
      !VerificaSeQuantidadeMaximaCondutoresEstaNoPermitido(
        condutores?.length ?? 0,
        dados?.length ?? 0
      )
    ) {
      return;
    }

    for (const condutor of dados) {
      if (condutores.some((n) => n.idCondutor == condutor.id)) {
        const mensagem = `O condutor ${condutor.apelido}, CPF nº ${condutor.cpfCnpj} já foi adicionado.`;
        exibirNotificacaoToast({
          mensagem,
          tipo: TipoNotificacao.Advertencia,
        });
      } else {
        dispatch(
          adicionarCondutor({
            id: 0,
            cpfCnpj: condutor.cpfCnpj,
            nome: condutor.nome,
            idCondutor: condutor.id,
            apelido: condutor.apelido,
          })
        );
        exibirNotificacaoToast({
          mensagem: `Condutor ${condutor.nome} adicionado com sucesso. Clique em "Salvar" para confirmar a operação.`,
          tipo: TipoNotificacao.Advertencia,
        });
      }
    }
  }

  async function excluirRegistro(condutor: GridCondutorViewModel | undefined) {
    if (condutor) {
      const mensagem = renderToStringClient(
        <>
          Tem certeza de que deseja excluir o condutor{" "}
          <strong>
            {condutor.nome}, CPF nº {condutor.cpfCnpj}
          </strong>
          ?
        </>
      );

      const excluir = await exibirConfirmacao("Confirmar exclusão", mensagem);

      if (excluir) {
        dispatch(removerCondutor(condutor));

        exibirNotificacaoToast({
          mensagem: MensagensParaNotificacao.ExcluidoComSucesso,
          tipo: TipoNotificacao.Sucesso,
        });
      }
    }
  }

  useImperativeHandle(
    ref,
    (): IFormulario => ({
      requestSubmit() {
        return true;
      },
      valido() {
        return true;
      },
      isDirty() {
        return false;
      },
    })
  );

  function VerificaSeQuantidadeMaximaCondutoresEstaNoPermitido(
    quantidadeJaNaGrade: number,
    quantidaSelecionada: number
  ): boolean {
    const total = quantidadeJaNaGrade + quantidaSelecionada;

    if (total > 10) {
      exibirNotificacaoToast({
        mensagem: `Não é possível ter mais de 10 condutores em um MDF-e. Existem ${quantidadeJaNaGrade} na grade e ${quantidaSelecionada} selecionados.`,
        tipo: TipoNotificacao.Erro,
      });

      return false;
    }

    return true;
  }

  let form: HTMLFormElement | null;

  return (
    <TabContainer>
      <form ref={(ref) => (form = ref)}>
        <Linha>
          <Coluna md={12}>
            <MxpGrid<GridCondutorViewModel>
              id={gridId}
              ref={gridRef}
              dataSource={dataSourceCondutores}
              colunas={colunas}
              excluirRegistro={excluirRegistro}
              exibirIconeEditarMenuAcoes={false}
              style={props.style}
              novoRegistro={props.somenteLeitura ? undefined : novoRegistro}
              //gerarItensAdicionaisDeContexto={gerarContextMenu}
              nomeDoArquivoAoExportar={NomesTelas.condutores}
              isGridInterno
            />
          </Coluna>
        </Linha>
      </form>

      <Seletor
        titulo="Selecionar condutores"
        modo="selecaoMultipla"
        visivel={seletorVisivel}
        larguraMaxima="80%"
        callBack={(ok, dados) => {
          if (
            !VerificaSeQuantidadeMaximaCondutoresEstaNoPermitido(
              condutores?.length ?? 0,
              dados?.length ?? 0
            )
          ) {
            return;
          }
          setSeletorVisivel(false);

          if (ok) {
            adicionarSelecaoCondutores(dados as CondutorSelecaoGridModel[]);
          }
        }}
        onClose={() => {
          setSeletorVisivel(false);
        }}
        componente={(r) => <GridSelecaoCondutor ref={r} />}
      />
    </TabContainer>
  );
});

export default MDFeAbaCondutor;
