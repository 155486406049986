import { DataGrid } from "devextreme-react";
import {
  Column,
  DataGridRef,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid";
import { SingleMultipleOrNone } from "devextreme/common";
import ArrayStore from "devextreme/data/array_store";
import { forwardRef, useImperativeHandle, useMemo, useState } from "react";
import { Seletor } from "../../../../../components/selecao/seletor";
import useEffectOnLoad from "../../../../../hooks/effect.hooks";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../hooks/store.hooks";
import { tiposNFe } from "../../../../../models/api/mdfe/mdfe-enums";
import DocumentoFiscalSelecaoMdfeGridModel, {
  novoRegistroDocumentoFiscalSelecaoMdfeGrid,
} from "../../../../../models/api/nota-fiscal/nota-fiscal-selecao-mdfe";
import { PermissoesNotaFiscal } from "../../../../../models/permissoes/vendas/nota-fiscal/permissoes-nota-fiscal";
import { CallBackModal } from "../../../../../models/shared/ui/callback-modal";
import EditFormDocumentoFiscalAvulso from "../../../../../pages/fiscal/mdfe/edit-form";
import {
  adicionarDocumentoFiscal,
  recalcularTotaisNotasMDFe,
  removerDocumentoFiscal,
} from "../../../../../store/mdfe/mdfe.slice";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import exibirNotificacaoToast, {
  JanelasDeNotificacaoTitulos,
  MensagensParaNotificacao,
  TipoNotificacao,
} from "../../../../../utils/common/notificacoes-utils";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../utils/common/permissoes-utils";
import {
  criarItensMenuContextoPadrao,
  tratarDadosContextMenu,
} from "../../../../../utils/context-menu/context-menu-utils";
import { exibirAlerta, exibirConfirmacao } from "../../../../../utils/dialogos";
import { extraiTipoDocumentoChaveAcesso } from "../../../../../utils/especifico/mdfe/mdfe-utils";
import { formatarNumero } from "../../../../../utils/formatadores/formatador-de-numeros";
import {
  GridProps,
  getGridDefaultProps,
} from "../../../../../utils/grid/grid-utils";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import {
  getGridSelecaoProps,
  gridSelecaoConstructor,
} from "../../../../../utils/grid/selecao-utils";
import { renderToStringClient } from "../../../../../utils/react/react-utils";
import GridDefaults from "../../../../layout/grid-defaults";
import { SelecaoNotasMDFe } from "./selecao-notas-fiscais";

interface GridDocumentosFiscaisProps extends GridProps {
  idRegistro: number;
}

const gridId = "mdfe-lista-documentos";

export const GridDocumentosFiscais = forwardRef(
  (props: GridDocumentosFiscaisProps, ref) => {
    const [
      modalDocumentoAvulsoEdicaoVisivel,
      setModalDocumentoAvulsoEdicaoVisivel,
    ] = useState(false);
    const [
      registroDocumentoAvulsoEmEdicao,
      setRegistroDocumentoAvulsoEmEdicao,
    ] = useState<DocumentoFiscalSelecaoMdfeGridModel | undefined>(undefined);
    const [seletorVisivel, setSeletorVisivel] = useState(false);
    const [modoSelecao, setModoSelecao] =
      useState<SingleMultipleOrNone>("none");
    const [acaoDoubleClick, setAcaoDoubleClick] = useState<() => void>();

    const documentos = useAppSelector((state) => {
      const lista: DocumentoFiscalSelecaoMdfeGridModel[] = [];
      for (const descarregamento of state.mdfe.documentoAtual
        .descarregamentos) {
        for (const documento of descarregamento?.documentosVinculados ?? []) {
          const documentoFiscal: DocumentoFiscalSelecaoMdfeGridModel = {
            id: documento.id,
            chaveAcesso: documento.documentoFiscal?.chaveAcesso ?? "",
            dataEmissao: documento.documentoFiscal?.dataEmissao ?? "",
            idDestinatario: documento.documentoFiscal?.idDestinatario ?? 0,
            destinatario: documento.documentoFiscal?.destinatario ?? "",
            numero: documento.documentoFiscal?.numero ?? "",
            pesoBruto: documento.documentoFiscal?.pesoBruto ?? 0,
            pesoLiquido: documento.documentoFiscal?.pesoLiquido ?? 0,
            idMunicipioDestino:
              documento.documentoFiscal?.idMunicipioDestino ?? 0,
            municipioDestino: documento.documentoFiscal?.municipioDestino ?? "",
            serie: documento.documentoFiscal?.serie ?? "",
            idUfDestino: documento.documentoFiscal?.idUfDestino ?? 0,
            ufDestino: documento.documentoFiscal?.ufDestino ?? "",
            valor: documento.documentoFiscal?.valor ?? 0,
            tipoDocumentoFormatado: tiposNFe.includes(
              extraiTipoDocumentoChaveAcesso(
                documento.documentoFiscal?.chaveAcesso
              )
            )
              ? "NF-e"
              : "CT-e",
            idNotaFiscal: documento.idNotaFiscal ?? 0,
          };

          lista.push(documentoFiscal);
        }
      }

      return lista;
    });

    const dataSourceNotas = useMemo(
      () => new ArrayStore({ data: documentos, key: "id" }),
      [documentos, props.idRegistro]
    );

    let gridRef: DataGridRef<any, any>;

    function getGridRef() {
      return gridRef;
    }

    function setGridRef(ref: DataGridRef<any, any>) {
      gridRef = ref;
    }

    useEffectOnLoad(() => {
      //Evita que o modal pisque no primeiro render
      setRegistroDocumentoAvulsoEmEdicao(
        novoRegistroDocumentoFiscalSelecaoMdfeGrid
      );
    });

    useImperativeHandle(ref, () =>
      gridSelecaoConstructor(
        gridRef,
        () => modoSelecao,
        setModoSelecao,
        setAcaoDoubleClick
      )
    );

    function atualizarGrid() {
      gridRef.instance().refresh();
    }

    const dispatch = useAppDispatch();

    function novoRegistro() {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesNotaFiscal.Consultar,
        ])
      ) {
        return;
      }

      setSeletorVisivel(true);
    }

    function novoRegistroDocumentoFiscalAvulso() {
      setRegistroDocumentoAvulsoEmEdicao(
        novoRegistroDocumentoFiscalSelecaoMdfeGrid
      );
      setModalDocumentoAvulsoEdicaoVisivel(true);
    }

    function editarRegistro(
      documentoFiscal: DocumentoFiscalSelecaoMdfeGridModel | undefined
    ) {
      if (documentoFiscal) {
        if (documentoFiscal.idNotaFiscal > 0) {
          exibirAlerta(
            JanelasDeNotificacaoTitulos.Atencao,
            "Para editar documentos emitidos pelo sistema acesse Vendas > Notas fiscais."
          );
          return;
        }

        setRegistroDocumentoAvulsoEmEdicao(documentoFiscal);
        setModalDocumentoAvulsoEdicaoVisivel(true);
      }
    }

    function handleDoubleClickGrid(gridData: any) {
      editarRegistro(gridData.data);
    }

    function handleModalCallBack(info: CallBackModal) {
      setRegistroDocumentoAvulsoEmEdicao(undefined);
      setModalDocumentoAvulsoEdicaoVisivel(false);
      if (info.precisaAtualizar) {
        atualizarGrid();
      }
    }

    function adicionarSelecaoNotas(
      dados: DocumentoFiscalSelecaoMdfeGridModel[]
    ) {
      let alterado = false;

      for (const nota of dados) {
        if (documentos.some((n) => n.id == nota.id)) {
          const mensagem = `A nota fiscal Nº ${nota.numero} - Série ${nota.serie} já foi adicionada.`;
          exibirNotificacaoToast({
            mensagem,
            tipo: TipoNotificacao.Advertencia,
          });
        } else {
          dispatch(adicionarDocumentoFiscal(nota));
          exibirNotificacaoToast({
            mensagem: `Documento Nº ${nota.numero} adicionado com sucesso. Clique em "Salvar" para confirmar a operação.`,
            tipo: TipoNotificacao.Advertencia,
          });
          alterado = alterado || true;
        }

        if (alterado) {
          dispatch(recalcularTotaisNotasMDFe());
        }
      }
    }

    async function excluirRegistro(
      nota: DocumentoFiscalSelecaoMdfeGridModel | undefined
    ) {
      if (nota) {
        const mensagem = renderToStringClient(
          <>
            Tem certeza de que deseja excluir a nota fiscal{" "}
            <strong>
              nº {nota.numero} série {nota.serie}
            </strong>
            ?
          </>
        );

        const excluir = await exibirConfirmacao("Confirmar exclusão", mensagem);

        if (excluir) {
          dispatch(removerDocumentoFiscal(nota));
          dispatch(recalcularTotaisNotasMDFe());

          exibirNotificacaoToast({
            mensagem: MensagensParaNotificacao.ExcluidoComSucesso,
            tipo: TipoNotificacao.Sucesso,
          });
        }
      }
    }

    function gerarContextMenu(data: any) {
      const [getData] =
        tratarDadosContextMenu<DocumentoFiscalSelecaoMdfeGridModel>(
          data,
          gridRef,
          getGridRef
        );

      return !props.somenteLeitura
        ? criarItensMenuContextoPadrao({
            editar: () => editarRegistro(getData()),
            excluir: () => excluirRegistro(getData()),
          })
        : [];
    }

    return (
      <>
        <DataGrid
          ref={setGridRef}
          dataSource={dataSourceNotas}
          {...getGridDefaultProps({
            executarOperacoesNoServidor: false,
            nomeDoArquivoAoExportar: NomesTelas.notasFiscais,
            getGridRef: getGridRef,
            gerarOpcoesDoRegistro: gerarContextMenu,
            isModal: props.isModal,
            style: props.style,
          })}
          {...getGridSelecaoProps(
            () => modoSelecao,
            () => acaoDoubleClick,
            handleDoubleClickGrid
          )}
        >
          {GridDefaults({
            gridId,
            novoRegistro: props.somenteLeitura ? undefined : novoRegistro,
            exportavel: false,
            filtravel: false,
            paginavel: false,
            selecionavel: false,
            gerarContextMenu,
            getGridRef,
            isGridInterno: true,
            dropDownItems: props.somenteLeitura
              ? undefined
              : [
                  {
                    text: "Adicionar NF-e emitida pelo Maxiprod",
                    onClick: novoRegistro,
                  },
                  {
                    text: "Adicionar documento avulso",
                    onClick: novoRegistroDocumentoFiscalAvulso,
                  },
                ],
          })}
          <Column
            dataField="tipoDocumentoFormatado"
            caption="Tipo do documento"
            allowResizing
            width={100}
          />
          <Column
            dataField="numero"
            caption="Número"
            dataType="number"
            allowResizing
            width={80}
          />
          <Column dataField="serie" caption="Série" allowResizing width={80} />
          <Column
            dataField="dataEmissao"
            caption="Emissão"
            allowResizing
            {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
          />
          <Column
            dataField="chaveAcesso"
            caption="Chave de acesso"
            allowResizing
            width={350}
          />
          <Column
            dataField="destinatario"
            caption="Destinatário"
            allowResizing
          />
          <Column
            dataField="municipioDestino"
            caption="Município de destino"
            allowResizing
            width={140}
          />
          <Column
            dataField="ufDestino"
            caption="UF de destino"
            allowResizing
            width={80}
          />
          <Column
            dataField="valor"
            caption="Valor"
            dataType="number"
            allowResizing
            width={100}
            cellRender={({ data }) => {
              const dados = data as DocumentoFiscalSelecaoMdfeGridModel;
              return formatarNumero(dados.valor, 2);
            }}
          />
          <Column
            dataField="pesoLiquido"
            caption="Peso líquido"
            dataType="number"
            allowResizing
            width={80}
            cellRender={({ data }) => {
              const dados = data as DocumentoFiscalSelecaoMdfeGridModel;
              return formatarNumero(dados.pesoLiquido, 3);
            }}
          />
          <Column
            dataField="pesoBruto"
            caption="Peso bruto"
            dataType="number"
            allowResizing
            width={80}
            cellRender={({ data }) => {
              const dados = data as DocumentoFiscalSelecaoMdfeGridModel;
              return formatarNumero(dados.pesoBruto, 3);
            }}
          />
          <Summary>
            <TotalItem
              column="valor"
              summaryType="sum"
              customizeText={(data: any) => formatarNumero(data.value, 2)}
            />
            <TotalItem
              column="pesoLiquido"
              summaryType="sum"
              customizeText={(data: any) => formatarNumero(data.value, 3)}
            />
            <TotalItem
              column="pesoBruto"
              summaryType="sum"
              customizeText={(data: any) => formatarNumero(data.value, 3)}
            />
          </Summary>
        </DataGrid>

        {/* Modal de seleção de NFs */}
        <Seletor
          titulo="Selecionar notas fiscais"
          modo="selecaoMultipla"
          visivel={seletorVisivel}
          larguraMaxima="80%"
          callBack={(ok, dados) => {
            setSeletorVisivel(false);

            if (ok) {
              adicionarSelecaoNotas(
                dados as DocumentoFiscalSelecaoMdfeGridModel[]
              );
            }
          }}
          onClose={() => {
            setSeletorVisivel(false);
          }}
          componente={(r) => <SelecaoNotasMDFe ref={r} />}
        />
        <EditFormDocumentoFiscalAvulso
          visivel={modalDocumentoAvulsoEdicaoVisivel}
          idRegistroEdicao={
            Number(registroDocumentoAvulsoEmEdicao?.chaveAcesso) ?? 0
          }
          configuracoesModal={{ largura: "max(50vw, 1000px)", altura: "auto" }}
          callBackFecharModal={handleModalCallBack}
          documentoFiscal={registroDocumentoAvulsoEmEdicao}
        />
      </>
    );
  }
);

export default GridDocumentosFiscais;
